import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { CircularProgress, ListItem, ListItemText, ListItemIcon, Tooltip } from '@mui/material';
import { RiFileUploadLine, RiFolderUploadLine }  from 'react-icons/ri';
import S3FileBrowserStore from 'stores/s3FileBrowserStore';
import { useIsComponentMounted } from 'utils/customHooks';
import { isFileInputDirectorySupported } from 'utils/browserSupport';
import './S3Upload.scss';

interface Props {
    s3FileBrowserStore: S3FileBrowserStore;
    folderPath: string;
    type: 'File'|'Folder';
    onSuccess?: () => void;
};

const S3Upload: React.FC<Props> = observer(({s3FileBrowserStore, folderPath, type, onSuccess}: Props): JSX.Element => {
    const [waiting, setWaiting] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const isComponentMounted = useIsComponentMounted();

    const uploadItems = (files: FileList): void => {
        const filesArr: Array<File> = [];
        for (let i=0; i < files.length; i++) {
            filesArr.push(files[i]);
        }

        setWaiting(true);

        s3FileBrowserStore.uploadItems(filesArr, folderPath).finally(() => {
            s3FileBrowserStore.fetchTree(true);
            if (!isComponentMounted.current) {
                return;
            }
            setWaiting(false);
        }).then(() => {
            s3FileBrowserStore.snackbarMessage = 'Upload Complete';
            onSuccess && onSuccess();
        }, () => {
            s3FileBrowserStore.snackbarMessage = 'Error Uploading';
        });
    };

    const inputDirectoryAttr = type === 'Folder' ? '' : undefined;
    const disabled = type === 'Folder' && !isFileInputDirectorySupported();

    const getListItem = () => {
        return <ListItem
            button
            onClick={() => !waiting && inputRef.current && inputRef.current.click()}
            className="s3-upload"
            disabled={disabled}
        >
            <ListItemIcon className="s3-upload-icon">
                { type === 'Folder' ?
                    <RiFolderUploadLine/>
                :
                    <RiFileUploadLine/>
                }
            </ListItemIcon>
            <ListItemText>
                { !waiting ?
                    `Upload ${type}(s)`
                :
                    <CircularProgress className="s3-upload-spinner" size={25}/>
                }
            </ListItemText>
            <input
                ref={inputRef}
                type="file"
                className="s3-upload-input"
                multiple
                onChange={(e) => e.target.files && e.target.files.length && uploadItems(e.target.files)}
                //@ts-ignore
                webkitdirectory={inputDirectoryAttr}
                mozdirectory={inputDirectoryAttr}
                msdirectory={inputDirectoryAttr}
                odirectory={inputDirectoryAttr}
                directory={inputDirectoryAttr}
            />
        </ListItem>
    };

    const listItem = getListItem();

    if (disabled) {
        return <Tooltip title="Use Chrome to Upload Folders">
            <div>
                {listItem}
            </div>
        </Tooltip>
    }

    return listItem;
});

export default S3Upload;