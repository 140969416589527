import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, Card } from '@mui/material';
import './CardGrid.scss';

export type CardSizeType = 'sm'|'md'|'lg';
type WindowSizeTypes = 'xs'|'sm'|'md';

export interface CardConfigInterface {
    component: JSX.Element;
    size: CardSizeType;
};

type ColumnTraformationType = {
    [key in CardSizeType]: {
        [key in WindowSizeTypes]: 1|2|3|4|5|6|7|8|9|10|11|12;
    }
};

const ColumnTraformation:ColumnTraformationType = {
    'sm': {
        xs: 12,
        sm: 6,
        md: 3
    },
    'md': {
        xs: 12,
        sm: 6,
        md: 4
    },
    'lg': {
        xs: 12,
        sm: 12,
        md: 6
    }
} as const;

interface Props {
    cardConfigs: Array<CardConfigInterface>;
}

const CardGrid: React.FC<Props> = observer(({cardConfigs}: Props): JSX.Element => {
    return (
        <Grid
            className="card-grid-component"
            container
            spacing={4}
            justifyContent="center"
        >
            {
                cardConfigs.map((card, i) => {
                    return (
                        <Grid
                            item
                            key={i}
                            xs={ColumnTraformation[card.size].xs}
                            sm={ColumnTraformation[card.size].sm}
                            md={ColumnTraformation[card.size].md}
                        >
                            <Card className="grid-card">
                                { card.component }
                            </Card>
                        </Grid>
                    );
                })
            }
        </Grid>
    );
});

export default CardGrid;