import React, { useState, useLayoutEffect, Fragment, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useLocation } from "react-router-dom";
import useResizeObserver from 'use-resize-observer/polyfilled';
import { useDropzone } from 'react-dropzone';
import {
    Tooltip,
    Breadcrumbs,
    CircularProgress
} from '@mui/material';
import { GetApp, CloudUpload } from '@mui/icons-material';
import S3FileBrowserStore, { Tree } from 'stores/s3FileBrowserStore';
import S3Preview, { MIN_S3_PREVIEW_PX_WIDTH, useS3PreviewDrop } from '../s3Preview/S3Preview';
import S3FolderActions from '../s3Actions/s3FolderActions/S3FolderActions';
import { useIsComponentMounted } from 'utils/customHooks';
import { concatRouteParts } from 'utils/strings';
import './S3Folder.scss';

interface Props {
    s3FileBrowserStore: S3FileBrowserStore;
};

const S3Folder: React.FC<Props> = observer(({ s3FileBrowserStore }: Props): JSX.Element => {
    const { ref: contentsRef, width } = useResizeObserver<HTMLDivElement>();
    const [previewWidth, setPreviewWidth] = useState((MIN_S3_PREVIEW_PX_WIDTH));
    const [waiting, setWating] = useState(false);
    const [preparingZip, setPreparingZip] = useState(false);
    const location = useLocation();
    const isComponentMounted = useIsComponentMounted();

    const dropRef = useS3PreviewDrop({
        destinationFolderS3Key: concatRouteParts(s3FileBrowserStore.baseS3Folder, s3FileBrowserStore.relativeS3Path),
        canDrop: () => s3FileBrowserStore.editMode
    });
    const { getRootProps, isDragActive } = useDropzone({
        onDropAccepted: (files: Array<File>) => {
            setWating(true);
            s3FileBrowserStore.uploadItems(files, folderPath).finally(() => {
                s3FileBrowserStore.fetchTree(true).finally(() => {
                    if (!isComponentMounted.current) {
                        return;
                    }
                    setWating(false);
                });
            }).then(() => {
                s3FileBrowserStore.snackbarMessage = 'Upload Complete';
            }, () => {
                s3FileBrowserStore.snackbarMessage = 'Error Uploading';
            });
        },
        onDropRejected: () => {
            s3FileBrowserStore.snackbarMessage = 'Error Uploading';
        },
        noKeyboard: true,
        noClick: true,
        noDragEventsBubbling: true,
        disabled: !s3FileBrowserStore.dropzoneEnabled
    });

    const folderEntries: Array<[string, Tree]> = useMemo(() => {
        return (Object.entries(s3FileBrowserStore.tree || {}).filter(([key, value]) => {
            return typeof value === 'object';
        }) as Array<[string, Tree]>).sort(([keyA], [keyB]): number => {
            if (keyA < keyB) {
                return -1;
            }
            if (keyA > keyB) {
                return 1;
              }
            return 0;
        });
    }, [s3FileBrowserStore.tree]);

    const fileEntries: Array<[string, string]> = useMemo(() => {
        return (Object.entries(s3FileBrowserStore.tree || {}).filter(([name, value]) => {
            return typeof value === 'string';
        }) as Array<[string, string]>).sort(([keyA], [keyB]): number => {
            if (keyA < keyB) {
                return -1;
            }
            if (keyA > keyB) {
                return 1;
              }
            return 0;
        });
    }, [s3FileBrowserStore.tree]);

    useLayoutEffect(() => {
        if (!contentsRef.current || !width) {
            setPreviewWidth(MIN_S3_PREVIEW_PX_WIDTH);
            return;
        }

        let previewsContainerWidth = contentsRef.current.getBoundingClientRect().width;

        if (!previewsContainerWidth) {
            setPreviewWidth(MIN_S3_PREVIEW_PX_WIDTH);
            return;
        }

        let previewsPerRow = Math.max(Math.floor(previewsContainerWidth / MIN_S3_PREVIEW_PX_WIDTH), 1);
        let numberOfPreviews = fileEntries.length + folderEntries.length;

        numberOfPreviews = Math.max(numberOfPreviews, 1);

        if (numberOfPreviews < previewsPerRow) {
            previewsContainerWidth = ((numberOfPreviews + 1) * MIN_S3_PREVIEW_PX_WIDTH) - 1;
            previewsPerRow = numberOfPreviews;
        }

        setPreviewWidth(Math.trunc(previewsContainerWidth / previewsPerRow));
    }, [
        contentsRef,
        width,
        fileEntries.length,
        folderEntries.length
    ]);

    const getFilePreviews = (): JSX.Element => {
        return <Fragment>
            { fileEntries.map(([key, s3Key]) => {
                return <S3Preview
                    key={s3Key}
                    name={key}
                    s3Key={s3Key}
                    isFile={true}
                    width={previewWidth}
                    s3FileBrowserStore={s3FileBrowserStore}
                />
            }) }
        </Fragment>
    };

    const getFolderPreviews = (): JSX.Element => {
        return <Fragment>
            { folderEntries.map(([name]) => {
                const s3Key = concatRouteParts(s3FileBrowserStore.baseS3Folder, s3FileBrowserStore.relativeS3Path, name);
                const folderRoute = '/' + concatRouteParts(location.pathname, encodeURIComponent(name));
                return <S3Preview
                    key={s3Key}
                    name={name}
                    s3Key={s3Key}
                    isFile={false}
                    width={previewWidth}
                    folderRoute={folderRoute}
                    s3FileBrowserStore={s3FileBrowserStore}
                />
            }) }
        </Fragment>
    };

    const getBreadcrumbs = (): JSX.Element => {
        let route = `${s3FileBrowserStore.fileBrowserAppRoute}`;
        return <Breadcrumbs aria-label="breadcrumb" separator="›" className="s3-folder-breadcrumbs">
            {s3FileBrowserStore.relativeS3Path.split('/').map((folder, i, folders) => {
                if (i === 0 && folder === '') {
                    folder = 'Home'
                } else {
                    if (!route.endsWith('/')) {
                        route += '/';
                    }
                    route += encodeURI(folder);
                }
                if (i < folders.length - 1) {
                    return <BreadcrumbLink
                        key={i}
                        route={route}
                        folderName={folder}
                        editMode={s3FileBrowserStore.editMode}
                        s3Key={concatRouteParts(s3FileBrowserStore.baseS3Folder, ...folders.slice(0, i + 1))}
                    />
                }
                return <div key={i}>{folder ?? ''}</div>
            })}
        </Breadcrumbs>
    };

    const folderPath = useMemo(() => {
        let path = s3FileBrowserStore.relativeS3Path;
        if (path.startsWith('/')) {
            path = path.slice(1);
        }
        if (path && !path.endsWith('/')) {
            path += '/'
        }

        return path;
    }, [
        s3FileBrowserStore.relativeS3Path
    ]);


    return <div
        className="s3-folder-component" {...(s3FileBrowserStore.dropzoneEnabled ? getRootProps() : {})}
        ref={dropRef}
    >
        <div className="s3-folder-header clearfix" style={{pointerEvents: isDragActive ? 'none' : 'auto'}}>
            <div className="s3-folder-header-left">
                {getBreadcrumbs()}
            </div>
            <div className="s3-folder-header-right">
                { s3FileBrowserStore.editMode &&
                    <S3FolderActions
                        className="s3-folder-button"
                        folderPath={folderPath}
                        s3FileBrowserStore={s3FileBrowserStore}
                    />
                }
                <Tooltip
                    title={preparingZip ? 'Preparing Zip' : 'Download Zip'}
                >
                    { preparingZip ?
                        <CircularProgress
                            className="s3-folder-button s3-folder-button-spinner"
                            size={24}
                            color="inherit"
                        />
                    :
                        <GetApp
                            className="s3-folder-download s3-folder-button"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                setPreparingZip(true);

                                s3FileBrowserStore.downloadZippedFolder().catch(() => {
                                    s3FileBrowserStore.snackbarMessage = 'Zip Download Failed';
                                }).finally(() => {
                                    setPreparingZip(false);
                                });
                            }}
                        />
                    }
                </Tooltip>
            </div>
        </div>
        <div className="s3-folder-contents" ref={contentsRef}>
            { (!s3FileBrowserStore.tree || !Object.keys(s3FileBrowserStore.tree).length) ?
                <div className="s3-folder-contents-empty">No Files Found</div>
            :
                <Fragment>
                    { getFolderPreviews() }
                    { getFilePreviews() }
                </Fragment>
            }
        </div>
        { waiting &&
            <div className="s3-folder-spinner-overlay">
                <CircularProgress className="loader" color="secondary"/>
            </div>
        }
        { isDragActive &&
            <div className="s3-folder-drag-and-drop-overlay">
                <CloudUpload className="s3-folder-drag-and-drop-overlay-icon"/>
            </div>
        }
    </div>
});

interface BreadcrumbLinkProps {
    route: string;
    s3Key: string;
    folderName: string;
    editMode: boolean;
};

const BreadcrumbLink: React.FC<BreadcrumbLinkProps> = ({route, folderName, s3Key, editMode}: BreadcrumbLinkProps): JSX.Element => {
    const dropRef = useS3PreviewDrop({
        destinationFolderS3Key: s3Key,
        canDrop: () => editMode
    });
    return <Link
        to={route}
        ref={dropRef}
        className="breadcrumb-link"
    >
        {folderName}
    </Link>
}

export default S3Folder;