import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { LinearProgress } from '@mui/material';
import LogoSpinner from 'components/logoSpinner/LogoSpinner';
import { AppControlStoreContext } from 'stores/appControlStore';
import './LoadingPage.scss';

const LoadingPage: React.FC = observer((): JSX.Element => {
    const appControlStore = useContext(AppControlStoreContext);

    return (
        <div className={`loading-page-component`}>
            { appControlStore.loadingFirstPage ?
                <div className="logo-spinner-container">
                    <LogoSpinner/>
                </div>
            :
                <LinearProgress color="secondary"/>
            }
        </div>
    );
});

export default LoadingPage;