import React, { Fragment, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Portal, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { ImLink } from 'react-icons/im';
import S3CreateLinksPrompt, { Link as LinkInfo } from '../../s3Prompt/s3CreateLinksPrompt/S3CreateLinksPrompt';
import S3FileBrowserStore from 'stores/s3FileBrowserStore';
import { useIsComponentMounted } from 'utils/customHooks';
import './S3CreateLinks.scss';

interface Props {
    s3FileBrowserStore: S3FileBrowserStore;
    folderPath: string;
    onSuccess?: () => void;
};

const S3CreateLinks: React.FC<Props> = observer(({s3FileBrowserStore, folderPath, onSuccess}: Props): JSX.Element => {
    const [showingPrompt, setShowingPrompt] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const isComponentMounted = useIsComponentMounted();

    const createLinks = (links: Array<LinkInfo>) => {
        if (!links.length) {
            return;
        }

        setWaiting(true);

        s3FileBrowserStore.createLinks(`${folderPath}`, links).finally(() => {
            s3FileBrowserStore.fetchTree(true);
            if (!isComponentMounted.current) {
                return
            }
            setWaiting(false);
        }).then(() => {
            s3FileBrowserStore.snackbarMessage = `Link${links.length > 1 ? 's' : ''} Created`;
            if (isComponentMounted.current) {
                setShowingPrompt(false);
            }
            onSuccess && onSuccess();
        }, () => {
            s3FileBrowserStore.snackbarMessage = `Error Creating Link${links.length > 1 ? 's' : ''}`;
        });
    };

    return <Fragment>
        <ListItem
            button
            onClick={() => setShowingPrompt(true)}
            className="s3-create-links"
        >
            <ListItemIcon className="s3-create-links-icon">
                <ImLink/>
            </ListItemIcon>
            <ListItemText>
                Add Link(s)
            </ListItemText>
        </ListItem>
        <Portal>
            <S3CreateLinksPrompt
                open={showingPrompt}
                onClose={() => setShowingPrompt(false)}
                onConfirm={createLinks}
                waitingForResponse={waiting}
            />
        </Portal>
    </Fragment>
});

export default S3CreateLinks;