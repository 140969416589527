import { computed, observable } from 'mobx';
import { S3 } from 'aws-sdk';
import AwsServiceStore from 'stores/abstractStores/awsServiceStore';
import { authStore } from 'stores/authStore';

export type PermissionType = 'read'|'read-write';

type Access = 'user-writable'|'pjx-writable-client-readable';

interface Props {
    access?: Access;
};

class S3Store extends AwsServiceStore {
    protected readonly serviceName = 'S3';

    private readonly access: Access;

    public static bucket = 'pjx-client-portal';
    public static thumbnailBucket = 'pjx-client-portal-thumbnails';

    constructor(props?: Props) {
        super();
        this.access = props?.access ? props.access : 'pjx-writable-client-readable';
    };

    @observable
    public prefix: string|null = null;

    @computed
    protected get client(): S3|null {
        if (!authStore.awsCredentials || this.prefix === null) {
            return null;
        }

        return new S3({
            credentials: authStore.awsCredentials
        });
    };

    @computed
    public get permissionType(): PermissionType|null {
        if (authStore.user === null) {
            return null
        }

        switch(this.access) {
            case 'pjx-writable-client-readable':
                return authStore.isPjxUser ? 'read-write' : 'read';
            case 'user-writable':
                return 'read-write';
            default:
                throw new Error('Unhandled or Invalid Access State');
        }
    };

    public getObject(params: S3.GetObjectRequest) {
        return this.callAwsSdkMethod<[S3.GetObjectRequest], S3.GetObjectOutput>(this.client?.getObject, params);
    };

    public listObjectsV2(params: S3.ListObjectsV2Request) {
        return this.callAwsSdkMethod<[S3.ListObjectsV2Request], S3.ListObjectsV2Output>(this.client?.listObjectsV2, params);
    };

    public deleteObject(params: S3.DeleteObjectRequest) {
        return this.callAwsSdkMethod<[S3.DeleteObjectRequest], S3.DeleteObjectOutput>(this.client?.deleteObject, params);
    };

    public getSignedUrlPromise(operation: string, params: any) {
        return this.callAwsSdkMethod(this.client?.getSignedUrlPromise, operation, params);
    };

    public deleteObjects(params: S3.DeleteObjectsRequest) {
        return this.callAwsSdkMethod<[S3.DeleteObjectsRequest], S3.DeleteObjectsOutput>(this.client?.deleteObjects, params);
    };

    public putObject(params: S3.PutObjectRequest) {
        return this.callAwsSdkMethod<[S3.PutObjectRequest], S3.PutObjectOutput>(this.client?.putObject, params);
    };

    public copyObject(params: S3.CopyObjectRequest) {
        return this.callAwsSdkMethod<[S3.CopyObjectRequest], S3.CopyObjectOutput>(this.client?.copyObject, params);
    };

    public headObject(params: S3.HeadObjectRequest) {
        return this.callAwsSdkMethod<[S3.HeadObjectRequest], S3.HeadObjectOutput>(this.client?.headObject, params);
    };
};

export default S3Store;