import React, { Fragment, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup'; 
import { Form, Formik } from "formik";
import { Button, TextField, CircularProgress } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { AuthStoreContext } from 'stores/authStore';
import CognitoStore from 'stores/cognitoStore';
import S3Store from 'stores/s3Store';
import { clientStore } from 'stores/clientStore';

import { getEnv } from 'utils/env';
import MessagePage from 'components/messagePage/MessagePage';

import './CreateUser.scss';

const REGION = 'us-east-1';
const cognitoStore = new CognitoStore();
const s3Store = new S3Store({ access: 'user-writable' });
s3Store.prefix = getEnv();

interface FormValues {
    userId: string;
    username: string;
    password: string;
    email: string;
    company: string;
    logo: File | null;
    primaryColor?: string;
    secondaryColor?: string;
    intacctClientId?: string;
}

const validationSchema = Yup.object().shape({
    userId: Yup.string()
        .min(3, 'User Id must be exactly 3 characters')
        .max(3, 'User Id must be exactly 3 characters')
        .required('User Id is required'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\W]+$/,
            'Password must contain at least one uppercase letter, one lowercase letter, and one number'
        ),
    username: Yup.string()
        .required('Username is required'),
    // email: Yup.string()
    //     .email('Invalid email format')
    //     .required('Email is required'),
    company: Yup.string()
        .required('Company Name is required'),
});

const getEmail = (userId: string) => `eric+client-portal-${getEnv()}-${userId}@pjxmedia.com`

const uploadLogoFileToS3 = async (file: File): Promise<string> => {
    const timestamp = new Date().getTime();
    const bucket = 'pjx-client-portal-misc';
    const key = `${getEnv()}/logos/${timestamp}_${file.name}`;
    const fileContent = await file.arrayBuffer();

    try {
        await s3Store.putObject({
            Bucket: bucket,
            Key: key,
            Body: new Uint8Array(fileContent),
            ContentType: file.type,
            ACL: 'public-read',
        });

        const fileUrl = `https://${bucket}.s3.${REGION}.amazonaws.com/${encodeURIComponent(key)}`;

        return fileUrl;
    } catch (error) {
        console.error('Error uploading file to S3:', error);
        throw error;
    }
}

const CreateUser: React.FC = observer((): JSX.Element => {
    const showEmail = false;
    const authStore = useContext(AuthStoreContext);

    if (!authStore.user) return <Fragment />
    if (!authStore.isPjxUser) return <MessagePage text="Access Denied"/>

    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [logoUrl, setLogoUrl] = useState<string>('');
    const [isSuccess, setIsSuccess] = useState<Boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [accountInfo, setAccountInfo] = useState<{
        companyName: string;
        username: string;
        password: string;
    } | null>(null);

    const initVal: FormValues = {
        userId: '',
        username: '',
        password: '',
        email: '',
        company: '',
        logo: null,
        primaryColor: '',
        secondaryColor: '',
        intacctClientId: '',
    }

    const handleFileUpload = async (
        event: React.ChangeEvent<HTMLInputElement>,
        setFieldValue: (field: string, value: any) => void
    ) => {
        const file = event.currentTarget.files?.[0];
        if (file) {
            setFieldValue("logo", file);
            setUploadedFile(file);

            try {
                const s3LogoUrl = await uploadLogoFileToS3(file);
                setLogoUrl(s3LogoUrl);
            } catch (error: unknown) {
                console.error("Error Uploading Logo", error);
                setError("Error Uploading Logo");
            }
            
        }
    };

    const handleSubmit = async (
        val: FormValues,
        setSubmitting: (isSubmitting: boolean) => void,
        resetForm: () => void,
        setIsSuccess: React.Dispatch<React.SetStateAction<Boolean>>,
        setError: React.Dispatch<React.SetStateAction<string | null>>
    ) => {
        try {       
            await cognitoStore.createUser({
                userId: val.userId,
                username: val.username,
                password: val.password,
                email: val.email,
                companyName: val.company,
                logo: logoUrl,
                primaryColor: val.primaryColor,
                secondaryColor: val.secondaryColor,
                intacctClientId: val.intacctClientId,
                setPermanentPassword: true,
            })
            // .then(async ({ User }) => {
            //     if (User && User.Username && User.Attributes) {
            //          Auth.updateUserAttributes(User, User.Attributes)
            //     }
            // })

            setIsSuccess(true);
            setError(null);
            setUploadedFile(null);
            setLogoUrl('');
            resetForm();

            setAccountInfo({
                companyName: val.company,
                username: val.username,
                password: val.password,
            });
        } catch (error: unknown) {
            if (error instanceof Error) {
                console.error("Error creating user: ", error.message);
                setError(error.message);
            } else {
                console.error("Error creating user: ", error);
                setError("An unexpected error occurred.");
            }

            setIsSuccess(false);
        } finally {
            setSubmitting(false);
        }
    };

    const handleLogoPreview = () => {
        Auth.currentAuthenticatedUser().then((currentAuthenticatedUser) => {
            const prevLogo = currentAuthenticatedUser.attributes['custom:logo'];
            const prevName = currentAuthenticatedUser.attributes['custom:company_name'];
            
            clientStore.loadClient({
                name: prevName,
                logo: logoUrl
            });

            // Set the clientStore logo back to the original state
            setTimeout(() => {
                clientStore.loadClient({
                    name: prevName,
                    logo: prevLogo
                });
            }, 5000);
        }).catch(() => {
            alert('Preview failed');
        });
    }

    useEffect(() => {
        if (isSuccess) {
            const timeoutId = setTimeout(() => {
                setIsSuccess(false);
            }, 5000); 

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [isSuccess]);

    return (
        <Formik 
            initialValues={initVal}
            validationSchema={validationSchema}
            onSubmit={async (val, { setSubmitting, resetForm }) => {
                handleSubmit(val, setSubmitting, resetForm, setIsSuccess, setError);
            }}
        >{({ values, handleChange, handleBlur, setFieldValue, errors, touched, isSubmitting }) => {
            useEffect(() => {
                setFieldValue("email", getEmail(values.userId));
            }, [values.userId, setFieldValue]);

            return (
                <div className="create-user-form-container">
                    <Form className="create-user-form">
                        <div className="user-id-intact-logo">
                            <TextField
                                name="userId"
                                required
                                label="User Id"
                                value={values.userId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.userId && !!errors.userId}
                                helperText={touched.userId && errors.userId}
                                variant="standard"
                                className="create-user-text-field"
                            />
                            <div className="upload-container">
                                <Button component="label" className="client-logo-upload-btn">
                                    <span className="upload-btn-label">
                                        {uploadedFile ? `Uploaded: ${uploadedFile.name}` : 'Upload Client Logo'}
                                    </span>
                                    <input 
                                        type="file" 
                                        hidden 
                                        onChange={(event) => handleFileUpload(event, setFieldValue)}
                                    />
                                </Button>
                                <Button className="client-logo-preview-btn" onClick={handleLogoPreview}><VisibilityIcon /></Button>
                            </div>
                        </div>
                        <div className="create-user-pwd-user">
                            <TextField 
                                name="username"
                                required
                                label="Username for Login"
                                value={values.username}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.username && !!errors.username}
                                helperText={touched.username && errors.username}
                                variant="standard"
                                className="create-user-text-field"
                            />
                            <TextField 
                                name="password"
                                type="password"
                                required
                                label="Password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.password && !!errors.password}
                                helperText={touched.password && errors.password}
                                variant="standard"
                                className="create-user-text-field"
                            />
                        </div>
                        {
                            showEmail && <TextField 
                                name="email"
                                label="Email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                variant="standard"
                                className="create-user-text-field"
                                required
                                disabled={true}
                            />

                        }
                        <TextField 
                            name="company"
                            label="Company Name"
                            value={values.company}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.company && !!errors.company}
                            helperText={touched.company && errors.company}
                            variant="standard"
                            className="create-user-text-field"
                            required
                        />
                        <div className="create-user-colors-clientId">
                            <TextField 
                                name="primaryColor"
                                label="Primary Color"
                                value={values.primaryColor}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="standard"
                                className="create-user-text-field"
                            />
                            <TextField 
                                name="secondaryColor"
                                label="Secondary Color"
                                value={values.secondaryColor}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="standard"
                                className="create-user-text-field"
                            />
                            <TextField
                                name="intacctClientId"
                                label="Intacct Client Id"
                                value={values.intacctClientId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="standard"
                                className="create-user-text-field"
                            />
                        </div>

                        <Button 
                            type="submit" 
                            variant="contained" 
                            className="create-user-submit" 
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? <CircularProgress size={24} /> : "Create"}
                        </Button>
                    </Form>
                    {isSuccess && (
                        <div className="success-message">
                            <p>Successfully Created!</p>
                        </div>
                    )}
                    {error && (
                        <div className="error-message">
                            <p>{error}</p>
                        </div>
                    )}
                    {accountInfo && (
                        <div className="account-info">
                            <Button className='clear-accountInfo-btn' onClick={() => {
                                setAccountInfo(null);
                            }}>close</Button>
                            <p>Company Name: <span>{accountInfo.companyName}</span></p>
                            <p>Username: <span>{accountInfo.username}</span></p>
                            <p>Password: <span>{accountInfo.password}</span></p>
                        </div>
                    )}
                </div>
            )
        }}</Formik>
    );
});

export default CreateUser;