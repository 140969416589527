import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, TextField } from '@mui/material';
import { Auth } from 'aws-amplify';
import { AuthStoreContext, UserInfo, UserAttributes } from 'stores/authStore';

const getUserAttributeAsString = (user: UserInfo|null, attribute: keyof UserAttributes): string => {
    if (!user) {
        return '';
    }

    return user.attributes[attribute] || '';
};

const UserSetup: React.FC = observer((): JSX.Element => {
    const authStore = useContext(AuthStoreContext);
    const user = authStore.user;

    const [identityId, setIdentityId] = useState<string>('');
    const [email, setEmail] = useState<string>(getUserAttributeAsString(user, 'email'));
    const [companyName, setCompanyName] = useState<string>(getUserAttributeAsString(user, 'custom:company_name'));
    const [preferredUsername, setPreferredUsername] = useState<string>(getUserAttributeAsString(user, 'preferred_username'));
    const [companyLogo, setCompanyLogo] = useState<string>(getUserAttributeAsString(user, 'custom:logo'));
    const [primaryColor, setPrimaryColor] = useState<string>(getUserAttributeAsString(user, 'custom:primary_color'));
    const [secondaryColor, setSecondaryColor] = useState<string>(getUserAttributeAsString(user, 'custom:secondary_color'));

    useEffect(() => {
        const user = authStore.user;

        setEmail(getUserAttributeAsString(user, 'email'));
        setCompanyName(getUserAttributeAsString(user, 'custom:company_name'));
        setPreferredUsername(getUserAttributeAsString(user, 'preferred_username'));
        setCompanyLogo(getUserAttributeAsString(user, 'custom:logo'));
        setPrimaryColor(getUserAttributeAsString(user, 'custom:primary_color'));
        setSecondaryColor(getUserAttributeAsString(user, 'custom:secondary_color'));
    }, [
        authStore.user
    ]);

    useEffect(() => {
        Auth.currentUserCredentials().then((credentials) => {
            setIdentityId(credentials.identityId || '');
        });
    }, [authStore.user]);

    const saveChanges = () => {
        type EditableUserAttributes = Omit<UserAttributes, 'sub'>;
        const attributes: Omit<EditableUserAttributes, 'sub'> = {
            'custom:identity_id': identityId,
            'custom:company_name': companyName,
            'custom:primary_color': primaryColor,
            'custom:secondary_color': secondaryColor,
            'custom:logo': companyLogo,
            preferred_username: preferredUsername,
            email: email
        };

        let missingAttribute: keyof EditableUserAttributes|undefined;

        (['custom:identity_id', 'custom:company_name', 'preferred_username'] as Array<keyof EditableUserAttributes>).some((attribute) => {
            if (!attributes[attribute]) {
                missingAttribute = attribute;
                return true;
            }
            return false;
        });

        if (missingAttribute) {
            alert(`Missing Required Attribute: ${missingAttribute}`);
            return;
        }

        Auth.currentAuthenticatedUser().then((currentAuthenticatedUser) => {
            return Auth.updateUserAttributes(currentAuthenticatedUser, attributes).then(() => {
                window.location.reload()
            });
        }).catch(() => {
            alert('Save failed');
        });
    };

    return <div style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 500,
        maxWidth: '100%'
    }}>
        <h1>{ authStore.user && authStore.user.username }</h1>
        <div style={{marginBottom: 20}}>
            <TextField
                label="Identity ID"
                value={identityId}
                onChange={(e) => setIdentityId(e.target.value)}
                disabled={true}
                style={{width: '100%'}}
                variant="standard"
            />
        </div>
        <div style={{marginBottom: 20}}>
            <TextField
                label="Username for Login"
                value={preferredUsername}
                onChange={(e) => setPreferredUsername(e.target.value)}
                style={{width: '100%'}}
                variant="standard"
            />
        </div>
        <div style={{marginBottom: 20}}>
            <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{width: '100%'}}
                variant="standard"
            />
        </div>
        <div style={{marginBottom: 20}}>
            <TextField
                label="Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                style={{width: '100%'}}
                variant="standard"
            />
        </div>
        <div style={{marginBottom: 20}}>
            <TextField
                label="Company Logo"
                value={companyLogo}
                onChange={(e) => setCompanyLogo(e.target.value)}
                style={{width: '100%'}}
                variant="standard"
            />
        </div>
        <div style={{marginBottom: 20}}>
            <TextField
                label="Primary Color"
                value={primaryColor}
                onChange={(e) => setPrimaryColor(e.target.value)}
                style={{width: '100%'}}
                variant="standard"
            />
        </div>
        <div style={{marginBottom: 20}}>
            <TextField
                label="Secondary Color"
                value={secondaryColor}
                onChange={(e) => setSecondaryColor(e.target.value)}
                style={{width: '100%'}}
                variant="standard"
            />
        </div>
        <Button
            variant="contained"
            onClick={() => saveChanges()}
            disabled={!identityId}
        >
            Save
        </Button>
        <div style={{marginTop: 20}}>
            * If Identity Id does not populate, please refresh page
        </div>
    </div>
});

export default UserSetup;