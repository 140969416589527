import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

// Breakpoints set to match material ui breakpoints
// https://material-ui.com/customization/breakpoints/

// For window widths
export const useIsMobileMediaQuery = useMediaQuery.bind(null, { maxWidth: 599 });
export const useIsTabletMediaQuery = useMediaQuery.bind(null, { minWidth: 600, maxWidth: 959 });
export const useIsDesktopMediaQuery = useMediaQuery.bind(null, { minWidth: 960 });

// For device widths
export const isTabletOrMobileDeviceMediaQuery = useMediaQuery.bind(null, {
    query: '(max-device-width: 1224px)'
});

interface WindowSize {
    width?: number;
    height?: number;
};

export const useWindowSize = (): WindowSize => {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
};