import React from 'react';
import { observer } from 'mobx-react-lite';
import PulseReportStore from 'stores/pulseReportStore';
import RevenueDoughnutChart from 'components/pulseReportCharts/revenueDoughnutChart/RevenueDoughnutChart';

interface Props {
    pulseReportStore: PulseReportStore;
};

const MarketRevenueDoughnutChart: React.FC<Props> = observer(({pulseReportStore}: Props): JSX.Element => {
    return <RevenueDoughnutChart
        revenueByCategoryByYear={pulseReportStore.revenueByMarketByYear}
        colorByCategory={pulseReportStore.colorByMarket}
        years={pulseReportStore.selectedYears}
        title="Spend By Market"
    />
});

export default MarketRevenueDoughnutChart;