import React, { Fragment, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation } from "react-router-dom";
import { Portal, Fab, Tooltip, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { DeleteForever as DeleteIcon } from '@mui/icons-material'
import S3DeletePrompt from '../../s3Prompt/s3DeletePrompt/S3DeletePrompt';
import S3FileBrowserStore from 'stores/s3FileBrowserStore';
import { useIsComponentMounted } from 'utils/customHooks';
import './S3Delete.scss';

interface Props {
    name?: string;
    s3Key: string;
    s3FileBrowserStore: S3FileBrowserStore;
    isFile: boolean;
    buttonStyle: 'fab'|'menuItem'|'icon';
    navigateUpOnDelete?: boolean;
};

const S3Delete: React.FC<Props> = observer(({name, s3Key, s3FileBrowserStore, isFile, buttonStyle, navigateUpOnDelete}: Props): JSX.Element => {
    const [showingPrompt, setShowingPrompt] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const isComponentMounted = useIsComponentMounted();
    const history = useHistory();
    const location = useLocation();

    const deleteItem = (): void => {
        setWaiting(true);

        s3FileBrowserStore.deleteItem(s3Key, isFile).finally(() => {
            if (!isComponentMounted.current) {
                return;
            }
            setWaiting(false);
        }).then(() => {
            if (navigateUpOnDelete) {
                let route = location.pathname;
                if (route.endsWith('/')) {
                    route = route.slice(0,-1);
                }
                const lastSlash = route.lastIndexOf('/');
                if (lastSlash > 0) {
                    history.push(route.slice(0, lastSlash));
                    return;
                }
            }

            s3FileBrowserStore.fetchTree(true);
            s3FileBrowserStore.snackbarMessage = `${isFile ? 'File' : 'Folder'} Deleted`;

            if (!isComponentMounted.current) {
                return;
            }
            setShowingPrompt(false);
        }, () => {
            s3FileBrowserStore.fetchTree(true);
            s3FileBrowserStore.snackbarMessage = `Error Deleting ${isFile ? 'File' : 'Folder'}`;
        });
    };

    return <Fragment>
        { buttonStyle === 'fab' ?
            <Tooltip title={`Delete ${isFile ? 'File' : 'Folder'}`}>
                <Fab
                    className="s3-delete-fab"
                    aria-label="remove"
                    size="small"
                    onClick={(e) => {e.stopPropagation(); setShowingPrompt(true)}}
                >
                    <DeleteIcon/>
                </Fab>
            </Tooltip>
        : buttonStyle === 'menuItem' ?
            <ListItem
                button
                onClick={() => setShowingPrompt(true)}
                className="s3-delete-list-item"
            >
                <ListItemIcon className="s3-delete-list-item-icon">
                    <DeleteIcon/>
                </ListItemIcon>
                <ListItemText className="s3-delete-list-item-text">
                    Delete {isFile ? 'File' : 'Folder'}
                </ListItemText>
            </ListItem>
        :
            <DeleteIcon
                onClick={() => setShowingPrompt(true)}
                style={{
                    height: 16,
                    cursor: 'pointer',
                    marginBottom: -2,
                    color: '#d60000'
                }}
            />
        }
        <Portal>
            <S3DeletePrompt
                open={showingPrompt}
                itemName={name ? name : `this ${isFile ? 'file' : 'folder'}`}
                waitingForResponse={waiting}
                onClose={() => setShowingPrompt(false)}
                onConfirm={deleteItem}
            />
        </Portal>
    </Fragment>
});

export default S3Delete;