import { observable, action } from 'mobx';
import BaseStore from "stores/abstractStores/baseStore";

export type TimeUnits = 'Weeks'|'Months';

interface Props {
    years?: Array<number>|Set<number>;
    formats?: Array<string>|Set<string>;
    markets?: Array<string>|Set<string>;
    advertisers?: Array<string>|Set<string>;
    timeUnits?: TimeUnits
};

class PulseReportFiltersStore extends BaseStore {
    @observable
    public years!: Set<number>;

    @observable
    public formats!: Set<string>;

    @observable
    public markets!: Set<string>;

    @observable
    public advertisers!: Set<string>;

    @observable
    public timeUnits!: TimeUnits;

    constructor(props?: Props) {
        super();
        this.updateFilters(props || {});
    };

    @action
    public updateFilters({ years, formats, markets, advertisers, timeUnits }: Props) {
        this.years = years ? new Set(years) : new Set();
        this.formats = formats ? new Set(formats) : new Set();
        this.markets = markets ? new Set(markets) : new Set();
        this.advertisers = advertisers ? new Set(advertisers) : new Set();
        this.timeUnits = timeUnits ? timeUnits : 'Months';
    };

    @action
    public clearFilters() {
        this.updateFilters({});
    };
};

export default PulseReportFiltersStore;

