export const isFileInputDirectorySupported = (() => {
    let supported: boolean|undefined;

    return (): boolean => {
        if (typeof supported === 'boolean') {
            return supported;
        }

        const elem = document.createElement('input');
        const dir = 'directory';
        const domPrefixes = [ "", "moz", "o", "ms", "webkit" ];

        elem.type = 'file';

        supported = false;
        for (let prefix in domPrefixes) {
            if (domPrefixes[prefix] + dir in elem) {
                supported = true;
            }
        }

        return supported;
    };
})();