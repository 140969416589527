import { observable, action, computed, autorun } from 'mobx';
import { S3 } from 'aws-sdk';
import isMobile from 'is-mobile';
import AutorunStore from 'stores/abstractStores/autorunStore';
import S3Store from 'stores/s3Store';
import LambdaStore from 'stores/lambdaStore';
import CognitoStore from 'stores/cognitoStore';
import { authStore } from 'stores/authStore';
import { clientStore } from 'stores/clientStore';
import { Link } from 'components/s3FileBrowser/s3Prompt/s3CreateLinksPrompt/S3CreateLinksPrompt';
import { concatRouteParts } from 'utils/strings';
import { getEnv } from 'utils/env';

interface Props {
    relativeS3Path: string;
    fileBrowserAppRoute: string;
    userToken: string;
    parentS3Folder: ParentS3Folder;
    enableBaseFolder: boolean;
};

type ParentS3Folder = 'fs'|'cf';

interface FetchAttributes {
    loadingTree: boolean;
    fetchError: boolean;
    tree: Tree|null;
};

export interface Tree {
    [key: string]: string|Tree;
};

class S3FileBrowserStore extends AutorunStore {
    @observable
    public relativeS3Path: string;

    @observable
    public fileBrowserAppRoute: string;

    @observable
    public tree: Tree|null = null;

    @observable
    public loadingTree: boolean = false;

    @observable
    public fetchError: boolean = false;

    @observable
    public snackbarMessage: string|null = null;

    @observable
    public draggingPreview: boolean = false;

    @observable
    public userToken: string;

    @observable
    private s3: S3Store;

    @observable
    private lambda: LambdaStore;

    @observable
    private cognito: CognitoStore;

    @observable
    public invalidUserToken: boolean = false;

    private readonly parentS3Folder: ParentS3Folder;

    private readonly enableBaseFolder: boolean;

    constructor({ relativeS3Path, fileBrowserAppRoute, userToken, parentS3Folder, enableBaseFolder }: Props) {
        super();
        this.relativeS3Path = relativeS3Path;
        this.fileBrowserAppRoute = fileBrowserAppRoute;
        this.userToken = userToken;
        this.s3 = new S3Store({
            access: parentS3Folder === 'cf' ? 'user-writable' : 'pjx-writable-client-readable'
        });
        this.lambda = new LambdaStore();
        this.cognito = new CognitoStore();
        this.parentS3Folder = parentS3Folder;
        this.enableBaseFolder = enableBaseFolder;
    };

    public activate() {
        super.activate();
        this.addAutorun(autorun(() => {
            this.invalidUserToken = false;

            if (!authStore.user || !authStore.awsCredentials) {
                this.s3.prefix = null;
                clientStore.loadClient({ name: '' });
                return;
            }

            if (authStore.isPjxUser) {
                this.cognito.getUserAttributes(this.userToken).then((attributes) => {
                    const identityId = attributes['custom:identity_id'];
                    if (!identityId) {
                        this.s3.prefix = null;
                    } else {
                        this.s3.prefix = `${getEnv()}/${identityId}/${this.parentS3Folder}`;
                    }
                    clientStore.loadClient({
                        name: attributes['custom:company_name'],
                        logo: attributes['custom:logo'] ? attributes['custom:logo'] : null
                        // primaryColor: attributes['custom:primary_color'],
                        // secondaryColor: attributes['custom:secondary_color']
                    });
                    this.invalidUserToken = false;
                }, () => {
                    this.invalidUserToken = true;
                    this.s3.prefix = null;
                    clientStore.loadClient({ name: '' });
                });
                return;
            }

            if (authStore.user.username !== this.userToken) {
                this.invalidUserToken = true;
                this.s3.prefix = null;
                clientStore.loadClient({ name: '' });
                return;
            }

            const identityId = authStore.user.attributes['custom:identity_id'];
            this.s3.prefix = `${getEnv()}/${identityId}/${this.parentS3Folder}`;
            clientStore.loadClient({
                name: authStore.user.attributes['custom:company_name'],
                // primaryColor: authStore.user.attributes['custom:primary_color'],
                // secondaryColor: authStore.user.attributes['custom:secondary_color']
            });
        }));
    };

    public clean() {
        super.clean();
        clientStore.loadClient({ name: '' });
    };

    @computed
    public get bucket(): string {
        if (S3Store.bucket === null) {
            throw new Error('Bucket Not Initialized');
        }

        return S3Store.bucket;
    };

    @computed
    public get thumbnailBucket(): string {
        if (S3Store.thumbnailBucket === null) {
            throw new Error('Bucket Not Initialized');
        }

        return S3Store.thumbnailBucket;
    };

    @computed
    public get baseS3Folder(): string {
        if (this.s3.prefix === null) {
            return '';
        }

        return this.s3.prefix;
    };

    public fetchTree(silent: boolean = false): Promise<void> {
        this.setFetchAttributes({
            loadingTree: silent ? false : true,
            fetchError: false,
            tree: silent ? this.tree : null
        });

        let key = this.getS3KeyFromRelativePath(this.relativeS3Path);

        if (!key.endsWith('/')) {
            key += '/';
        }

        if (key.startsWith('/')) {
            key = key.slice(1);
        }

        return this.s3.listObjectsV2({
            Bucket: this.bucket,
            MaxKeys: 2147483647, // Maximum allowed by S3 API
            Prefix: key,
            StartAfter: key // removes the folder name from listing
        }).then((data) => {
            if (!data.Contents) {
                throw new Error('No Contents Found');
            }

            return data.Contents.reduce((tree: Tree, file: S3.Object): Tree => {
                let fileKey = file.Key;
                if (!fileKey) {
                    return tree;
                }

                let folder = tree;
                const relativeKey = fileKey.slice(key.length);
                const splitKey = relativeKey.split('/');
                splitKey.forEach((keyPart, i) => {
                    if (!keyPart || !fileKey) {
                        return;
                    }
                    if (i === splitKey.length - 1) {
                        folder[keyPart] = fileKey;
                    } else {
                        if (!folder[keyPart]) {
                            folder[keyPart] = {};
                        }
                        folder = folder[keyPart] as Tree;
                    }
                });
                return tree;
            }, {});
        }).then((tree: Tree) => {
            this.setFetchAttributes({
                loadingTree: false,
                fetchError: false,
                tree: tree
            });
        }, (err) => {
            this.setFetchAttributes({
                loadingTree: false,
                fetchError: true,
                tree: null
            });
        });
    };

    @action
    public setFetchAttributes({loadingTree, fetchError, tree}: FetchAttributes): void {
        this.loadingTree = loadingTree;
        this.fetchError = fetchError;
        this.tree = tree;
    };

    @computed
    public get editMode(): boolean {
        return this.s3.permissionType === 'read-write';
    };

    @computed
    public get isBaseFolder(): boolean {
        return this.enableBaseFolder && this.relativeS3Path === '';
    };

    public getFileUrl(key: string, bucket?: string): string {
        return `https://${bucket || this.bucket}.s3.amazonaws.com/${encodeURIComponent(key)}`;
    };

    public deleteItem(key: string, isFile: boolean): Promise<S3.DeleteObjectOutput|S3.DeleteObjectsOutput> {
        return new Promise((resolve, reject) => {
            if (isFile) {
                this.s3.deleteObject({
                    Bucket: this.bucket,
                    Key: key
                }).then(resolve, reject);
                return;
            }

            if (!key.endsWith('/')) {
                key += '/';
            }

            this.s3.listObjectsV2({
                Bucket: this.bucket,
                Prefix: key
            }).then((data) => {
                if (!data.Contents || !data.Contents.length) {
                    reject(new Error('Folder not Found'));
                    return;
                };

                const numberOfItems = data.Contents.length;

                const params = {
                    Bucket: this.bucket,
                    Delete: {
                        Objects: [] as Array<{Key: string}>
                    }
                };

                data.Contents.forEach(function(content) {
                    if (content.Key === undefined) {
                        return;
                    }
                    params.Delete.Objects.push({
                        Key: content.Key
                    });
                });

                this.s3.deleteObjects(params).then((data) => {
                    if(data.Deleted && data.Deleted.length && numberOfItems >= 1000) {
                        const prevDeleted = data.Deleted;
                        this.deleteItem(key ,isFile).then((data: S3.DeleteObjectsOutput) => {
                            data.Deleted = data.Deleted ? [...prevDeleted, ...data.Deleted] : prevDeleted;
                            resolve(data);
                        });
                    } else {
                        resolve(data);
                    }
                }, reject);
            }, reject);
        });
    };

    public uploadItems(files: Array<File>, relativeS3FolderPath: string, isFullS3Key: boolean = false): Promise<PromiseSettledResult<unknown>[]> {
        return Promise.allSettled(files.map((file) => {
            if (S3FileBrowserStore.isIgnoredFile(file.name)) {
                return Promise.resolve();
            }

            let contentType = file.type;
            if (!contentType) {
                const lowerCaseName = contentType.toLowerCase();
                if (lowerCaseName.endsWith('.url') || lowerCaseName.endsWith('.webloc')) {
                    contentType = 'application/internet-shortcut';
                }
            }

            interface FileWithPath extends File {
                webkitRelativePath?: string;
                path?: string;
            };

            let key = isFullS3Key ? relativeS3FolderPath : this.getS3KeyFromRelativePath(relativeS3FolderPath);

            if (key && !key.endsWith('/')) {
                key += '/';
            }

            let filePathAndName = (file as FileWithPath).webkitRelativePath || (file as FileWithPath).path || file.name;

            if (filePathAndName.startsWith('/')) {
                filePathAndName = filePathAndName.slice(1);
            }

            key += filePathAndName;

            return this.s3.putObject({
                Bucket: this.bucket,
                Key: key,
                Body: file,
                ContentDisposition: 'attachment',
                ContentType: contentType
            });
        }));
    };

    public createFolder(relativeS3FolderPath: string): Promise<S3.PutObjectOutput|void> {
        const s3Key = `${this.getS3KeyFromRelativePath(relativeS3FolderPath)}/`;

        return this.s3.putObject({
            Bucket: this.bucket,
            Key: s3Key,
            ContentLength: 0
        });
    };

    public createLinks(relativeS3FolderPath: string, links: Array<Link>): Promise<PromiseSettledResult<unknown>[]> {
        const folderKey = `${this.getS3KeyFromRelativePath(relativeS3FolderPath)}/`;
        return Promise.allSettled(links.map((link) => {
            const url = link.url; // need to clean

            return this.s3.putObject({
                Bucket: this.bucket,
                Key: `${folderKey}${link.name}.url`,
                Body: `[InternetShortcut]\nURL=${url}`,
                ContentDisposition: 'attachment',
                ContentType: 'application/internet-shortcut'
            });
        }));
    };

    public moveFile(oldS3Key: string, newS3Key: string): Promise<void> {
        if (oldS3Key === newS3Key) {
            return Promise.resolve();
        }

        return this.s3.copyObject({
            Bucket: this.bucket,
            CopySource: `/${this.bucket}/${oldS3Key.split('/').map(encodeURIComponent).join('/')}`,
            Key: newS3Key,
            ContentDisposition: 'attachment'
        }).then(() => {
            return this.s3.deleteObject({
                Bucket: this.bucket,
                Key: oldS3Key
            }).then();
        });
    };

    public moveFolder(s3FolderKey: string, newS3FolderKey: string): Promise<void> {
        if (!s3FolderKey.endsWith('/')) {
            s3FolderKey += '/'
        }

        if (!newS3FolderKey.endsWith('/')) {
            newS3FolderKey += '/'
        }

        return this.s3.listObjectsV2({
            Bucket: this.bucket,
            Prefix: s3FolderKey,
        }).then((data) => {
            if (!data.Contents || !data.Contents.length) {
                throw new Error('Folder not Found');
            };

            let fileMoved = false;

            let requestsPromise: Promise<unknown> = Promise.allSettled(data.Contents.map((content) => {
                if (content.Key === undefined) {
                    return Promise.resolve();
                }
                fileMoved = true;
                const newS3Key = `${newS3FolderKey}${content.Key.slice(s3FolderKey.length)}`;
                return this.moveFile(content.Key, newS3Key);
            }));

            if (data.IsTruncated) {
                requestsPromise = requestsPromise.then(() => {
                    if (fileMoved) {
                        this.moveFolder(s3FolderKey, newS3FolderKey);
                    }
                });
            }

            return requestsPromise.then();
        });
    };

    public s3HeadRequest(s3Key: string): Promise<S3.HeadObjectOutput> {
        return this.s3.headObject({
            Bucket: this.bucket,
            Key: s3Key
        });
    };

    public getSignedUrlForFilePromise({ s3Key, bucket = this.bucket, operation = 'getObject', expires = (60 * 5) /* 5 minutes */ }: { s3Key: string, bucket?: string, operation?: string, expires?: number }): Promise<string> {
        const params = {
            Bucket: bucket,
            Key: s3Key,
            Expires: expires
        };

        return this.s3.getSignedUrlPromise(operation, params);
    };

    public getThumbnailUrlFromS3Key(s3Key: string): string {
        return this.getFileUrl(s3Key, this.thumbnailBucket);
    };

    public getS3KeyFromRelativePath(relativeS3FolderPath: string): string {
        return concatRouteParts(this.baseS3Folder, relativeS3FolderPath);
    };

    public getRelativePathFromS3Key(s3Key: string, encodeURIComponents?: boolean): string {
        let path = s3Key.slice(this.baseS3Folder.length + 1);
        if (encodeURIComponents) {
            path = path.split('/').map(encodeURIComponent).join('/');
        }
        return path;
    };

    public goToLink(key: string): Promise<string> {
        return this.s3.getObject({
            Bucket: this.bucket,
            Key: key
        }).then(async (data) => {
            let body: string;
            if (!data.Body) {
                throw new Error('Link File Empty');
            }
            if (typeof data.Body === 'string') {
                body = data.Body;
            } else if (data.Body instanceof Blob) {
                body = await data.Body.text();
            } else {
                body = data.Body.toString();
            }

            const urlMatch = body.match(/url="?([^"\s\n\r<>]+)|<key>URL<\/key>\s*<string>([^"\s\n\r<>]+)<\/string>/i);
            if (!urlMatch) {
                throw new Error('Unable to Read Link From File');
            }

            const url = urlMatch[1] || urlMatch[2];
            if (isMobile({tablet: true, featureDetect: true})) {
                window.location.href = url;
            } else {
                window.open(url, '_blank');
            }

            return url;
        });
    };

    public getLinkFromObject(key: string): Promise<string> {
        return this.s3.getObject({
            Bucket: this.bucket,
            Key: key
        }).then(async (data) => {
            let body: string;
            if (!data.Body) {
                throw new Error('Link File Empty');
            }
            if (typeof data.Body === 'string') {
                body = data.Body;
            } else if (data.Body instanceof Blob) {
                body = await data.Body.text();
            } else {
                body = data.Body.toString();
            }

            const urlMatch = body.match(/url="?([^"\s\n\r<>]+)|<key>URL<\/key>\s*<string>([^"\s\n\r<>]+)<\/string>/i);
            if (!urlMatch) {
                throw new Error('Unable to Read Link From File');
            }

            const url = urlMatch[1] || urlMatch[2];

            return url;
        });
    };

    private static isIgnoredFile(fileName: string): boolean {
        if (fileName.startsWith('.')) {
            return true;
        }
        return false;
    };

    @computed
    public get loading(): boolean {
        return this.loadingTree || !this.s3.clientInitialized;
    };

    @computed
    public get readyToFetch(): boolean {
        return this.s3.clientInitialized && this.cognito.clientInitialized;
    };

    @computed
    public get dropzoneEnabled(): boolean {
        return this.editMode && !this.draggingPreview;
    };

    public downloadZippedFolder(): Promise<void> {
        if (!this.bucket) {
            return Promise.reject('No Bucket Set');
        }

        if (!authStore.user) {
            return Promise.reject('User Not Initialized');
        }

        if (!authStore.user.getSignInUserSession()) {
            return Promise.reject('User Session Not Initialized');
        }

        const folderKey = this.getS3KeyFromRelativePath(this.relativeS3Path);

        let env;
        try {
            env = getEnv();
        } catch (err) {
            return Promise.reject((err as Error).message);
        }

        const lambdaParams: AWS.Lambda.InvocationRequest = {
            FunctionName: `generate_zip_file_${env}`,
            InvocationType: 'RequestResponse',
            Payload: JSON.stringify({
                src_bucket: S3Store.bucket,
                folder_key: folderKey
            })
        };

        return this.lambda.invoke(lambdaParams).then((data) => {
            let response = null;
            if (data.StatusCode && ![200, 202, 204].includes(data.StatusCode)) {
                throw new Error('Zip Download Failed');
            }

            if (data.Payload && typeof data.Payload === 'string') {
                response = JSON.parse(data.Payload);

                return this.getSignedUrlForFilePromise({
                    s3Key: response.key, 
                    bucket: response.bucket
                });
            } else {
                throw new Error('Invalid Payload Received');
            }
        }, () => {
            throw new Error('Zip Download Failed');
        }).then((url: string) => {
            window.location.href = url;
        });
    };
};

export default S3FileBrowserStore;