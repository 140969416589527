import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    ImageList,
    ImageListItem,
    FormControlLabel,
    Checkbox,
    CircularProgress
} from '@mui/material';
import DatePicker from 'components/datePicker/DatePicker';
import { UserInfo } from 'stores/authStore';
import LambdaStore from 'stores/lambdaStore';
import { useStore } from 'utils/customHooks';
import {
    getDateForPulseReport,
    twoWeeksAgoForPulseReport,
    formatDate
} from 'utils/dates';
import { getEnv } from 'utils/env';

interface WrapperProps {
    open: boolean;
    onClose: () => void;
    clients: Array<UserInfo>;
};

type ModalState = 'form'|'confirmation'|'waiting'|'error'|'success';

const PulseReportGeneratorModalWrapper: React.FC<WrapperProps> = ({ open, ...otherProps }: WrapperProps): JSX.Element => {
    if (!open) {
        return <Fragment/>
    }

    return <PulseReportGeneratorModal {...otherProps}/>
};

type Props = Omit<WrapperProps, "open">

const PulseReportGeneratorModal: React.FC<Props> = observer(({ onClose, clients }: Props): JSX.Element => {
    const lambdaStore = useStore(LambdaStore);
    const [modalState, setModalState] = useState<ModalState>('form');

    const now = getDateForPulseReport();
    const twoWeeksAgo = twoWeeksAgoForPulseReport();
    const [reportAsOfDate, setReportAsOfDate] = useState<Date>(twoWeeksAgo);
    const [clientsSelected, setClientsSelected] = useState<Map<UserInfo, boolean>>(() => {
        return new Map(clients.map((client) => [client, false]));
    });

    const allSelected = useMemo<boolean>(() => {
        for (let checked of clientsSelected.values()) {
            if (!checked) {
                return false;
            }
        }
        return true;
    }, [clientsSelected]);

    const noneSelected = useMemo<boolean>(() => {
        for (let checked of clientsSelected.values()) {
            if (checked) {
                return false;
            }
        }
        return true;
    }, [clientsSelected]);

    useEffect(() => {
        const updatedClientsSelected = clients.reduce((updatedClientsSelected, client) => {
            let checked = clientsSelected.has(client) ? clientsSelected.get(client) as boolean : false;
            updatedClientsSelected.set(client, checked);
            return updatedClientsSelected;
        }, new Map<UserInfo, boolean>());

        setClientsSelected(updatedClientsSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        clients
    ]);

    const getModalContents = (): JSX.Element => {
        switch(modalState) {
            case 'form':
                return (
                    <Fragment>
                        <DialogContent style={{
                            display: 'flex',
                            flexFlow: 'column'
                        }}>
                            <div style={{
                                flexShrink: 0,
                                marginBottom: 15
                            }}>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        marginRight: 15,
                                        paddingTop: 6
                                    }}
                                >
                                    <b>Report End Date:</b>
                                </div>
                                <DatePicker
                                    selectedDate={reportAsOfDate}
                                    onDateChange={(newDate) => newDate && setReportAsOfDate(newDate)}
                                    maxDate={now}
                                    minDate={twoWeeksAgo}
                                />
                            </div>
                            <div style={{
                                flexShrink: 0
                            }}>
                                <b>Clients:</b>
                            </div>
                            <div style={{
                                flexShrink: 0,
                                marginTop: 10
                            }}>
                                { !allSelected &&
                                    <Button
                                        style={{
                                            marginRight: !noneSelected ? 20 : 0
                                        }}
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => setClientsSelected(new Map(clients.map((client) => [client, true])))}
                                    >
                                        Select All
                                    </Button>
                                }
                                { !noneSelected &&
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        disabled={noneSelected}
                                        onClick={() => setClientsSelected(new Map(clients.map((client) => [client, false])))}
                                    >
                                        Unselect All
                                    </Button>
                                }
                            </div>
                            <ImageList
                                style={{
                                    flexShrink: 1,
                                    overflow: 'auto'
                                }}
                            >
                                { clients.map((client) => {
                                    return <ClientListItem
                                        key={client.attributes.sub}
                                        client={client}
                                        checked={!!clientsSelected.get(client)}
                                        onChange={(checked: boolean) => {
                                            const updatedClientsSelected = new Map(clientsSelected);
                                            updatedClientsSelected.set(client, checked);
                                            setClientsSelected(updatedClientsSelected);
                                        }}
                                    />
                                })}
                            </ImageList>
                        </DialogContent>
                        <DialogActions>
                        <Button
                            onClick={() => onClose()}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => setModalState('confirmation')}
                            color="primary"
                            disabled={noneSelected}
                        >
                            Generate
                        </Button>
                        </DialogActions>
                    </Fragment>
                );
            case 'confirmation':
                return (
                    <Confirmation
                        onCancel={() => setModalState('form')}
                        onConfirm={() => {
                            setModalState('waiting');
                            const lambdaParams: AWS.Lambda.InvocationRequest = {
                                FunctionName: `enqueue_select_pulse_report_${getEnv()}`,
                                InvocationType: 'RequestResponse',
                                Payload: JSON.stringify({
                                    report_as_of_date: formatDate(reportAsOfDate, 'MM/DD/YYYY'),
                                    usernames: clients.filter((client) => clientsSelected.get(client)).map((client) => client.username)
                                })
                            };
                            lambdaStore.invoke(lambdaParams).then(() => {
                                setModalState('success');
                            }, () => {
                                setModalState('error');
                            });
                        }}
                    />
                );
            case 'waiting':
                return (
                    <DialogContent style={{
                        textAlign: 'center',
                        marginBottom: 20
                    }}>
                        <CircularProgress color="primary"/>
                    </DialogContent>
                );
            case 'error':
                return (
                    <Fragment>
                        <DialogContent
                            style={{
                                color: '#d60000'
                            }}
                        >
                            Pulse Report Generation Failed
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => onClose()}
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => setModalState('form')}
                                color="primary"
                            >
                                Back
                            </Button>
                        </DialogActions>
                    </Fragment>
                );
            case 'success':
                return (
                    <Fragment>
                        <DialogContent>
                            Pulse report generation started.  The report can take some time to complete.
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => onClose()}
                                color="primary"
                            >
                                OK
                            </Button>
                        </DialogActions>
                    </Fragment>
                );
            default:
                throw Error('Invalid Modal State');
        }
    };

    return (
        <Dialog
            open={true}
            onClose={() => onClose()}
            aria-labelledby="pulse-report-generator-modal"
            aria-describedby="modal-for-generating-pulse-reports"
            maxWidth='lg'
            fullWidth={true}
            disableEscapeKeyDown={modalState === 'waiting'}>
            <DialogTitle>Generate Pulse Reports</DialogTitle>
            { getModalContents() }
        </Dialog>
    );
});

interface ClientListItemProps {
    client: UserInfo;
    checked: boolean;
    onChange: (checked: boolean) => void;
};

const ClientListItem: React.FC<ClientListItemProps> = ({ client, checked, onChange }: ClientListItemProps): JSX.Element => {
    return <ImageListItem
        style={{
            marginRight: 50,
            marginTop: 5,
            marginBottom: 5
        }}
    >
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    onChange={(e, checked) => {
                        onChange(checked);
                    }}
                />
            }
            label={<div
                style={{
                    width: 200,
                    wordBreak: 'break-word'
                }}
            >
                    {client.attributes['custom:company_name']}
            </div>}
        />
    </ImageListItem>
};

interface ConfirmationProps {
    onCancel: () => void;
    onConfirm: () => void;
};

const Confirmation: React.FC<ConfirmationProps> = ({ onCancel, onConfirm }: ConfirmationProps): JSX.Element => {
    const [warningAcknowledged, setWarningAcknowledged] = useState<boolean>(false);
    return <Fragment>
        <DialogContent>
            <div>
                Pulse Report data is normally only verified for data older than 2 weeks. Please check with Ops before generating a report containing more recent data.
            </div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={warningAcknowledged}
                        onChange={(e, checked) => {
                            setWarningAcknowledged(checked);
                        }}
                    />
                }
                label="I understand"
            />
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => onCancel()}
                color="primary"
            >
                Back
            </Button>
            <Button
                onClick={() => onConfirm()}
                color="primary"
                disabled={!warningAcknowledged}
            >
                Continue
            </Button>
        </DialogActions>
    </Fragment>
};

export default PulseReportGeneratorModalWrapper;