let nextSid = 1;

abstract class BaseStore {
    public readonly sid: number;

    constructor() {
        this.sid = nextSid;
        nextSid++;
    }

    public activate(): void {};
    public clean(): void {};
};

export default BaseStore;