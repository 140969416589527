import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Line } from 'react-chartjs-2';
import { defaults as chartJsDefaults } from 'chart.js';
import palette from 'google-palette';
import PulseReportStore from 'stores/pulseReportStore';
import ChartWrapper from 'components/chartWrapper/ChartWrapper';
import { formatLabelsWithNumberSeparatorWithPrefix } from 'utils/chartjs';
import { formatNumbersWithSeparator } from 'utils/numbers';
import { getAllMonthStrings, getLongMonthStringFromShortMonthString } from 'utils/dates';

interface Props {
    pulseReportStore: PulseReportStore;
};

const RevenueByMonthChart: React.FC<Props> = observer(({pulseReportStore}: Props): JSX.Element => {
    const data = useMemo(() => {
        const data = {
            datasets: [] as Array<any>,
            labels: getAllMonthStrings(true)
        };

        const colorPalette = (palette('mpn65', pulseReportStore.presentedYears.length < 65 ? pulseReportStore.presentedYears.length : 65) || []).map((color) => `#${color}`);

        let years = pulseReportStore.presentedYears;

        years.forEach((year) => {
            const revenueByFormatByMonth = pulseReportStore.revenueByFormatByMonthByYear.get(year);

            let yearsRevenues: Array<number>
            if (revenueByFormatByMonth) {
                yearsRevenues = new Array(revenueByFormatByMonth.size);

                revenueByFormatByMonth.forEach((revenueByFormat, month) => {
                    yearsRevenues[month] = Object.values(revenueByFormat).reduce((sum, revenue) => sum + revenue, 0);
                });
            } else {
                yearsRevenues = [];
            }

            data.datasets.push({
                data: yearsRevenues,
                label: year,
                borderColor: colorPalette.shift(),
                type: 'line',
                pointRadius: 0,
                fill: false,
                lineTension: 0,
                borderWidth: 2
            });
        });

        return data;
    }, [
        pulseReportStore.presentedYears,
        pulseReportStore.revenueByFormatByMonthByYear
    ]);

    return (
        <ChartWrapper
            title="Spend By Month"
            componentClassName="revenue-by-month-by-year-chart-component"
        >
            { data === null ?
                <div>No Data Found</div>
            :
                <Line
                    data={data}
                    options={{
                        tooltips: {
                            position: 'nearest',
                            intersect: false,
                            mode: 'index',
                            callbacks: {
                                title: (item) => {
                                    const titles = item.map((labelInfo) => {
                                        return labelInfo.label ? getLongMonthStringFromShortMonthString(labelInfo.label) : '';
                                    });

                                    if (titles.length === 0) {
                                        return titles;
                                    }

                                    if (titles.length === 1 || titles.every(val => val === titles[0])) {
                                        return titles[0];
                                    }

                                    return titles;
                                },
                                label: (tooltipItem, data) => {
                                    return formatLabelsWithNumberSeparatorWithPrefix('$', tooltipItem, data, true);
                                },
                                labelColor: (tooltipItem, chart) => {
                                    let color: string;
                                    if (typeof tooltipItem.datasetIndex !== 'undefined') {
                                        color = data.datasets[tooltipItem.datasetIndex].borderColor;
                                    } else {
                                        color = chartJsDefaults.backgroundColor;
                                    }

                                    return {
                                        borderColor: chartJsDefaults.borderColor,
                                        backgroundColor: color
                                    };
                                }
                            }
                        },
                        responsive:true,
                        maintainAspectRatio: false,
                        scales: {
                            yAxes: [{
                                ticks: {
                                    callback: function(label) {
                                        return `$${formatNumbersWithSeparator(label)}`;
                                    },
                                    beginAtZero: true
                                },
                                gridLines: {
                                    drawBorder: false
                                },
                            }],
                            xAxes: [{
                                distribution: 'series',
                                offset: true,
                                ticks: {
                                    major: {
                                        enabled: true,
                                        fontStyle: 'bold'
                                    },
                                    source: 'data',
                                }
                            }]
                        }
                    }}
                />
            }
        </ChartWrapper>
    );
});

export default RevenueByMonthChart;