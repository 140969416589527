import React, { useMemo, useState, useEffect, Fragment } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableSortLabel,
    Button,
    Input
} from '@mui/material';
import {
    Edit as EditIcon,
    Check as CheckIcon,
    Close as CloseIcon
} from '@mui/icons-material';
import S3Delete from 'components/s3FileBrowser/s3Actions/s3Delete/S3Delete';
import S3CreateFolder from 'components/s3FileBrowser/s3Actions/s3CreateFolder/S3CreateFolder';
import S3FileBrowserStore, { Tree } from 'stores/s3FileBrowserStore';
import {
    getNameWithoutExtention,
    getIsLinkExtention,
    concatRouteParts
} from 'utils/strings';
import { useIsMobileMediaQuery, useIsTabletMediaQuery } from 'utils/reactMediaQueries';
import './S3BaseFolder.scss';

const STANDARD_ROW_LINKS = [
    'Pre-Planning',
    'Media Plan',
    'Production',
    'POP',
    'Reporting',
    'ATBs',
    'Pulse Report'
];

interface Props {
    s3FileBrowserStore: S3FileBrowserStore;
};

interface MaterialsData {
    title: string;
    componentKey: string;
    onClick: () => void;
}

interface RowData {
    title: string,
    materials: Array<MaterialsData>;
};

type Order = 'asc' | 'desc';

const S3BaseFolder: React.FC<Props> = observer(({s3FileBrowserStore}: Props): JSX.Element => {
    const history = useHistory();
    const location = useLocation();
    const [order, setOrder] = useState<Order>('asc');

    const rowsData = useMemo<Array<RowData>>(() => {
        if (!s3FileBrowserStore.tree) {
            return [];
        }

        return (Object.entries(s3FileBrowserStore.tree).filter(([name, s3KeyOrTree]) => {
            return typeof s3KeyOrTree === 'object';
        }) as Array<[string, Tree]>).map(([folderName, tree]): RowData => {
            const materials: Array<MaterialsData> = Object.entries(tree).filter(([name]) => {
                return STANDARD_ROW_LINKS.includes(getNameWithoutExtention(name));
            }).sort(([nameA], [nameB]): number => {
                const indexA = STANDARD_ROW_LINKS.indexOf(getNameWithoutExtention(nameA));
                const indexB = STANDARD_ROW_LINKS.indexOf(getNameWithoutExtention(nameB));
                if (indexA < indexB) {
                    return -1;
                }
                if (indexA > indexB) {
                    return 1;
                }
                return 0;
            }).map(([name, s3KeyOrTree]): MaterialsData => {
                return {
                    title: getNameWithoutExtention(name),
                    componentKey: name,
                    onClick: () => {
                        if (typeof s3KeyOrTree === 'string') {
                            if (getIsLinkExtention(s3KeyOrTree)) {
                                s3FileBrowserStore.goToLink(s3KeyOrTree);
                            } else {
                                s3FileBrowserStore.getSignedUrlForFilePromise({ s3Key: s3KeyOrTree }).then((url: string) => {
                                    window.location.href = url;
                                }, () => {
                                    s3FileBrowserStore.snackbarMessage = 'Error Downloading File';
                                });
                            }
                        } else {
                            history.push('/' + concatRouteParts(location.pathname, encodeURIComponent(folderName), encodeURIComponent(name)));
                        }
                    }
                }
            });

            materials.unshift({
                title: 'All Files',
                componentKey: 'All Files',
                onClick: () => {
                    history.push('/' + concatRouteParts(location.pathname, encodeURIComponent(folderName)));
                }
            });

            return {
                title: folderName,
                materials: materials
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        s3FileBrowserStore,
        s3FileBrowserStore.tree,
        history,
        location.pathname
    ]);

    return <div className="s3-base-folder" style={{width: '100%'}}>
        <div style={{
            maxWidth: 1000,
            margin: '20px auto 0 auto',
        }}>
            <Fragment>
                { s3FileBrowserStore.editMode &&
                    <span
                        style={{
                            marginRight: 25
                        }}
                    >
                        <S3CreateFolder
                            folderPath="/"
                            s3FileBrowserStore={s3FileBrowserStore}
                            buttonStyle="button"
                            folderAlias="Row"
                        />
                    </span>
                }
                <Link
                    to={`/client-upload/${s3FileBrowserStore.userToken}`}
                    style={{ textDecoration: 'none' }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                    >
                        Upload Folder
                    </Button>
                </Link>
            </Fragment>
        </div>
        <TableContainer style={{
            maxWidth: 1000,
            margin: '20px auto 0 auto',
        }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={true}
                                direction={order}
                                onClick={() => { setOrder(order === 'asc' ? 'desc' : 'asc') }}
                            >
                                <b>Title</b>

                            </TableSortLabel>
                        </TableCell>
                        <TableCell><b>Materials</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { (order === 'asc' ? rowsData : rowsData.slice().reverse()).map(({ title, materials }) => {
                        return <Row
                            title={title}
                            materials={materials}
                            s3FileBrowserStore={s3FileBrowserStore}
                            key={title}
                        />
                    }) }
                </TableBody>
            </Table>
        </TableContainer>
    </div>;
});

interface RowProps {
    title: string;
    materials: Array<MaterialsData>;
    s3FileBrowserStore: S3FileBrowserStore;
}

const Row: React.FC<RowProps> = ({ title, materials, s3FileBrowserStore }: RowProps) => {
    const location = useLocation();

    const [editMode, setEditMode] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [newTitle, setNewTitle] = useState<string>(title);

    const isMobileMediaQuery = useIsMobileMediaQuery();
    const isTabletMediaQuery = useIsTabletMediaQuery();

    const buttonWidth = 100;
    const buttonMarginRight = 10;
    const buttonsPerRowMobile = 1;
    const buttonsPerRowTabiet = 3;
    const buttonsPerRowDesktop = 5;

    const buttonsPerRow = isMobileMediaQuery ? buttonsPerRowMobile : (isTabletMediaQuery ? buttonsPerRowTabiet : buttonsPerRowDesktop);

    const buttonCellWidth = (buttonWidth + buttonMarginRight) * buttonsPerRow - buttonMarginRight;
    const buttonMarginMod = isMobileMediaQuery ? buttonsPerRowMobile : (isTabletMediaQuery ? buttonsPerRowTabiet : buttonsPerRowDesktop);

    useEffect(() => {
        if (editMode) {
            setNewTitle(title);
        }
    }, [
        editMode,
        title
    ]);

    const changeTitle = () => {
        if (title === newTitle) {
            setEditMode(false);
        }

        if (!s3FileBrowserStore.tree) {
            return s3FileBrowserStore.snackbarMessage = 'Error Changing Title';
        }

        if (s3FileBrowserStore.tree[newTitle]) {
            return s3FileBrowserStore.snackbarMessage = `"${newTitle}" already exists`;
        }

        const oldS3Key = s3FileBrowserStore.getS3KeyFromRelativePath(title);
        const newS3Key = s3FileBrowserStore.getS3KeyFromRelativePath(newTitle);
        setDisabled(true);
        let success: boolean;
        s3FileBrowserStore.moveFolder(oldS3Key, newS3Key).then(() => {
            success = true;
        }, () => {
            s3FileBrowserStore.snackbarMessage = 'Error Changing Title';
            success = false;
        }).finally(() => {
            return s3FileBrowserStore.fetchTree(true);
        }).finally(() => {
            setDisabled(false);
            setEditMode(!success);
            if (success) {
                s3FileBrowserStore.snackbarMessage = 'Title Changed';
            }
        });
    };

    return <TableRow>
        <TableCell
            style={{
                paddingTop: 16,
                cursor: 'default'
            }}
        >
            { editMode ?
                s3FileBrowserStore.editMode &&
                    <Fragment>
                        <Input
                            value={newTitle}
                            onChange={(e) => setNewTitle(e.target.value)}
                            autoFocus={true}
                            disabled={disabled}
                            onKeyDown={(e) => {
                                if (e.key !== 'Enter') {
                                    if (e.key === 'Escape') {
                                        setEditMode(false);
                                    }
                                    return;
                                }

                                changeTitle();
                            }}
                        />
                        { !disabled &&
                            <Fragment>
                                <CheckIcon
                                    style={{
                                        height: 16
                                    }}
                                    onClick={() => changeTitle()}
                                />
                                <CloseIcon
                                    style={{
                                        height: 16
                                    }}
                                    onClick={() => setEditMode(false)}
                                />
                            </Fragment>
                        }
                    </Fragment>
            :
                <Fragment>
                    <Link 
                        to={'/' + concatRouteParts(location.pathname, encodeURIComponent(title))}
                    >{ title }</Link>
                    { s3FileBrowserStore.editMode &&
                        <Fragment>
                            <EditIcon
                                onClick={() => setEditMode(true)}
                                style={{
                                    height: 16,
                                    cursor: 'pointer',
                                    marginBottom: -2,
                                    marginLeft: 20
                                }}
                            />
                            <S3Delete
                                s3FileBrowserStore={s3FileBrowserStore}
                                isFile={false}
                                s3Key={s3FileBrowserStore.getS3KeyFromRelativePath(title)}
                                buttonStyle="icon"
                            />
                        </Fragment>
                    }
                </Fragment>
            }
        </TableCell>
        <TableCell style={{
            width: buttonCellWidth
        }}>
            { materials.map(({ title, onClick, componentKey }, i) => {
                return <Button
                    key={componentKey}
                    variant="contained"
                    color="primary"
                    style={{
                        minWidth: buttonWidth,
                        paddingLeft: 8,
                        paddingRight: 8,
                        marginTop: 16,
                        marginRight: ((i + 1) % buttonMarginMod && i !== materials.length - 1) ? buttonMarginRight : 0
                    }}
                    onClick={onClick}
                >
                    {title}
                </Button>
            }) }
        </TableCell>
    </TableRow>
}

export default S3BaseFolder;