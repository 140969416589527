export const round = (number: number, decimals: number = 0) => {
    const multiplierDivider = Math.pow(10, decimals);
    return Math.round((number + Number.EPSILON) * multiplierDivider) / multiplierDivider;
};

interface FormatNumbersWithSeparator {
    (value:number, roundToInt?: boolean): string;
    <T extends string|number>(param:T, roundToInt?: boolean): T;
};

export const formatNumbersWithSeparator: FormatNumbersWithSeparator = <T extends string|number>(value: T, roundToInt: boolean = true): T|string => {
    let numberVersionOfValue = (typeof value === 'string' && !isNaN(value as any)) ? Number(value) : value;

    if (typeof numberVersionOfValue === 'number') {
        if (roundToInt) {
            numberVersionOfValue = Math.round(numberVersionOfValue);
        }
        return numberVersionOfValue.toLocaleString();
    }

    return value;
};