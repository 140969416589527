import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress
} from '@mui/material';
import './S3Prompt.scss';

interface Props {
    open: boolean;
    onClose: () => unknown;
    onConfirm: () => unknown;
    waitingForResponse: boolean;
    title: string;
    children: JSX.Element;
    maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl";
    confirmButtonText?: string;
    className?: string;
    onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => unknown;
};

const S3Prompt: React.FC<Props> = ({open, waitingForResponse, onClose, onConfirm, onKeyPress, title, children, confirmButtonText, className, maxWidth}: Props): JSX.Element => {
    return <Dialog
        open={open}
        onClose={() => onClose()}
        onKeyPress={(e) => onKeyPress && onKeyPress(e)}
        className={`s3-prompt ${className || ''} ${waitingForResponse ? 'loading' : ''}`}
        maxWidth={maxWidth}
        fullWidth={Boolean(maxWidth)}
    >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={{textAlign: waitingForResponse ? 'center' : 'inherit'}} className="s3-prompt-dialog-content">
            { waitingForResponse &&
                <div className="s3-folder-button-spinner-container">
                    <CircularProgress className="s3-folder-button-spinner" size={25}/>
                </div>
            }
            { children }
        </DialogContent>
        <DialogActions>
        <Button onClick={(e) => { e.stopPropagation(); onClose(); }} color="primary" disabled={waitingForResponse}>
            Cancel
        </Button>
        <Button onClick={(e) => { e.stopPropagation(); onConfirm() }} color="primary" disabled={waitingForResponse}>
            { confirmButtonText || 'Confirm' }
        </Button>
        </DialogActions>
    </Dialog>
};

export default S3Prompt;