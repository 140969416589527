import BaseStore from 'stores/abstractStores/baseStore';
import { CardSizeType } from 'components/cardGrid/CardGrid';
import { observable } from 'mobx';

const DEFAULT_POSITION: number = 6;
const DEFAULT_SIZE: CardSizeType = 'lg';

export interface Props {
    url: string;
    position?: number;
    size?: CardSizeType;
};

class CampaignImageStore extends BaseStore {
    @observable
    public url: string;

    @observable
    public position: number;

    @observable
    public size: CardSizeType;

    constructor({ url, position, size }: Props) {
        super();
        this.url = url;
        this.position = (position || position === 0) ? position : DEFAULT_POSITION;
        this.size = size ? size : DEFAULT_SIZE;
    }
};

export default CampaignImageStore;