import { computed } from 'mobx';
import {
    S3,
    Lambda,
    CognitoIdentityServiceProvider
} from 'aws-sdk';
import { authStore } from 'stores/authStore';
import BaseStore from 'stores/abstractStores/baseStore';

type Client = S3|Lambda|CognitoIdentityServiceProvider;

type PromiseOrPromisable<T> = Promise<T>|AWS.Request<T, AWS.AWSError>;

abstract class AwsServiceStore extends BaseStore {
    protected abstract readonly serviceName: string;
    protected abstract get client(): Client|null;

    protected callAwsSdkMethod<P extends any[], T>(method: ((...params: P) => PromiseOrPromisable<T>)|undefined, ...params: P): Promise<T> {
        return authStore.awsCallWithRefreshSession(() => {
            if (this.client === null || !method) {
                return Promise.reject(new Error(`${this.serviceName} client not initialized`));
            }

            const request = method.call(this.client, ...params);

            if (request instanceof Promise) {
                return request;
            }

            return request.promise();
        });
    };

    @computed
    public get clientInitialized(): boolean {
        return !!this.client;
    };
};

export default AwsServiceStore;