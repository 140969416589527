import { computed, observable, autorun, action } from 'mobx';
import palette from 'google-palette';
import AutorunStore from 'stores/abstractStores/autorunStore';
import PulseReportFiltersStore from 'stores/pulseReportFiltersStore';
import { clientStore } from 'stores/clientStore';
import { authStore } from 'stores/authStore';
import S3Store from 'stores/s3Store';
import CognitoStore from 'stores/cognitoStore';
import { getEnv } from 'utils/env';
import { MonthIndex } from 'utils/dates';

interface FetchAttributes {
    loadingReport: boolean;
    fetchError: boolean;
    intacctReportData: IntacctReportData|null;
};

export type RevenueByCategoryByMonthByYear = Map<number, Map<MonthIndex, {
    [category: string]: number;
}>>;

export type RevenueByCategoryByYear = Map<number, {
    [category: string]: number;
}>;

export type ColorByCategory = {
    [category: string]: string;
};

interface IntacctReportData {
    fetchedAt: Date;
    reportAsOf: Date;
    data: Array<{
        year: number;
        month: MonthIndex;
        format: string;
        market: string;
        advertiserName: string;
        revenueValue: number;
    }>;
};

interface IntacctReportDataJson extends Omit<IntacctReportData, 'fetchedAt'|'reportAsOf'> {
    fetchedAt: string;
    reportAsOf: string;
};

interface Props {
    userToken: string;
};

class PulseReportStore extends AutorunStore {
    @observable
    public filters: PulseReportFiltersStore;

    @observable
    private intacctReportData: IntacctReportData|null;

    @observable
    public invalidUserToken: boolean = false;

    @observable
    private s3: S3Store;

    @observable
    private cognito: CognitoStore;

    @observable
    public userToken: string;

    @observable
    public loadingReport: boolean = false;

    @observable
    public fetchError: boolean = false;

    constructor({
        userToken
    }: Props) {
        super();
        this.userToken = userToken;
        this.s3 = new S3Store();
        this.cognito = new CognitoStore();
        this.intacctReportData = null;

        this.filters = new PulseReportFiltersStore();
    };

    @computed
    public get bucket(): string {
        if (S3Store.bucket === null) {
            throw new Error('Bucket Not Initialized');
        }

        return S3Store.bucket;
    };

    @computed
    public get readyToFetch(): boolean {
        return this.s3.clientInitialized && this.cognito.clientInitialized;
    };

    @action
    public setFetchAttributes({loadingReport, fetchError, intacctReportData}: FetchAttributes): void {
        this.loadingReport = loadingReport;
        this.fetchError = fetchError;
        this.intacctReportData = intacctReportData;
    };

    public activate() {
        super.activate();
        this.addAutorun(autorun(() => {
            this.invalidUserToken = false;

            if (!authStore.user || !authStore.awsCredentials) {
                this.s3.prefix = null;
                clientStore.loadClient({ name: '' });
                return;
            }

            if (authStore.isPjxUser) {
                this.cognito.getUserAttributes(this.userToken).then((attributes) => {
                    const identityId = attributes['custom:identity_id'];
                    if (!identityId) {
                        this.s3.prefix = null;
                    } else {
                        this.s3.prefix = `${getEnv()}/${identityId}/df`;
                    }
                    clientStore.loadClient({
                        name: attributes['custom:company_name'],
                        logo: attributes['custom:logo'] ? attributes['custom:logo'] : null
                        // primaryColor: attributes['custom:primary_color'],
                        // secondaryColor: attributes['custom:secondary_color']
                    });
                    this.invalidUserToken = false;
                }, () => {
                    this.invalidUserToken = true;
                    this.s3.prefix = null;
                    clientStore.loadClient({ name: '' });
                });
                return;
            }

            if (authStore.user.username !== this.userToken) {
                this.invalidUserToken = true;
                this.s3.prefix = null;
                clientStore.loadClient({ name: '' });
                return;
            }

            const identityId = authStore.user.attributes['custom:identity_id'];
            this.s3.prefix = `${getEnv()}/${identityId}/df`;
            clientStore.loadClient({
                name: authStore.user.attributes['custom:company_name'],
                // primaryColor: authStore.user.attributes['custom:primary_color'],
                // secondaryColor: authStore.user.attributes['custom:secondary_color']
            });
        }));
    };

    public clean() {
        super.clean();
        clientStore.loadClient({ name: '' });
    };

    public fetchReport(silent: boolean = false) {
        this.setFetchAttributes({
            loadingReport: silent ? false : true,
            fetchError: false,
            intacctReportData: silent ? this.intacctReportData : null
        });

        this.filters.clearFilters();

        return this.s3.getObject({
            Bucket: this.bucket,
            Key: `${this.s3.prefix}/pulse-report.json`,
            ResponseCacheControl: 'no-store'
        }).then(async (data) => {
            let body: string;
            if (!data.Body) {
                throw new Error('Pulse Report Not Found');
            }
            if (typeof data.Body === 'string') {
                body = data.Body;
            } else if (data.Body instanceof Blob) {
                body = await data.Body.text();
            } else {
                body = data.Body.toString();
            };

            const { fetchedAt, reportAsOf, ...nonDateIntacctReportData} = JSON.parse(body) as IntacctReportDataJson;
            return {
                fetchedAt: new Date(fetchedAt),
                reportAsOf: new Date(reportAsOf),
                ...nonDateIntacctReportData
            };
        }).then((intacctReportData: IntacctReportData|null) => {
            this.setFetchAttributes({
                loadingReport: false,
                fetchError: false,
                intacctReportData: intacctReportData
            });

            this.filters.updateFilters({
                years: this.years,
                markets: this.markets,
                formats: this.formats,
                advertisers: this.advertisers
            });
        }, (err) => {
            this.setFetchAttributes({
                loadingReport: false,
                fetchError: true,
                intacctReportData: null
            });

            this.filters.clearFilters();
        });
    };

    @computed
    public get revenueByMarketByMonthByYear(): RevenueByCategoryByMonthByYear {
        return this.getRevenueByCategory('market');
    };

    @computed
    public get revenueByFormatByMonthByYear(): RevenueByCategoryByMonthByYear {
        return this.getRevenueByCategory('format');
    };

    private getRevenueByCategory(cateogryKey: 'format'|'market'): RevenueByCategoryByMonthByYear {
        if (!this.intacctReportData) {
            return new Map();
        }

        return this.intacctReportData.data.reduce((revenueByCategory, lineItem) => {
            const month = lineItem.month;
            const year = lineItem.year;
            const category = lineItem[cateogryKey];
            const market = lineItem.market;
            const format = lineItem.format;
            const revenue = lineItem.revenueValue;
            const advertiser = lineItem.advertiserName;

            if (month < 0 || month > 11) {
                throw new Error('Invalid Posting Date');
            }

            const years = new Set(this.presentedYears);
            const markets = new Set(this.presentedMarkets);
            const formats = new Set(this.presentedFormats);
            const advertisers = new Set(this.presentedAdvertisers);

            if (!years.has(year) || !markets.has(market) || !formats.has(format) || !advertisers.has(advertiser)) {
                return revenueByCategory;
            }

            let revenueByCategoryByMonth = revenueByCategory.get(year);
            if (!revenueByCategoryByMonth) {
                revenueByCategoryByMonth = new Map();
                for(let i = 0; i < 12; i++) {
                    revenueByCategoryByMonth.set(i as MonthIndex, {});
                }
                revenueByCategory.set(year, revenueByCategoryByMonth);
            }

            const monthsRevenue = revenueByCategoryByMonth.get(month)!;

            if (!monthsRevenue[category]) {
                monthsRevenue[category] = 0;
            }
            monthsRevenue[category] += revenue;
            return revenueByCategory;

        }, new Map() as RevenueByCategoryByMonthByYear);
    };

    @computed
    public get selectedYears(): Array<number> {
        return this.years.filter((year) => this.filters.years.has(year));
    };

    @computed
    public get selectedMarkets(): Array<string> {
        return this.markets.filter((market) => this.filters.markets.has(market));
    };

    @computed
    public get selectedFormats(): Array<string> {
        return this.formats.filter((format) => this.filters.formats.has(format));
    };

    @computed
    public get selectedAdvertisers(): Array<string> {
        return this.advertisers.filter((advertiser) => this.filters.advertisers.has(advertiser));
    };

    @computed
    public get presentedYears(): Array<number> {
        return this.selectedYears.length ? this.selectedYears : this.years;
    };

    @computed
    public get presentedMarkets(): Array<string> {
        return this.selectedMarkets.length ? this.selectedMarkets : this.markets;
    };

    @computed
    public get presentedFormats(): Array<string> {
        return this.selectedFormats.length ? this.selectedFormats : this.formats;
    };

    @computed
    public get presentedAdvertisers(): Array<string> {
        return this.selectedAdvertisers.length ? this.selectedAdvertisers : this.formats;
    };

    @computed
    public get markets(): Array<string> {
        return this.getCategories('market');
    };

    @computed
    public get formats(): Array<string> {
        return this.getCategories('format');
    };

    @computed
    public get advertisers(): Array<string> {
        return this.getCategories('advertiserName');
    };

    private getCategories(cateogryKey: 'format'|'market'|'advertiserName'): Array<string> {
        if (!this.intacctReportData) {
            return [];
        }
        const categorySet = this.intacctReportData.data.reduce((categories, lineItem) => {
            const category = lineItem[cateogryKey];
            if (!categories.has(category)) {
                categories.add(category);
            }
            return categories;
        }, new Set() as Set<string>);

        return Array.from(categorySet).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    };

    @computed
    public get years(): Array<number> {
        if (!this.intacctReportData) {
            return [];
        }
        const yearsSet = this.intacctReportData.data.reduce((years, lineItem) => {
            years.add(lineItem.year)
            return years;
        }, new Set<number>());

        return Array.from(yearsSet).sort();
    };

    @computed
    public get revenueByMarketByYear(): RevenueByCategoryByYear {
        return this.revenueByCategoryByYear(this.revenueByMarketByMonthByYear);
    };

    @computed
    public get revenueByFormatByYear(): RevenueByCategoryByYear {
        return this.revenueByCategoryByYear(this.revenueByFormatByMonthByYear);
    };

    private revenueByCategoryByYear(revenueByCategoryByYearByMonth: RevenueByCategoryByMonthByYear): RevenueByCategoryByYear {
        const revenueByYear = new Map<number, {
            [category: string]: number;
        }>();

        revenueByCategoryByYearByMonth.forEach((revenueByCategoryByMonth, year) => {
            const yearsRevenueByCategory = {} as {
                [category: string]: number;
            };

            revenueByCategoryByMonth.forEach((revenueByCategory) => {
                Object.entries(revenueByCategory).forEach(([category, revenue]) => {
                    if (!yearsRevenueByCategory[category]) {
                        yearsRevenueByCategory[category] = 0;
                    }
                    yearsRevenueByCategory[category] += revenue;
                });
            });

            revenueByYear.set(year, yearsRevenueByCategory)
        });

        return revenueByYear;
    };

    @computed
    public get colorByMarket(): ColorByCategory {
        return this.getColorByCategory(this.markets);
    };

    @computed
    public get colorByFormat(): ColorByCategory {
        return this.getColorByCategory(this.formats);
    };

    private getColorByCategory(cateogryList: Array<string>): ColorByCategory {
        const colorPalette = (palette('mpn65', cateogryList.length < 65 ? cateogryList.length : 65) || []).map((color) => `#${color}`);
        return cateogryList.reduce((colorByCategory, category) => {
            colorByCategory[category] = colorPalette.shift()!;
            return colorByCategory;
        }, {} as {
            [category: string]: string;
        });
    };

    @action
    public setNewUserToken(userToken: string) {
        this.userToken = userToken;
        this.intacctReportData = null;
        this.fetchError = false;
        this.loadingReport = false;
    };

    @computed
    public get isDataLoaded(): boolean {
        return !!this.intacctReportData;
    };

    @computed
    public get isEmptyIntacctReport(): boolean {
        return !!(this.intacctReportData && this.intacctReportData.data.length === 0);
    };

    @computed
    public get loaded(): boolean {
        return !this.loadingReport && !!(this.intacctReportData || this.fetchError);
    };

    @computed
    public get reportAsOf(): Date|null {
        return this.intacctReportData ? this.intacctReportData.reportAsOf : null;
    };

    @computed
    public get fetchedAt(): Date|null {
        return this.intacctReportData ? this.intacctReportData.fetchedAt : null;
    };
};

export default PulseReportStore;