import { observable, action } from 'mobx';
import BaseStore from 'stores/abstractStores/baseStore';
import { createContext } from 'react';

class AppControlStore extends BaseStore {
    @observable
    public loadingFirstPage: boolean;

    // Time in ms
    public static LOGO_SPINNER_ANIMATION_DURATION = 3000;

    // Time in ms
    public static LOGO_SPINNER_ANIMATION_LOADING_TIME = 1500;

    constructor() {
        super();
        this.loadingFirstPage = true;
    };

    @action
    registerPageLoaded() {
        this.loadingFirstPage = false;
    };
};


export default AppControlStore;
export const appControlStore = new AppControlStore();
export const AppControlStoreContext = createContext(appControlStore);