import { computed } from 'mobx';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import AwsServiceStore from 'stores/abstractStores/awsServiceStore';
import { authStore, UserAttributes, UserInfo } from 'stores/authStore';
import { getEnv, Env } from 'utils/env';

export const REGION = 'us-east-1';
export const USER_POOL_ID = {
    dev: 'us-east-1_0JC1vr3UI',
    qa: 'us-east-1_U9SARLXJ0',
    demo: 'us-east-1_vj1wQQNAi',
    prod: 'us-east-1_HrFlfrcaS',
};
export const IDENTITY_POOL_ID = {
    dev: 'us-east-1:9c9f391c-7efe-41e5-a8bc-bc3c479b82ce',
    qa: 'us-east-1:d6318335-e238-452c-8238-b7c12d7e54f2',
    demo: 'us-east-1:9c856800-23a7-4005-91df-60b90e0ce19d',
    prod: 'us-east-1:917085bc-7ec8-4570-b4ec-c2b5c80ffa5a'
};
export const USER_POOL_WEB_CLIENT_ID = {
    dev: '7jffbepld71mh024fj9ck24f6u',
    qa: 'sb925slsng0p1t7cbon51p994',
    demo: '38mdhnv4kaam5rh7pr00bro3i6',
    prod: '30v2v64rl238qne6p73kkgtgl3',
};

export const PJX_USER_GROUP = 'pjx-user';
export const CLIENT_GROUP = 'client';

interface CreateUserProps {
    userId: string;
    username: string;
    password: string;
    email: string;
    companyName: string;
    logo?: string;
    primaryColor?: string;
    secondaryColor?: string;
    intacctClientId?: string;
    intacctClientIdsList?: string;
    setPermanentPassword?: boolean 
}

class CognitoStore extends AwsServiceStore {
    protected readonly serviceName = 'Cognito';

    @computed
    protected get client(): CognitoIdentityServiceProvider|null {
        if (!authStore.awsCredentials) {
            return null;
        }

        return new CognitoIdentityServiceProvider({ credentials: authStore.awsCredentials });
    };

    public listClients() {
        let env;

        try {
            env = getEnv();
        } catch (err) {
            return Promise.reject((err as Error).message);
        }

        const params = {
            UserPoolId: USER_POOL_ID[env],
            GroupName: CLIENT_GROUP,
            Limit: 60
        };

        return this.listUsersInGroup(params);
    };

    public getUserAttributes(userToken: string): Promise<UserAttributes> {
        let env: Env;
        try {
            env = getEnv();
        } catch (err) {
            return Promise.reject((err as Error).message);
        }
        const params = {
            Username: userToken,
            UserPoolId: USER_POOL_ID[env]
        };

        return this.adminGetUser(params).then((data) => {
            if (!data.UserAttributes) {
                throw new Error('Missing User Attributes');
            }

            return CognitoStore.formatRequestedUserAttributes(data.UserAttributes);
        })
    };

    public async createUser({
        userId,
        username,
        password,
        email,
        companyName,
        logo,
        primaryColor,
        secondaryColor,
        intacctClientId,
        intacctClientIdsList,
        setPermanentPassword,
    }: CreateUserProps) {
        let env: Env;
        try {
            env = getEnv();
        } catch (err) {
            throw err;
        }

        const userAttributes = [
            { Name: 'email', Value: email },
            { Name: 'email_verified', Value: "True" },
            { Name: 'preferred_username', Value: username },
            { Name: 'custom:company_name', Value: companyName },
        ];

        if (logo) userAttributes.push({ Name: 'custom:logo', Value: logo });
        if (primaryColor) userAttributes.push({ Name: 'custom:primary_color', Value: primaryColor });
        if (secondaryColor) userAttributes.push({ Name: 'custom:secondary_color', Value: secondaryColor });
        if (intacctClientId) userAttributes.push({ Name: 'custom:intacct_client_id', Value: intacctClientId });
        if (intacctClientIdsList) userAttributes.push({ Name: 'custom:intacct_ids_list', Value: intacctClientIdsList });

        const payload = {
            UserPoolId: USER_POOL_ID[env],
            Username: userId,
            DesiredDeliveryMediums: ["EMAIL"],
            // (optional) ForceAliasCreation: true || false,
            MessageAction: "SUPPRESS",
            TemporaryPassword: password,
            UserAttributes: userAttributes,
        };


        try {
            const response = await this.adminCreateUser(payload);
            await this.assignUserToGroup(userId, CLIENT_GROUP);

            if (setPermanentPassword) {
                await this.setPermanentPassword(userId, password);
            }

            return response;
        } catch (error) {
            console.error('Error creating user: ', error);
            throw error;
        }
    }

    public async assignUserToGroup(userId: string, groupName: string) {
        let env: Env;
        try {
            env = getEnv();
        } catch (err) {
            throw err;
        }

        const params = {
            Username: userId,
            UserPoolId: USER_POOL_ID[env],
            GroupName: groupName,
        };

        try {
            const response = await this.adminAddUserToGroup(params);
            return response;
        } catch (error) {
            console.error('Error assigning user to group: ', error);
            throw error;
        }
    }

    public static formatRequestedUser(user: AWS.CognitoIdentityServiceProvider.UserType): UserInfo {
        return {
            username: user.Username!,
            attributes: CognitoStore.formatRequestedUserAttributes(user.Attributes!),
            getUsername: function() {
                return this.username;
            }
        };
    };

    private static formatRequestedUserAttributes(requestedUserAttributes: AWS.CognitoIdentityServiceProvider.AttributeListType): UserAttributes {
        return requestedUserAttributes.reduce((attributes, attribute): Partial<UserAttributes> => {
            attributes[attribute.Name as keyof UserAttributes] = attribute.Value;
            return attributes;
        }, {} as Partial<UserAttributes>) as UserAttributes;
    };

    public listUsersInGroup(params: CognitoIdentityServiceProvider.ListUsersInGroupRequest, users: CognitoIdentityServiceProvider.UsersListType = []): Promise<CognitoIdentityServiceProvider.UsersListType> {
        return new Promise<CognitoIdentityServiceProvider.UsersListType>((resolve, reject) =>
            this.callAwsSdkMethod<[CognitoIdentityServiceProvider.ListUsersInGroupRequest], CognitoIdentityServiceProvider.ListUsersInGroupResponse>(this.client?.listUsersInGroup, params)
                .then(response => {
                    users = users.concat(response.Users || [])

                    if (!response.NextToken) {
                        resolve(users);
                    } else {
                        params.NextToken = response.NextToken;
                        this.listUsersInGroup(params, users)
                            .then(resolve)
                            .catch(reject);
                    }
                }).catch(reject));
    };

    public adminGetUser(params: CognitoIdentityServiceProvider.AdminGetUserRequest) {
        return this.callAwsSdkMethod<[CognitoIdentityServiceProvider.AdminGetUserRequest], CognitoIdentityServiceProvider.AdminGetUserResponse>(this.client?.adminGetUser, params);
    };

    public adminCreateUser(params: CognitoIdentityServiceProvider.AdminCreateUserRequest) {
        return this.callAwsSdkMethod<[CognitoIdentityServiceProvider.AdminCreateUserRequest], CognitoIdentityServiceProvider.AdminCreateUserResponse>(this.client?.adminCreateUser, params);
    }

    public adminAddUserToGroup(params: CognitoIdentityServiceProvider.AdminAddUserToGroupRequest) {
        return this.callAwsSdkMethod<[CognitoIdentityServiceProvider.AdminAddUserToGroupRequest], {}>(this.client?.adminAddUserToGroup, params);
    }

    public setPermanentPassword(username: string, password: string) {
        let env: Env;
        try {
            env = getEnv();
        } catch (err) {
            return Promise.reject((err as Error).message);
        }

        const params = {
            UserPoolId: USER_POOL_ID[env],
            Username: username,
            Password: password,
            Permanent: true
        };

        return this.callAwsSdkMethod<[CognitoIdentityServiceProvider.AdminSetUserPasswordRequest], CognitoIdentityServiceProvider.AdminSetUserPasswordResponse>(this.client?.adminSetUserPassword, params);
    }
};

export default CognitoStore;