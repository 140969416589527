import { startOfDay, sub, set } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

type DateFormats = 'YYYY-MM-DD'|'MM/DD/YYYY';

export const formatDate = (date: Date, format: DateFormats = 'YYYY-MM-DD'): string => {
    let month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    switch (format) {
        case 'YYYY-MM-DD':
            return [year, month, day].join('-');
        case 'MM/DD/YYYY':
            return [month, day, year].join('/');
        default:
            throw new Error('Invalid Date Format');
    }
};

export const getToday = (): Date => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate());
};

export type MonthIndex = 0|1|2|3|4|5|6|7|8|9|10|11;

export const getMonthStringFromIndex = (monthIndex: MonthIndex, abbreviated?: boolean): string => {
    switch(monthIndex) {
        case 0:
            return abbreviated ? 'Jan' : 'January';
        case 1:
            return abbreviated ? 'Feb': 'February';
        case 2:
            return abbreviated ? 'Mar': 'March';
        case 3:
            return abbreviated ? 'Apr': 'April';
        case 4:
            return abbreviated ? 'May': 'May';
        case 5:
            return abbreviated ? 'Jun': 'June';
        case 6:
            return abbreviated ? 'Jul': 'July';
        case 7:
            return abbreviated ? 'Aug': 'August';
        case 8:
            return abbreviated ? 'Sep': 'September';
        case 9:
            return abbreviated ? 'Oct': 'October';
        case 10:
            return abbreviated ? 'Nov': 'November';
        case 11:
            return abbreviated ? 'Dec': 'December';
        default:
            throw new Error('Invalid Month');
    };
};

export const getAllMonthStrings = (abbreviated?: boolean): Array<string> => {
    return Array(12).fill(0).map((_, i) => getMonthStringFromIndex(i as MonthIndex, abbreviated));
};

export const getLongMonthStringFromShortMonthString = (shortMonthString: string): string => {
    switch(shortMonthString) {
        case 'Jan':
            return 'January';
        case 'Feb':
            return 'February';
        case 'Mar':
            return 'March';
        case 'Apr':
            return 'April';
        case 'May':
            return 'May';
        case 'Jun':
            return 'June';
        case 'Jul':
            return 'July';
        case 'Aug':
            return 'August';
        case 'Sep':
            return 'September';
        case 'Oct':
            return 'October';
        case 'Nov':
            return 'November';
        case 'Dec':
            return 'December';
        default:
            throw new Error('Invalid Month');
    }
};

// possibly rename function
export const getDateForPulseReport = (dateString?: string, isStartOfDay: boolean = true, timeZone: string = 'America/New_York') => {
    // we are setting the time to 6:30 here so that the date doesnt change when converting utc to zoned time
    const date = dateString ? set(new Date(dateString), { hours: 6, minutes: 30 }) : new Date();
    const zonedDate = utcToZonedTime(date, timeZone);

    return isStartOfDay ? startOfDay(zonedDate) : zonedDate;
};

// possibly rename this too? maybe (twoWeeksAgoAtSixThirty)?
export const twoWeeksAgoForPulseReport = () => {
    const twoWeeksAgoDate = sub(getDateForPulseReport(), { days: 14 });

    return set(twoWeeksAgoDate, { hours: 6, minutes: 30 });
};