import React, { useContext } from 'react';
import { Router, Route, Switch, match } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { ClientStoreContext } from 'stores/clientStore';
import AuthedApp, { ROUTES_CONFIG as AUTHED_ROUTES_CONFIG } from 'components/authedApp/AuthedApp';
import UnauthedApp, { ROUTES_CONFIG as UNAUTHED_ROUTES_CONFIG } from 'components/unauthedApp/UnauthedApp';
import history from 'appInitialization/history';
import './App.scss';
import { getS3ImageUrl, getLabelUrl } from 'utils/label';

export type RoutesConfig = Array<{
    path: string;
    component: React.FC<{match: match<any>}>;
    exact?: boolean;
}>;


const App: React.FC = observer((): JSX.Element => {
    const clientStore = useContext(ClientStoreContext);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={clientStore.theme}>
                <Router history={history}>
                    <div className="App">
                        <Switch>
                            { AUTHED_ROUTES_CONFIG.map(({path, exact}, i) => {
                                return <Route
                                    key={i}
                                    path={path}
                                    component={AuthedApp}
                                    exact={!!exact}
                                />
                            })}
                            { UNAUTHED_ROUTES_CONFIG.map(({path, exact}, i) => {
                                return <Route
                                    key={i}
                                    path={path}
                                    component={UnauthedApp}
                                    exact={!!exact}
                                />
                            })}
                            <Route path={undefined} component={UnauthedApp}/>
                        </Switch>
                        <div className="App-footer">
                            <a className="app-footer-adstruc nostyle" href={getLabelUrl()}>
                                <span className="app-footer-text">Powered by </span>
                                <img
                                    className="app-footer-logo"
                                    src={getS3ImageUrl('logo.svg')}
                                    alt="logo"
                                />
                            </a>
                        </div>
                    </div>
                </Router>
            </ThemeProvider>
        </StyledEngineProvider>
    );
});

export default App;
