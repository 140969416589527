import { observable, action, computed } from 'mobx';
import { createContext } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { AuthState } from '@aws-amplify/ui-components';
import AWS from 'aws-sdk';
import BaseStore from 'stores/abstractStores/baseStore';
import {
    REGION,
    USER_POOL_ID,
    IDENTITY_POOL_ID,
    USER_POOL_WEB_CLIENT_ID,
    PJX_USER_GROUP
} from 'stores/cognitoStore';
import history from 'appInitialization/history';
import { getEnv } from 'utils/env';

Amplify.configure({
    Auth: {
        region: REGION,
        identityPoolId: IDENTITY_POOL_ID[getEnv()],
        userPoolId: USER_POOL_ID[getEnv()],
        userPoolWebClientId: USER_POOL_WEB_CLIENT_ID[getEnv()],
    }
});

interface CredentialsOptions {
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken?: string;
};

type Credentials = AWS.Credentials | CredentialsOptions | null | undefined;
export interface User extends CognitoUser {
    username: string;
    attributes: UserAttributes;
};

export interface UserAttributes {
    'custom:company_name': string;
    'custom:primary_color'?: string;
    'custom:secondary_color'?: string;
    'custom:logo'?: string;
    'custom:identity_id'?: string;
    'custom:intacct_client_id'?: string;
    'custom:intacct_ids_list'?: string;
    preferred_username: string;
    email: string;
    sub: string;
};

export type UserInfo = Pick<User, 'username' | 'attributes' | 'getUsername'>;

type CognitoUserSession = NonNullable<ReturnType<CognitoUser['getSignInUserSession']>>;

class AuthStore extends BaseStore {
    @observable
    public user: User|null = null;

    @observable
    public authState?: AuthState;

    @observable
    private checkingAuth: boolean = true;

    @observable
    public awsCredentials?: Credentials;

    @observable
    private tempNewUser?: User;

    @computed
    public get isPjxUser(): boolean {
        if (!this.user) {
            return false;
        }

        const signedInUserSession = this.user.getSignInUserSession();
        if (!signedInUserSession || !signedInUserSession.getAccessToken().payload['cognito:groups']) {
            return false;
        }

        return signedInUserSession.getAccessToken().payload['cognito:groups'].includes(PJX_USER_GROUP);
    };

    public signOut(): Promise<void> {
        return Auth.signOut().then(() => {
            this.handleAuthStateChange(AuthState.SignedOut, undefined);
            history.push('/');
        });
    };

    private clearUser(): void {
        this.setUser(null, null);
    };

    @action
    private setUser(user: User|null, awsCredentials: Credentials) {
        this.user = user;
        this.awsCredentials = awsCredentials;
        this.tempNewUser = undefined;
    };

    @action
    public handleAuthStateChange(nextAuthState: AuthState, authData: User|undefined): void {
        // console.log(nextAuthState); //for debugging handleAuthStateChange
        const currentUser = this.tempNewUser || this.user;
        if (nextAuthState === this.authState) {
            if (!authData || !currentUser) {
                if (!authData && !currentUser) {
                    return;
                }
            } else if (authData.attributes.sub === currentUser.attributes.sub) {
                return;
            }
        }

        this.checkingAuth = true;

        this.authState = nextAuthState;

        if (!authData) {
            this.clearUser();
            return;
        }

        this.setCredentialsFromUser(authData.getSignInUserSession(), authData);
    };

    @action
    public setCredentialsFromUser(userSession: CognitoUserSession|null, user: User): Promise<void> {
        if (!userSession) {
            this.clearUser();
            return Promise.resolve();
        }

        this.tempNewUser = user;

        AWS.config.region = REGION;
        var loginMap: { [key: string]: string; } = {};

        let idToken = userSession.getIdToken().getJwtToken() as string;
        loginMap[`cognito-idp.${REGION}.amazonaws.com/${USER_POOL_ID[getEnv()]}`] = idToken;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: IDENTITY_POOL_ID[getEnv()],
            Logins: loginMap
        });
        // @ts-ignore
        AWS.config.credentials.clearCachedId();

        return new Promise((resolve, reject) => {
            // @ts-ignore
            AWS.config.credentials.get(action((err) => {
                this.checkingAuth = false;

                if (err || !user) {
                    this.clearUser();
                    if (err) {
                        return reject(err);
                    }
                    return resolve();
                }

                this.setUser(user, AWS.config.credentials);
                resolve();
            }));
        });
    };

    public awsCallWithRefreshSession<T extends any>(awsMethodCallback: () => Promise<T>): Promise<T> {
        return awsMethodCallback().catch((err) => {
            return new Promise((resolve, reject) => {
                if (!err || err.code !== 'CredentialsError' || !this.user) {
                    reject(err);
                    return;
                }

                const signInUserSession = this.user.getSignInUserSession();
                if (!signInUserSession) {
                    reject(err);
                    return;
                }

                const user = this.user;
                this.user.refreshSession(signInUserSession.getRefreshToken(), (err, session) => {
                    if (err) {
                        return reject(err);
                    }

                    if (this.user !== user) {
                        return reject('User changged while refreshing session');
                    }

                    this.setCredentialsFromUser(session as CognitoUserSession|null, user).then(() => {
                        resolve(awsMethodCallback());
                    });
                });
            });
        });
    };
};

export default AuthStore;
export const authStore = new AuthStore();
export const AuthStoreContext = createContext(authStore);