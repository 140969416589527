import React, { useContext, useEffect } from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import { match } from 'react-router-dom';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormLabel,
    FormControlLabel,
    Checkbox,
    LinearProgress
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import PageBanner from 'components/pageBanner/PageBanner';
import CardGrid, { CardConfigInterface } from 'components/cardGrid/CardGrid';
import PulseReportStore from 'stores/pulseReportStore';
import RevenueByMarketByYearChart from 'components/pulseReportCharts/marketRevenueDoughnutChart/MarketRevenueDoughnutChart';
import RevenueByFormatByYearChart from 'components/pulseReportCharts/formatRevenueDoughnutChart/FormatRevenueDoughnutChart';
import RevenueByMonthChart from 'components/pulseReportCharts/revenueByMonthChart/RevenueByMonthChart';
import RevenueByYearChart from 'components/pulseReportCharts/revenueByYearChart/RevenueByYearChart';
import RevenueByStackedMarketChart from 'components/pulseReportCharts/marketRevenueStackedBarChart/MarketRevenueStackedBarChart';
import RevenueByStackedFormatChart from 'components/pulseReportCharts/formatRevenueStackedBarChart/FormatRevenueStackedBarChart';
import MessagePage from 'components/messagePage/MessagePage';
import { useStore } from 'utils/customHooks';
import './PulseReport.scss';
import { ClientStoreContext } from 'stores/clientStore';

interface MatchParams {
    userToken: string;
};

interface Props {
    match: match<MatchParams>;
};

const PulseReport: React.FC<Props> = observer(({match}: Props): JSX.Element => {
    const pulseReportStore = useStore(PulseReportStore, { userToken: match.params.userToken });
    const clientStore = useContext(ClientStoreContext);

    useEffect(() => {
        action('userTokenUpdate', () => {
            pulseReportStore.setNewUserToken(match.params.userToken);
        });
    }, [
        match.params.userToken,
        pulseReportStore
    ]);

    useEffect(() => {
        if (!pulseReportStore.readyToFetch) {
            return;
        }

        if (
            !pulseReportStore.isDataLoaded &&
            !pulseReportStore.loadingReport &&
            !pulseReportStore.fetchError
        ) {
            pulseReportStore.fetchReport();
        }
    }, [
        pulseReportStore,
        pulseReportStore.readyToFetch,
        pulseReportStore.isDataLoaded,
        pulseReportStore.loadingReport,
        pulseReportStore.fetchError
    ]);

    if (!pulseReportStore.loaded) {
        return <LinearProgress
            color="secondary"
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0
            }}
        />
    }

    if (pulseReportStore.fetchError) {
        return <div
            style={{
                textAlign: 'center',
                marginTop: 75,
                fontSize: 24
            }}
        >
            <MessagePage text="Something Went Wrong"/>
        </div>
    }

    if (pulseReportStore.isEmptyIntacctReport) {
        return <div
            style={{
                textAlign: 'center',
                marginTop: 75,
                fontSize: 24
            }}
        >
            <MessagePage text="No Data Available"/>
        </div>
    }

    const cardConfigs = [
        {
            component: <RevenueByYearChart pulseReportStore={pulseReportStore}/>,
            size: 'lg'
        },
        {
            component: <RevenueByMonthChart pulseReportStore={pulseReportStore}/>,
            size: 'lg'
        },
        {
            component: <RevenueByMarketByYearChart pulseReportStore={pulseReportStore}/>,
            size: 'lg'
        },
        {
            component: <RevenueByStackedMarketChart pulseReportStore={pulseReportStore}/>,
            size: 'lg'
        },
        {
            component: <RevenueByFormatByYearChart pulseReportStore={pulseReportStore}/>,
            size: 'lg'
        },
        {
            component: <RevenueByStackedFormatChart pulseReportStore={pulseReportStore}/>,
            size: 'lg'
        }
    ] as Array<CardConfigInterface>;

    return <div className="pulse-report-component">
        <PageBanner
            pageName="Pulse Report"
            subName={clientStore.name}
        />
        <div className="pulse-report-content">
            <Filters
                pulseReportStore={pulseReportStore}
            />
            <CardGrid cardConfigs={cardConfigs}/>
        </div>
        { pulseReportStore.reportAsOf &&
            <div style={{
                textAlign: 'right',
                color: '#777777',
                fontSize: 13,
                marginTop: 10,
                marginRight: 16,
                marginLeft: 16
            }}>Data as of {pulseReportStore.reportAsOf.toLocaleDateString()}</div>
        }
    </div>;
});

export default PulseReport;

interface FiltersProps {
    pulseReportStore: PulseReportStore;
};

const Filters: React.FC<FiltersProps> = observer(({ pulseReportStore }: FiltersProps): JSX.Element => {
    return <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            Filters
        </AccordionSummary>
        <AccordionDetails
            style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap'
            }}
        >
            { pulseReportStore.years.length > 1 &&
                <div>
                    <Checkboxes
                        options={pulseReportStore.years}
                        selected={pulseReportStore.filters.years}
                        onChange={(selected) => pulseReportStore.filters.years = selected}
                        title="Years"
                    />
                </div>
            }
            <Checkboxes
                options={pulseReportStore.markets}
                selected={pulseReportStore.filters.markets}
                onChange={(selected) => pulseReportStore.filters.markets = selected}
                title="Markets"
            />
            <Checkboxes
                options={pulseReportStore.formats}
                selected={pulseReportStore.filters.formats}
                onChange={(selected) => pulseReportStore.filters.formats = selected}
                title="Formats"
            />
            <Checkboxes
                options={pulseReportStore.advertisers}
                selected={pulseReportStore.filters.advertisers}
                onChange={(selected) => pulseReportStore.filters.advertisers = selected}
                title="Advertisers"
            />
        </AccordionDetails>
    </Accordion>
});

interface CheckboxesProps <T extends string|number> {
    options: Array<T>;
    selected: Set<T>;
    onChange: (selected: Set<T>) => unknown;
    title?: string;
};

const Checkboxes = <T extends string|number>({ options, selected, onChange, title }: CheckboxesProps<T>) => {
    return <div>
        { title &&
            <FormLabel>{title}</FormLabel>
        }
        { options.map((option, i) => {
            return <div key={i}>
                <FormControlLabel
                    control={<Checkbox
                        checked={selected.has(option)}
                        onChange={(e)=> {
                            const newSelectedOptions = new Set(selected);
                            if (e.target.checked) {
                                newSelectedOptions.add(option);
                            } else {
                                newSelectedOptions.delete(option);
                            }
                            onChange(newSelectedOptions);
                        }}
                        color="primary"
                        value={option}
                    />}
                    label={option}
                />
            </div>
        })}
    </div>
};