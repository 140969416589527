export type Env = 'prod'|'qa'|'demo'|'dev';

export const getEnv = (): Env => {
    let env: Env;

    switch(process.env.REACT_APP_ENV) {
        case 'prod':
        case 'qa':
        case 'demo':
            env = process.env.REACT_APP_ENV;
            break;
        case undefined:
            env = 'dev';
            break;
        default:
            throw new Error('Invalid Env');
    }

    return env;
};