import React from 'react';
import './MessagePage.scss';

interface Props {
    text: string;
    subtext?: string;
    className?: string;
}

const MessagePage: React.FC<Props> = ({text, subtext, className}: Props): JSX.Element => {
    subtext = typeof subtext !== 'undefined' ? subtext : "Don't hesitate to contact our support team at support@pjxmedia.com or (929) 269-4182 for assistance.";
    return (
        <div className={`message-page-component ${className}`}>
            <div className="message-page-text">{ text }</div>
            <div className="message-page-subtext">{ subtext }</div>
        </div>
    );
};

export default MessagePage;