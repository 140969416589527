import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Card, Fade, CircularProgress } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';
import { useDropzone } from 'react-dropzone';
import { IconType } from 'react-icons';
import { SiMicrosoftword, SiMicrosoftexcel, SiMicrosoftpowerpoint } from 'react-icons/si';
import { FaRegFileAudio, FaRegImage, FaRegFileVideo }  from 'react-icons/fa';
import { GrDocumentCsv, GrDownload } from 'react-icons/gr';
import { VscFilePdf } from 'react-icons/vsc';
import { ImLink } from 'react-icons/im';
import { AiFillFolderOpen, AiOutlineFileText } from 'react-icons/ai';
import S3FileBrowserStore from 'stores/s3FileBrowserStore';
import S3PreviewActions from '../s3Actions/s3PreviewActions/S3PreviewActions';
import { useIsComponentMounted } from 'utils/customHooks';
import { concatRouteParts, getNameWithoutLinkExtention } from 'utils/strings';
import './S3Preview.scss';

const MIN_S3_PREVIEW_IMAGE_WIDTH = 175;
const S3_PREVIEW_PADDING = 15;
export const MIN_S3_PREVIEW_PX_WIDTH = MIN_S3_PREVIEW_IMAGE_WIDTH + (S3_PREVIEW_PADDING * 2);

const REACT_DND_TYPE = 'S3Preview';

const MAX_IMAGE_RELOAD_RETRIES = 5;
const FIRST_IMAGE_RELOAD_WAIT_TIME = 500;
const TOTAL_IMAGE_RELAOD_WAIT_TIME = 30000; // must be greater than or equal to MAX_IMAGE_RELOAD_RETRIES * FIRST_IMAGE_RELOAD_WAIT_TIME
const getImageReloadWaitTime: (reloadAttempt: number) => number = (() => {
    if (
        MAX_IMAGE_RELOAD_RETRIES < 0 ||
        FIRST_IMAGE_RELOAD_WAIT_TIME < 0 ||
        TOTAL_IMAGE_RELAOD_WAIT_TIME < FIRST_IMAGE_RELOAD_WAIT_TIME * MAX_IMAGE_RELOAD_RETRIES
    ) {
        throw new Error('Invalid Image Reload Settings');
    }

    let nSum = 0;
    let nSqSum = 0;
    for (let i=1; i <= MAX_IMAGE_RELOAD_RETRIES; i++) {
        nSum += i;
        nSqSum += Math.pow(i, 2);
    }

    const a = (TOTAL_IMAGE_RELAOD_WAIT_TIME - FIRST_IMAGE_RELOAD_WAIT_TIME * nSum) / (nSqSum - nSum);
    const b = FIRST_IMAGE_RELOAD_WAIT_TIME - a;

    return (reloadAttempt: number): number => {
        if (MAX_IMAGE_RELOAD_RETRIES <= 0 || reloadAttempt <= 0) {
            return 0;
        }

        return a * Math.pow(reloadAttempt, 2) + b * reloadAttempt;
    };
})();

export const useS3PreviewDrop = ({destinationFolderS3Key, canDrop}: { destinationFolderS3Key: string, canDrop: () => boolean }) => {
    return useDrop({
        accept: REACT_DND_TYPE,
        drop: (item ,monitor) => {
            const childrenDropResult = monitor.getDropResult();
            return childrenDropResult || {
                folderS3Key: destinationFolderS3Key
            };
        },
        canDrop: canDrop
    })[1];
};

interface Props {
    name: string;
    isFile: boolean;
    s3Key: string;
    folderRoute?: string;
    s3FileBrowserStore: S3FileBrowserStore;
    width?: number;
};

interface FileProps extends Omit<Props, 'folderRoute'> {
    isFile: true;
};

interface FolderProps extends Omit<Props, 'folderRoute'> {
    isFile: false;
    folderRoute: string;
};

const S3Preview: React.FC<FileProps|FolderProps> = (props: FileProps|FolderProps): JSX.Element => {
    const { name, s3FileBrowserStore, isFile, s3Key } = props;
    const imageWidth = 'width' in props ? (props.width! - (S3_PREVIEW_PADDING * 2)) : MIN_S3_PREVIEW_IMAGE_WIDTH;
    const folderRoute = 'folderRoute' in props ? props.folderRoute : null;

    const [showImageButtons, setShowImageButtons] = useState(false);
    const [imageFound, setImageFound] = useState(false);
    const [imageReloadAttempt, setImageReloadAttempt] = useState(0);
    const [waiting, setWating] = useState(false);
    const [PreviewFileIcon, setPreviewFileIcon] = useState<null|IconType>(null);
    const [isLink, setIsLink] = useState<boolean|undefined>();
    const history = useHistory();

    const isComponentMountedRef = useIsComponentMounted();
    let imageSrc: string;
    if (isFile) {
        try {
            imageSrc = s3FileBrowserStore.getThumbnailUrlFromS3Key(s3Key);
        } catch (e) {
            imageSrc = '';
        }
    } else {
        imageSrc = '';
    }

    const [{ isDragging }, dragRef] = useDrag({
        item: {
            type: REACT_DND_TYPE
        },
        canDrag: () => s3FileBrowserStore.editMode,
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        begin: () => {
            s3FileBrowserStore.draggingPreview = true;
        },
        end: (item, monitor) => {
            s3FileBrowserStore.draggingPreview = false;
            if (monitor.didDrop()) {
                const { folderS3Key } = monitor.getDropResult();
                const trimRegx = /^\/|\/$/g;
                const trimmedS3Key = s3Key.replace(trimRegx, '');
                if (trimmedS3Key === folderS3Key.replace(trimRegx, '')) {
                    return Promise.resolve();
                }

                const newDropS3Key = concatRouteParts(folderS3Key, name);

                if (trimmedS3Key === newDropS3Key.replace(trimRegx, '')) {
                    return Promise.resolve();
                }

                let movePromise: Promise<void>
                if (isFile) {
                    movePromise = s3FileBrowserStore.moveFile(s3Key, newDropS3Key);
                } else {
                    movePromise = s3FileBrowserStore.moveFolder(s3Key, newDropS3Key);
                }

                setWating(true);

                movePromise.finally(() => {
                    s3FileBrowserStore.fetchTree(true).finally(() => {
                        if (!isComponentMountedRef.current) {
                            return;
                        }
                        setWating(true);
                    });
                }).then(() => {
                    s3FileBrowserStore.snackbarMessage = 'Move Complete';
                }, () => {
                    s3FileBrowserStore.snackbarMessage = `Error Moving ${isFile ? 'File' : 'Folder'}`;
                });
            }
        }
    });

    const dropRef = useS3PreviewDrop({
        destinationFolderS3Key: s3Key,
        canDrop: () => s3FileBrowserStore.editMode && !isFile
    });

    const { getRootProps } = useDropzone({
        onDropAccepted: (files: Array<File>) => {
            setWating(true);
            s3FileBrowserStore.uploadItems(files, s3Key, true).finally(() => {
                s3FileBrowserStore.fetchTree(true).finally(() => {
                    if (!isComponentMountedRef.current) {
                        return;
                    }
                    setWating(false);
                });
            }).then(() => {
                s3FileBrowserStore.snackbarMessage = 'Upload Complete';
            }, () => {
                s3FileBrowserStore.snackbarMessage = 'Error Uploading';
            });
        },
        onDropRejected: () => {
            s3FileBrowserStore.snackbarMessage = 'Error Uploading';
        },
        noKeyboard: true,
        noClick: true,
        noDragEventsBubbling: true,
        disabled: isFile || !s3FileBrowserStore.dropzoneEnabled
    });

    const routeToFolder = (): void => {
        if (!folderRoute) {
            return;
        }
        history.push(folderRoute)
    };

    useEffect(() => {
        if (!isFile) {
            return;
        }

        if (imageFound) {
            if (imageReloadAttempt) {
                setImageReloadAttempt(0);
            }
            return;
        }

        if (imageReloadAttempt > MAX_IMAGE_RELOAD_RETRIES) {
            return;
        }

        fetch(imageSrc, {method: 'HEAD', cache: "no-store"}).then((data) => {
            if (!isComponentMountedRef.current) {
                return;
            }
            if (!data.ok) {
                throw new Error('Image not found');
            }
            setImageFound(true);
        }).catch(() => {
            if (imageReloadAttempt >= MAX_IMAGE_RELOAD_RETRIES) {
                return;
            }
            const nextImageReloadAttempt = imageReloadAttempt + 1;

            setTimeout(() => {
                if (!isComponentMountedRef.current) {
                    return;
                }
                setImageReloadAttempt(nextImageReloadAttempt);
            }, getImageReloadWaitTime(nextImageReloadAttempt));
        });
    }, [
        isFile,
        imageSrc,
        imageFound,
        imageReloadAttempt,
        isComponentMountedRef
    ]);

    useEffect(() => {
        setImageReloadAttempt(0);
        setImageFound(false);
    }, [imageSrc]);

    useEffect(() => {
        if (PreviewFileIcon !== null) {
            setPreviewFileIcon(null);
        }

        if (!isFile || imageFound) {
            return;
        }

        s3FileBrowserStore.s3HeadRequest(s3Key).then((response) => {
            if (!isComponentMountedRef.current) {
                return;
            }
            const contentType = (response.ContentType || '').split(';')[0];
            let isLinkContentType = false;
            switch (contentType) {
                case 'application/pdf':
                    setPreviewFileIcon(() => VscFilePdf);
                    break;
                case 'text/csv':
                    setPreviewFileIcon(() => GrDocumentCsv);
                    break;
                case 'application/msword':
                case 'application/vnd.ms-word.document.macroenabled.12':
                case 'application/vnd.ms-word.template.macroenabled.12':
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
                case 'application/vnd.apple.pages':
                case 'application/x-iwork-pages-sffpages':
                case 'application/vnd.oasis.opendocument.text':
                case 'application/vnd.oasis.opendocument.text-template':
                case 'application/vnd.oasis.opendocument.text-web':
                case 'application/vnd.oasis.opendocument.text-master':
                case 'application/vnd.oasis.opendocument.text-master-template':
                case 'application/vnd.lotus-wordpro':
                case 'application/vnd.kde.kword	':
                    setPreviewFileIcon(() => SiMicrosoftword);
                    break;
                case 'application/vnd.ms-excel':
                case 'application/vnd.ms-excel.addin.macroenabled.12':
                case 'application/vnd.ms-excel.sheet.binary.macroenabled.12':
                case 'application/vnd.ms-excel.template.macroenabled.12':
                case 'application/vnd.ms-excel.sheet.macroenabled.12':
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
                case 'application/vnd.apple.numbers':
                case 'application/x-iwork-numbers-sffnumbers':
                case 'application/vnd.oasis.opendocument.spreadsheet':
                case 'application/vnd.oasis.opendocument.spreadsheet-template':
                case 'application/vnd.kde.kspread':
                    setPreviewFileIcon(() => SiMicrosoftexcel);
                    break;
                case 'application/vnd.ms-powerpoint':
                case 'application/vnd.ms-powerpoint.addin.macroenabled.12':
                case 'application/vnd.ms-powerpoint.slide.macroenabled.12':
                case 'application/vnd.ms-powerpoint.presentation.macroenabled.12':
                case 'application/vnd.ms-powerpoint.slideshow.macroenabled.12':
                case 'application/vnd.ms-powerpoint.template.macroenabled.12':
                case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                case 'application/vnd.openxmlformats-officedocument.presentationml.slide':
                case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
                case 'application/vnd.openxmlformats-officedocument.presentationml.template':
                case 'application/vnd.apple.keynote':
                case 'application/x-iwork-keynote-sffkey':
                case 'application/vnd.oasis.opendocument.presentation':
                case 'application/vnd.oasis.opendocument.presentation-template':
                case 'application/vnd.kde.kpresenter':
                    setPreviewFileIcon(() => SiMicrosoftpowerpoint);
                    break;
                case 'wwwserver/redirection':
                case 'application/internet-shortcut':
                case 'application/x-url':
                case 'message/external-body':
                case 'text/url':
                case 'text/x-url':
                    setPreviewFileIcon(() => ImLink);
                    isLinkContentType = true;
                    break;
                case null:
                    setPreviewFileIcon(() => AiOutlineFileText);
                    break;
                default:
                    const genericContentType = contentType.split('/')[0];
                    switch (genericContentType) {
                        case 'video':
                            setPreviewFileIcon(() => FaRegFileVideo);
                            break;
                        case 'audio':
                            setPreviewFileIcon(() => FaRegFileAudio);
                            break;
                        case 'image':
                            setPreviewFileIcon(() => FaRegImage);
                            break;
                        // case 'text':
                        // case 'model':
                        // case 'chemical':
                        // case 'x-conference':
                        // case 'message':
                        default:
                            const lastDot = s3Key.lastIndexOf('.');

                            if (lastDot === -1) {
                                setPreviewFileIcon(() => AiOutlineFileText);
                            }

                            switch (s3Key.slice(lastDot).toLowerCase()) {
                                case '.ppt':
                                case '.pptx':
                                    setPreviewFileIcon(() => SiMicrosoftpowerpoint);
                                    break;
                                case '.doc':
                                case '.docx':
                                    setPreviewFileIcon(() => SiMicrosoftword);
                                    break;
                                case '.xls':
                                case '.xlsx':
                                    setPreviewFileIcon(() => SiMicrosoftexcel);
                                    break;
                                case '.pdf':
                                    setPreviewFileIcon(() => VscFilePdf);
                                    break;
                                case '.csv':
                                    setPreviewFileIcon(() => GrDocumentCsv);
                                    break;
                                case '.jpeg':
                                case '.jpg':
                                case '.png':
                                case '.gif':
                                case '.tiff':
                                case '.svg':
                                case '.raw':
                                    setPreviewFileIcon(() => FaRegImage);
                                    break;
                                case '.avi':
                                case '.flv':
                                case '.wmv':
                                case '.mov':
                                case '.mp4':
                                case '.mpepg4':
                                    setPreviewFileIcon(() => FaRegFileVideo);
                                    break;
                                case '.m4a':
                                case '.flac':
                                case '.mp3':
                                case '.wav':
                                case '.wma':
                                case '.aac':
                                    setPreviewFileIcon(() => FaRegFileAudio);
                                    break;
                                case '.url':
                                case '.webloc':
                                    setPreviewFileIcon(() => ImLink);
                                    isLinkContentType = true;
                                    break;
                                default:
                                    setPreviewFileIcon(() => AiOutlineFileText);
                            }
                    }
            }

            setIsLink(isLinkContentType);
        }).catch((error: Error) => {
            setPreviewFileIcon(AiOutlineFileText);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        s3Key,
        imageFound
    ]);

    const downloadFile = (): void => {
        s3FileBrowserStore.getSignedUrlForFilePromise({ s3Key }).then((url: string) => {
            window.location.href = url;
        }, () => {
            s3FileBrowserStore.snackbarMessage = 'Error Downloading File';
        });
    };

    const formatName = (name: string): string => {
        if (isLink === false) {
            return name;
        }

        return getNameWithoutLinkExtention(name);
    };

    const cardHeight = Math.trunc(imageWidth * 2 / 3)

    return <div
        className={`s3-preview-component ${isFile ? 'file' : 'folder'}`}
        ref={dropRef}
        {...(!isFile && s3FileBrowserStore.dropzoneEnabled ? getRootProps() : {})}
        style={{
            opacity: isDragging && !waiting ? 0.5 : 1,
            pointerEvents: waiting ? 'none' : 'auto'
        }}
        onMouseEnter={() => setShowImageButtons(true)}
        onMouseLeave={() => setShowImageButtons(false)}
    >
        { waiting &&
            <div className="s3-preview-spinner-container">
                <CircularProgress
                    size={40}
                    thickness={5}
                    style={{
                        marginTop: S3_PREVIEW_PADDING + (cardHeight/2) - 20
                    }}
                />
            </div>
        }
        <div
            ref={dragRef}
            style={{
                width: imageWidth,
                padding: `${S3_PREVIEW_PADDING}px ${S3_PREVIEW_PADDING}px ${S3_PREVIEW_PADDING - 5}px ${S3_PREVIEW_PADDING}px`
            }}
        >
            { s3FileBrowserStore.editMode &&
                <S3PreviewActions
                    show={showImageButtons && !isDragging}
                    name={name}
                    s3Key={s3Key}
                    isFile={isFile}
                    s3FileBrowserStore={s3FileBrowserStore}
                />
            }
            <Card
                className="s3-preview-image-container"
                onClick={() => {
                    if (isLink && s3Key) {
                        s3FileBrowserStore.goToLink(s3Key);
                    } else if (isFile) {
                        downloadFile();
                    } else {
                        routeToFolder();
                    }
                }}
                style={{
                    backgroundColor: '#FFFFFF',
                    height: cardHeight
                }}
            >
                {
                    isFile ?
                        (imageFound ?
                            <img
                                className="s3-preview-image"
                                src={imageSrc}
                                alt={name}
                                onError={() => {
                                    if (!isComponentMountedRef.current) {
                                        return;
                                    }
                                    setImageReloadAttempt(MAX_IMAGE_RELOAD_RETRIES + 1);
                                    setImageFound(false);
                                }}
                            />
                        :
                            <div className="s3-icon-image-container">
                                {PreviewFileIcon && <PreviewFileIcon
                                    className="s3-icon-image"
                                    color="action"
                                    style={{
                                        fontSize: `${(imageWidth * 4) / 15}px`
                                    }}
                                />}
                            </div>
                        )
                    :
                        <div className="s3-icon-image-container">
                            <AiFillFolderOpen
                                className="s3-icon-image"
                                color="action"
                                style={{
                                    fontSize: `${(imageWidth * 4) / 15}px`
                                }}
                            />
                        </div>

                }
                <Fade in={showImageButtons && isFile && !isLink && !isDragging} timeout={200}>
                    <div className="s3-preview-image-buttons">
                        <Card
                            className="s3-preview-image-button"
                        >
                            <GrDownload/>
                        </Card>
                    </div>
                </Fade>
            </Card>
            <div
                className="s3-name"
                onClick={() => isFile ? downloadFile() : () => {}}
            >
                {formatName(name)}
            </div>
        </div>
    </div>
};

export default S3Preview;