import { useRef, useState, useEffect, MutableRefObject } from 'react';
import BaseStore from 'stores/abstractStores/baseStore';

interface UseStore {
    <T extends BaseStore>(StoreClass: { new(...args: []): T }): T;
    <T extends BaseStore, P extends {}>(StoreClass: { new(...args: [P]): T }, props: P): T;
};

export const useStore: UseStore = <T extends BaseStore, P extends {}|undefined>(StoreClass: { new(...args: [P]): T }, props?: P): T => {
    const [store] = useState(() => new StoreClass(props as P));
    useEffect(() => {
        store.activate();
        return () => {
            store.clean();
        };
    }, [store]);
    return store;
};

export const useIsComponentMounted = (): MutableRefObject<boolean> => {
    const componentIsMounted = useRef(true);
    useEffect(() => {
        return () => {
            componentIsMounted.current = false
        }
    }, []);
    return componentIsMounted;
};
