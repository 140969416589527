import React, { useState } from 'react';
import { TextField } from '@mui/material';
import S3Prompt from '../S3Prompt';
import { isValidFileOrFolderName } from 'utils/strings';

interface Props {
    open: boolean;
    waitingForResponse: boolean;
    onClose: () => unknown;
    onConfirm: (newFolderName: string) => unknown;
    folderAlias?: string;
};

const S3CreateFolderPrompt: React.FC<Props> = ({open, waitingForResponse, onClose, onConfirm, folderAlias}: Props): JSX.Element => {
    const [newFolderName, setNewFolderName] = useState('');
    const [error, setError] = useState(false);

    folderAlias = folderAlias || 'Folder';

    const handleChange = (newFolderName: string) => {
        if (error){
            setError(false);
        }
        setNewFolderName(newFolderName);
    };

    const confirmNewFolder = (newFolderName: string) => {
        if (!isValidFileOrFolderName(newFolderName)) {
            setError(true);
            return;
        }
        onConfirm(newFolderName);
    };

    return (
        <S3Prompt
            open={open}
            onClose={() => {
                handleChange('');
                onClose();
            }}
            onConfirm={() => confirmNewFolder(newFolderName)}
            waitingForResponse={waitingForResponse}
            maxWidth="xs"
            title={`Create New ${folderAlias}`}
            confirmButtonText="Create"
        >
            <TextField
                autoFocus
                variant="standard"
                margin="dense"
                id="name"
                label={`New ${folderAlias} Name`}
                fullWidth
                onChange={(e) => handleChange(e.target.value)}
                value={newFolderName}
                error={error}
                onKeyPress={(e) => e.key === 'Enter' && confirmNewFolder(newFolderName)}
            />
        </S3Prompt>
    );
};

export default S3CreateFolderPrompt;