import React, { useState, useMemo, useLayoutEffect, Fragment, useEffect, useCallback, CSSProperties } from 'react';
import { observer } from 'mobx-react-lite';
import { Doughnut } from 'react-chartjs-2';
import { RevenueByCategoryByYear, ColorByCategory } from 'stores/pulseReportStore';
import ChartWrapper, { ChartWrapperStyles } from 'components/chartWrapper/ChartWrapper';
import { formatLabelsWithNumberSeparatorWithPrefix } from 'utils/chartjs';
import Selector from 'components/selector/Selector';
import './RevenueDoughnutChart.scss';

interface Props {
    revenueByCategoryByYear: RevenueByCategoryByYear;
    colorByCategory: ColorByCategory;
    years: Array<number|"Total">;
    title: string;
};

const RevenueDoughnutChart: React.FC<Props> = observer(({
    revenueByCategoryByYear,
    colorByCategory,
    years,
    title
}: Props): JSX.Element => {
    const [maxLegendLabelLength, setMaxLegendLabelLength] = useState(20);
    const [width, setWidth] = useState<number|undefined>();
    const [height, setHeight] = useState<number|undefined>();

    useLayoutEffect(() => {
        if (!width) {
            return;
        }
        let offsetWidth = width - 300;
        if (offsetWidth < 0) {
            offsetWidth = 0;
        }
        setMaxLegendLabelLength(offsetWidth * (30/450) + 20);
    }, [width]);

    const getDefaultYear = useCallback(() => {
        return years.length === 1 ? years[0] : 'Total';
    }, [years]);

    const [year, setYear] = useState<number|'Total'>(getDefaultYear());

    useEffect(() => {
        if (!years.includes(year)) {
            setYear(getDefaultYear());
        }
    }, [
        year,
        years,
        getDefaultYear
    ]);

    const data = useMemo(() => {
        if (year === null) {
            return null;
        }

        let revenueByCategory: {
            [category: string]: number;
        }|undefined;
        if (year === 'Total') {
            revenueByCategory = {};

            revenueByCategoryByYear.forEach((yearsRevenueByCateogry) => {
                Object.entries(yearsRevenueByCateogry).forEach(([category, revenue]) => {
                    if (!revenueByCategory![category]) {
                        revenueByCategory![category] = 0;
                    }

                    revenueByCategory![category] += revenue;
                });
            });
        } else {
            revenueByCategory = revenueByCategoryByYear.get(year);

        }

        if (!revenueByCategory) {
            return null;
        }

        const categories = Object.keys(revenueByCategory).sort();
        const revenues = categories.map((category) => revenueByCategory ? revenueByCategory[category] : 0);

        return {
            datasets: [{
                data: revenues,
                backgroundColor: categories.map((category) => colorByCategory[category])
            }],
            labels: categories
        };
    }, [
        year,
        revenueByCategoryByYear,
        colorByCategory
    ]);

    const legendItems = useMemo(() => {
        if (!data) {
            return [];
        }
        return data.labels.map((label, i) => {
            return {
                label: label,
                color: data.datasets[0].backgroundColor[i]
            }
        });
    }, [data]);


    let chartContainerWidth: string|number|undefined;
    let chartWidth: string|number|undefined;
    if (width && height && width < height * 2) {
        chartContainerWidth = width / 2;
        chartWidth = '100%';
    } else {
        const chartTitleHeight = parseFloat(ChartWrapperStyles.chartTitleHeight as string);
        chartContainerWidth = undefined;
        chartWidth = height && height > chartTitleHeight ? height - chartTitleHeight : height;
    }

    return (
        <ChartWrapper
            title={title}
            componentClassName="revenue-by-category-by-year-chart-component"
            onWidthChange={setWidth}
            onHeightChange={setHeight}
        >
            { data === null || year === null ?
                <div>No Data Found</div>
            :
                <Fragment>
                    <div
                        style={{
                            flexGrow: 1,
                            height: '100%',
                            width: chartContainerWidth
                        }}
                    >
                        <div style={{
                            width: chartWidth,
                            height: '100%',
                            margin: '0 auto'
                        }}>
                            <Doughnut
                                data={data}
                                options={{
                                    legend: {
                                        display: false
                                    },
                                    tooltips: {
                                        position: 'nearest',
                                        callbacks: {
                                            label: (tooltipItem, data) => {
                                                const formattedLabel = formatLabelsWithNumberSeparatorWithPrefix('$', tooltipItem, data, true);
                                                return formattedLabel;
                                            }
                                        }
                                    },
                                    responsive: true,
                                    maintainAspectRatio: false
                                }}
                            />
                        </div>
                    </div>
                    <Legend
                        style={{
                            height: "100%",
                            flexGrow: 1,
                            maxHeight: 286,
                            maxWidth: '50%'
                        }}
                        items={legendItems}
                        maxLegendLabelLength={maxLegendLabelLength}
                    />
                    <Selector
                        className="year-selector"
                        value={year}
                        onChange={(year) => setYear(year as number)}
                        options={years.length === 1 ? years : ['Total', ...years]}
                    />
                </Fragment>
            }
        </ChartWrapper>
    );
});

interface LegendItem {
    label: string;
    color: string;
};
interface LegendProps {
    style?: CSSProperties;
    items: Array<LegendItem>;
    maxLegendLabelLength: number;
};

const Legend: React.FC<LegendProps> = ({style, items, maxLegendLabelLength}: LegendProps) => {
    return <div
        style={{
            ...style,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        }}
    >
        <div style={{
            width: '100%',
            height: 30
        }}/>
        <div style={{
            fontSize: 12,
            color: '#555555',
            cursor: 'default',
            userSelect: 'none',
            maxHeight: '100%',
            overflowY: 'auto'
        }}>
            { items.map(({label, color}) => {
                return <div key={label}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            height: 15,
                            width: 15,
                            backgroundColor: color,
                            borderRadius: '50%',
                            display: 'inline-block',
                            margin: '3.5px 4px',
                            flexShrink: 0
                        }}/>
                        <span>
                            {
                                label.length <= maxLegendLabelLength ?
                                    label
                                :
                                    `${label.slice(0, maxLegendLabelLength - 3)}...`
                            }
                        </span>
                    </div>
                </div>
            }) }
        </div>
        <div style={{
            width: '100%',
            height: 30
        }}/>
    </div>
};

export default RevenueDoughnutChart;