import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Paper } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import './PageBanner.scss';

interface BaseProps {
    pageName: string;
    subName?: string;
    bottomCenter?: JSX.Element|string|false;
    topLeft?: JSX.Element|string|false;
    topRight?: JSX.Element|string|false;
    bottomLeft?: JSX.Element|string|false;
    bottomRight?: JSX.Element|string|false;
    onBannerComponentsStackedChanged?: (bannerComponentsStacked: boolean) => void;
    className?: string;
};

type Props = Omit<BaseProps, 'subName'>|Omit<BaseProps, 'bottomCenter'>;

const PageBanner: React.FC<Props> = observer((props: Props): JSX.Element => {
    const {
        pageName,
        topLeft,
        topRight,
        bottomLeft,
        bottomRight,
        onBannerComponentsStackedChanged,
        className = ''
    } = props;
    const bottomCenter = 'bottomCenter' in props ? props.bottomCenter : ('subName' in props ? props.subName : undefined);

    const stackBannerComponents = useMediaQuery( { maxWidth: 999 });

    useEffect(() => {
        if (!onBannerComponentsStackedChanged) {
            return;
        }
        onBannerComponentsStackedChanged(stackBannerComponents);
    }, [
        stackBannerComponents,
        onBannerComponentsStackedChanged
    ]);

    const TopLeftWrapped = <div
            className="banner-left banner-top"
            key="bannerTopLeft"
            style={{
                marginLeft: stackBannerComponents ? 0 : 15,
                textAlign: stackBannerComponents ? 'inherit' : 'left'
            }}
        >
            { topLeft }
    </div>;

    const TopCenterWrapped = <div
        className="banner-center banner-top"
        key="bannerTopCenter"
    >
        { pageName }
    </div>;

    const TopRightWrapped = <div
        className="banner-right banner-top"
        key="bannerTopRight"
        style={{
            marginRight: stackBannerComponents ? 0 : 15,
            textAlign: stackBannerComponents ? 'inherit' : 'right'
        }}
    >
        { topRight }
    </div>;

    const BottomLeftWrapped = <div
        className="banner-left banner-bottom"
        style={{
            marginLeft: stackBannerComponents ? 0 : 15,
            textAlign: stackBannerComponents ? 'inherit' : 'left'
        }}
    >
        { bottomLeft }
    </div>;

    const BottomCenterWrapped = <div
        className="banner-center banner-bottom"
    >
        { bottomCenter }
    </div>;

    const BottomRightWrapped = <div
        className="banner-right banner-bottom"
        style={{
            marginRight: stackBannerComponents ? 0 : 16,
            textAlign: stackBannerComponents ? 'inherit' : 'right'
        }}
    >
        { bottomRight }
    </div>;

    return (
        <Paper className={`page-banner-component ${className}`} elevation={1} square>
            { stackBannerComponents ? (<>
                <>{TopCenterWrapped}</>
                <>{TopLeftWrapped}</>
                { (bottomCenter || bottomLeft || bottomRight) && <>
                    <>{BottomLeftWrapped}</>
                    <>{BottomCenterWrapped}</>
                    <>{BottomRightWrapped}</>
                </>}
                <>{TopRightWrapped}</>
            </>) : (<>
                <>{TopLeftWrapped}</>
                <>{TopCenterWrapped}</>
                <>{TopRightWrapped}</>
                { (bottomCenter || bottomLeft || bottomRight) && <>
                    <>{BottomLeftWrapped}</>
                    <>{BottomCenterWrapped}</>
                    <>{BottomRightWrapped}</>
                </>}
            </>)}
        </Paper>
    );
});

export default PageBanner;