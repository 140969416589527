import React from 'react';
import AppControlStore from 'stores/appControlStore';
import './LogoSpinner.scss';
import { getS3ImageUrl } from 'utils/label';


interface Props {
    width?: number;
};

const LogoSpinner: React.FC<Props> = ({ width }: Props): JSX.Element => {
    const mountTime = React.useRef(Date.now() % (AppControlStore.LOGO_SPINNER_ANIMATION_DURATION ));
    const animationDelay = `-${mountTime.current}ms`;
    const animationDuration = `${AppControlStore.LOGO_SPINNER_ANIMATION_DURATION}ms`;

    width = typeof width === 'number' ? width : 200;

    return (
        <div className="logo-spinner-component" style={{width: width}}>
            <img
                className="logo-spinner" style={{
                    animationDelay: animationDelay,
                    WebkitAnimationDelay: animationDelay,
                    animationDuration: animationDuration,
                    WebkitAnimationDuration: animationDuration
                }}
                src={getS3ImageUrl('logo-icon.svg')}
                alt="Logo Icon"
            />
        </div>
    );
};

export default LogoSpinner;