import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect } from "react-router-dom";
import { AuthState } from '@aws-amplify/ui-components';
import MessagePage from 'components/messagePage/MessagePage';
import { AuthStoreContext } from 'stores/authStore';

const HomePageRedirect: React.FC = observer((): JSX.Element => {
    const authStore = useContext(AuthStoreContext);

    if (authStore.authState === AuthState.SignedIn && authStore.user) {
        return <Redirect
            to={authStore.isPjxUser ? `/clients` : `/file-share/${authStore.user.username}`}
        />
    }

    return <MessagePage text="Something went wrong"/>;
});

export default HomePageRedirect;