import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { LinearProgress, Snackbar, IconButton, Portal } from '@mui/material';
import { Close } from '@mui/icons-material';
import DragAndDropContainer from 'components/dragAndDropContainer/DragAndDropContainer';
import MessagePage from 'components/messagePage/MessagePage';
import S3FileBrowserStore from 'stores/s3FileBrowserStore';
import S3BaseFolder from './s3BaseFolder/S3BaseFolder';
import S3Folder from './s3Folder/S3Folder';
import './S3FileBrowser.scss';

interface Props {
    s3FileBrowserStore: S3FileBrowserStore;
};

const S3FileBrowser: React.FC<Props> = observer(({s3FileBrowserStore}: Props): JSX.Element => {
    return (
        <div className={`s3-file-browser-component ${s3FileBrowserStore.loading ? 'loading' : ''}`}>
            {
                s3FileBrowserStore.loading ?
                    <div className="s3-file-browser-loader">
                        <LinearProgress color="secondary"/>
                    </div>
                : s3FileBrowserStore.fetchError ?
                    <MessagePage
                        className="s3-file-browser-load-error"
                        text="Something went wrong"
                    />
                : s3FileBrowserStore.isBaseFolder ?
                    <S3BaseFolder
                        s3FileBrowserStore={s3FileBrowserStore}
                    />
                :
                    <DragAndDropContainer>
                        <S3Folder
                            s3FileBrowserStore={s3FileBrowserStore}
                        />
                    </DragAndDropContainer>
            }
            <Portal>
                <Snackbar
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    open={!!s3FileBrowserStore.snackbarMessage}
                    autoHideDuration={4000}
                    onClose={() => s3FileBrowserStore.snackbarMessage = null}
                    message={s3FileBrowserStore.snackbarMessage}
                    action={
                        <Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={() => s3FileBrowserStore.snackbarMessage = null}>
                                <Close fontSize="small" />
                            </IconButton>
                        </Fragment>
                    }
                />
            </Portal>
        </div>
    );
});

export default S3FileBrowser;