import React from 'react';
import { observer } from 'mobx-react-lite';
import PulseReportStore from 'stores/pulseReportStore';
import RevenueStackedBarChart from 'components/pulseReportCharts/revenueStackedBarChart/RevenueStackedBarChart';

interface Props {
    pulseReportStore: PulseReportStore;
};

const FormatRevenueStackedBarChart: React.FC<Props> = observer(({pulseReportStore}: Props): JSX.Element => {
    return <RevenueStackedBarChart
        revenueByCategoryByMonthByYear={pulseReportStore.revenueByFormatByMonthByYear}
        selectedCategories={pulseReportStore.presentedFormats}
        colorByCategory={pulseReportStore.colorByFormat}
        years={pulseReportStore.years}
        title="Spend By Format"
    />
});

export default FormatRevenueStackedBarChart;