import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartDataSets, defaults as chartJsDefaults } from 'chart.js';
import { RevenueByCategoryByMonthByYear, ColorByCategory } from 'stores/pulseReportStore';
import ChartWrapper from 'components/chartWrapper/ChartWrapper';
import { formatLabelsWithNumberSeparatorWithPrefix } from 'utils/chartjs';
import { formatNumbersWithSeparator } from 'utils/numbers';
import {
    getAllMonthStrings,
    getLongMonthStringFromShortMonthString,
    MonthIndex
} from 'utils/dates';

interface Props {
    revenueByCategoryByMonthByYear: RevenueByCategoryByMonthByYear;
    selectedCategories: Array<string>;
    colorByCategory: ColorByCategory;
    years: Array<number>;
    title: string;
};

//@ts-ignore
// window.datas = [] as Array<ChartData>;

let counter = 0;

const RevenueStackedBarChart: React.FC<Props> = observer(({
    revenueByCategoryByMonthByYear,
    selectedCategories,
    colorByCategory,
    years,
    title
}: Props): JSX.Element => {
    const data = useMemo<ChartData|null>(() => {
        if (!selectedCategories.length) {
            return null;
        }

        const datasets = [] as Array<ChartDataSets>;

        selectedCategories.forEach((cateogry) => {
            years.forEach((year) => {
                const revenueByCategoryByMonth = revenueByCategoryByMonthByYear.get(year);
                if (!revenueByCategoryByMonth) {
                    return;
                }

                datasets.push({
                    label: cateogry,
                    backgroundColor: colorByCategory[cateogry],
                    stack: year.toString(),
                    data: Array.from({ length: 12 }).map((_, month) => {
                        const revenueByCategory = revenueByCategoryByMonth.get(month as MonthIndex);
                        if (!revenueByCategory) {
                            return 0;
                        }
                        return revenueByCategory[cateogry] || 0;
                    })
                });
            });
        });

        return {
            datasets: datasets,
            labels: getAllMonthStrings(true)
        };
    }, [
        selectedCategories,
        years,
        revenueByCategoryByMonthByYear,
        colorByCategory
    ]);

    // let data: null|ChartData;

    // if (!selectedCategories.length) {
    //     data = null;
    // }

    // const datasets = [] as Array<ChartDataSets>;

    // selectedCategories.forEach((cateogry) => {
    //     years.forEach((year) => {
    //         const revenueByCategoryByMonth = revenueByCategoryByMonthByYear.get(year);
    //         if (!revenueByCategoryByMonth) {
    //             return;
    //         }

    //         datasets.push({
    //             label: cateogry,
    //             backgroundColor: colorByCategory[cateogry],
    //             stack: year.toString(),
    //             data: Array.from({ length: 12 }).map((_, month) => {
    //                 const revenueByCategory = revenueByCategoryByMonth.get(month as MonthIndex);
    //                 if (!revenueByCategory) {
    //                     return 0;
    //                 }
    //                 return revenueByCategory[cateogry] || 0;
    //             })
    //         });
    //     });
    // });

    // data = {
    //     datasets: datasets,
    //     labels: getAllMonthStrings(true)
    // };

    // if (title === 'Spend By Market') {
    //     //@ts-ignore
    //     window.datas.push(data);
    //     console.log(data);
    //     //@ts-ignore
    //     console.log(window.datas)
    // }

    counter++;

    return (
        <ChartWrapper
            title={title}
            componentClassName="revenue-by-category-by-year-chart-component"
        >
            { data === null ?
                <div>No Data Found</div>
            :
                <Bar
                    key={counter}
                    data={data}
                    options={{
                        layout: {
                            padding: {
                                top: 26
                            }
                        },
                        legend: {
                            display: false
                        },
                        tooltips: {
                            position: 'nearest',
                            callbacks: {
                                title: (item, data) => {
                                    const titles = item.map((labelInfo) => {
                                        const datasetIndex = labelInfo.datasetIndex;
                                        const datasets = data.datasets;
                                        let year: string|undefined;
                                        if (datasets && typeof datasetIndex === 'number') {
                                            year = datasets[datasetIndex].stack;
                                        }

                                        let label = labelInfo.label ? getLongMonthStringFromShortMonthString(labelInfo.label) : '';

                                        if (year) {
                                            if (label) {
                                                label += ' ';
                                            }
                                            label += year;
                                        }

                                        return label;
                                    });

                                    if (titles.length === 0) {
                                        return titles;
                                    }

                                    if (titles.length === 1 || titles.every(val => val === titles[0])) {
                                        return titles[0];
                                    }

                                    return titles;
                                },
                                label: (tooltipItem, data) => {
                                    return formatLabelsWithNumberSeparatorWithPrefix('$', tooltipItem, data, true);
                                }
                            }
                        },
                        responsive:true,
                        maintainAspectRatio: false,
                        scales: {
                            yAxes: [{
                                ticks: {
                                    callback: function(label) {
                                        return `$${formatNumbersWithSeparator(label)}`;
                                    }
                                },
                                stacked: true
                            }],
                            xAxes: [{
                                stacked: true
                            }]
                        },
                        animation: {
                            onProgress: function(Chart) {
                                const chartInstance = Chart.chart;

                                var dataLabels: {
                                    [key: number]: [number, string|undefined];
                                } = {};

                                if (data && data.datasets) {
                                    data.datasets.forEach(function(dataset, i) {
                                        var meta = chartInstance.controller.getDatasetMeta(i);
                                        meta.data.forEach(function(bar: any, j: number) {
                                            if (
                                                !meta.hidden &&
                                                Array.isArray(dataset.data) &&
                                                j < dataset.data.length &&
                                                dataset.data[j] !== 0
                                            ) {
                                                var groupByCoordinate: number = bar._model.x;
                                                var otherCoordinate: number = bar._model.y;

                                                if(dataLabels[groupByCoordinate]) {
                                                    dataLabels[groupByCoordinate][0] = Math.min(otherCoordinate, dataLabels[groupByCoordinate][0]);
                                                    dataLabels[groupByCoordinate][1] = dataset.stack;
                                                } else {
                                                    dataLabels[groupByCoordinate] = [otherCoordinate, dataset.stack];
                                                }
                                            }
                                        });
                                    });
                                }

                                const labelMap = new Map<string ,HTMLCanvasElement>();
                                const fillStyle = typeof chartJsDefaults.global.defaultFontColor === 'string' ? chartJsDefaults.global.defaultFontColor : '#666';
                                const rotation = -0.5*Math.PI;

                                for(var key in dataLabels) {
                                    const label = dataLabels[key][1];
                                    if (!label) {
                                        continue;
                                    }

                                    let canvas = labelMap.get(label);
                                    if (!canvas) {
                                        canvas = document.createElement('canvas');
                                        const ctx = canvas.getContext('2d')!;
                                        ctx.textBaseline = "top";
                                        ctx.font = '10px "Helvetica Neue", Helvetica, Arial, sans-serif';
                                        ctx.fillStyle = fillStyle;
                                        ctx.rotate(rotation);
                                        ctx.fillText(dataLabels[key][1]!, -25, 0);
                                    }
                                    chartInstance.ctx.drawImage(canvas, Number(key) - 5, dataLabels[key][0] - 27);
                                }
                            }
                        }
                    }}
                />
            }
        </ChartWrapper>
    );
});

export default RevenueStackedBarChart;