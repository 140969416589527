import React from 'react';
import { Fade } from '@mui/material';
import S3Delete from '../s3Delete/S3Delete';
import S3Move from '../s3Move/S3Move';
import S3PreSignObject from '../s3PreSignObject/S3PreSignObject';
import S3FileBrowserStore from 'stores/s3FileBrowserStore';
import './S3PreviewActions.scss';

interface Props {
    name: string;
    s3Key: string;
    show: boolean;
    isFile: boolean;
    s3FileBrowserStore: S3FileBrowserStore;
};

const S3PreviewActions: React.FC<Props> = ({name, s3Key, show, isFile, s3FileBrowserStore}: Props): JSX.Element => {
    return <Fade in={show} timeout={200}>
        <div
            className="s3-preview-actions"
        >
            <S3Delete
                name={name}
                s3Key={s3Key}
                s3FileBrowserStore={s3FileBrowserStore}
                isFile={isFile}
                buttonStyle="fab"
            />
            <S3Move
                s3Key={s3Key}
                s3FileBrowserStore={s3FileBrowserStore}
                isFile={isFile}
                buttonStyle="fab"
            />
            <S3PreSignObject
                s3Key={s3Key}
                s3FileBrowserStore={s3FileBrowserStore}
                isFile={isFile}
                buttonStyle="fab"
            />
        </div>
    </Fade>
};

export default S3PreviewActions;