import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';
import { useLocation, match } from 'react-router-dom';
import S3FileBrowser from 'components/s3FileBrowser/S3FileBrowser';
import PageNotFound from 'components/pageNotFound/PageNotFound';
import S3FileBrowserStore from 'stores/s3FileBrowserStore';
import { useStore } from 'utils/customHooks';
import './FileShare.scss';

interface MatchParams {
    userToken: string;
};

interface Props {
    match: match<MatchParams>;
    clientWritable?: boolean;
};

const FileShare: React.FC<Props> = observer(({match, clientWritable}: Props): JSX.Element => {
    const location = useLocation();
    const path = decodeURIComponent(location.pathname.slice(match.url.length));

    const s3FileBrowserStore = useStore(S3FileBrowserStore, {
        relativeS3Path: path,
        fileBrowserAppRoute: match.url,
        userToken: match.params.userToken,
        parentS3Folder: clientWritable ? 'cf' : 'fs',
        enableBaseFolder: !clientWritable
    });

    useEffect(() => {
        action('s3FileBrowserStoreUpdate', () => {
            s3FileBrowserStore.relativeS3Path = path;
            s3FileBrowserStore.fileBrowserAppRoute = match.url;
            s3FileBrowserStore.userToken = match.params.userToken;
        });
    }, [
        path,
        match.url,
        match.params.userToken,
        s3FileBrowserStore
    ]);

    useEffect(() => {
        if (s3FileBrowserStore === null || !s3FileBrowserStore.readyToFetch) {
            return;
        }
        const path = decodeURIComponent(location.pathname.slice(match.url.length));
        if (s3FileBrowserStore.relativeS3Path !== path || (
            s3FileBrowserStore.tree === null &&
            !s3FileBrowserStore.loadingTree &&
            !s3FileBrowserStore.fetchError
        )) {
            s3FileBrowserStore.relativeS3Path = path;
            s3FileBrowserStore.fetchTree();
        }
    }, [
        location,
        match.url.length,
        s3FileBrowserStore,
        s3FileBrowserStore.readyToFetch
    ]);

    return (
        <div className="file-share-component">
            { s3FileBrowserStore.invalidUserToken ?
                <PageNotFound/>
            :
                <div className="file-share-content">
                    <S3FileBrowser
                        s3FileBrowserStore={s3FileBrowserStore}
                    />
                </div>
            }
        </div>
    );
});

type ClientWritableFileShareProps = Omit<Props, 'clientWritable'>;

export const ClientWritableFileShare: React.FC<ClientWritableFileShareProps> = (props: ClientWritableFileShareProps): JSX.Element => {
    return <FileShare
        {...props}
        clientWritable={true}
    />;
};

export default FileShare;