import React, { useContext } from 'react';
import { AuthStoreContext } from 'stores/authStore';

const SignOut: React.FC = (): JSX.Element => {
    const authStore = useContext(AuthStoreContext);

    return (
        <div
            className="sign-out-component"
            onClick={() => authStore.signOut()}
            style={{
                cursor: 'pointer',
                display: 'inline-block'
            }}
        >
            sign out
        </div>
    );
};

export default SignOut;