import React, { Fragment, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Tooltip,
    Paper,
    Portal
} from '@mui/material';
import { S3 } from 'aws-sdk';
import PageBanner from 'components/pageBanner/PageBanner';
import MessagePage from 'components/messagePage/MessagePage';
import PulseReportGeneratorModal from 'components/pulseReportGeneratorModal/PulseReportGeneratorModal';
import { AuthStoreContext, UserInfo } from 'stores/authStore';
import CognitoStore from 'stores/cognitoStore';
import S3Store from 'stores/s3Store';
import { useStore } from 'utils/customHooks';
import { getEnv } from 'utils/env';

const ClientBrowser: React.FC = observer((): JSX.Element => {
    const authStore = useContext(AuthStoreContext);
    const cognitoStore = useStore(CognitoStore);
    const history = useHistory();
    const [clients, setClients] = useState<Array<UserInfo>|undefined|null>();
    const [pulseReportGeneratorModalOpen, setPulseReportGeneratorModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!authStore.user || !authStore.isPjxUser || !cognitoStore.clientInitialized) {
            return;
        }

        cognitoStore.listClients().then((clients) => {
            let newClients;
            if (clients) {
                newClients = clients.map((client) => CognitoStore.formatRequestedUser(client));
                newClients = newClients.filter((client) => client.attributes['custom:identity_id']); // Remove once admin page is built
                newClients = newClients.sort((a, b) => a.attributes['custom:company_name'].localeCompare(b.attributes['custom:company_name']));
            } else {
                newClients = clients;
            }
            setClients(newClients);
        }, () => {
            setClients(null);
        });
    }, [
        authStore.user,
        authStore.isPjxUser,
        cognitoStore.clientInitialized,
        cognitoStore
    ]);

    if (!authStore.user) {
        return <Fragment/>
    }

    if (!authStore.isPjxUser) {
        return <MessagePage text="Access Denied"/>
    }

    const PulseReportsHeader = 'Pulse Reports';

    return (
        <div className="client-browser-component">
            <PageBanner
                pageName="Clients"
            />
            <div className="client-browser-list" style={{padding: 25, paddingLeft: '4vw', paddingRight: '4vw'}}>
                { clients === null ?
                    <h1>Error loading clients</h1>
                :
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Company</b></TableCell>
                                    <TableCell><b>Username</b></TableCell>
                                    <TableCell
                                        style={{
                                            minWidth: 116,
                                            textAlign: 'center',
                                        }}
                                    >
                                        { clients ?
                                            <Fragment>
                                                <b
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => setPulseReportGeneratorModalOpen(!pulseReportGeneratorModalOpen)}
                                                >
                                                    {PulseReportsHeader}
                                                </b>
                                                <Portal>
                                                    <PulseReportGeneratorModal
                                                        open={pulseReportGeneratorModalOpen}
                                                        onClose={() => setPulseReportGeneratorModalOpen(false)}
                                                        clients={clients}
                                                    />
                                                </Portal>
                                            </Fragment>
                                        :
                                            <b>{PulseReportsHeader}</b>
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { (clients || []).map((client) => {
                                    return <TableRow key={client.attributes.sub}>
                                        <TableCell
                                            onClick={() => history.push(`/file-share/${client.username}`)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {client.attributes['custom:company_name']}
                                        </TableCell>
                                        <TableCell
                                            onClick={() => history.push(`/file-share/${client.username}`)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {client.attributes.preferred_username}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                textAlign: 'center',
                                                minWidth: 116
                                            }}
                                        >
                                            <PulseReportButton
                                                hasIntacctIdOrIdList={!!(client.attributes['custom:intacct_client_id'] || client.attributes['custom:intacct_ids_list'])}
                                                username={client.username}
                                                identityId={client.attributes['custom:identity_id']}
                                            />
                                        </TableCell>
                                    </TableRow>
                                }) }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </div>
        </div>
    );
});

interface PulseReportButtonProps {
    hasIntacctIdOrIdList: boolean;
    username: string;
    identityId?: string;
};

const PulseReportButton: React.FC<PulseReportButtonProps> = observer(({ hasIntacctIdOrIdList, username, identityId }: PulseReportButtonProps): JSX.Element => {
    const history = useHistory();
    const authStore = useContext(AuthStoreContext);
    const cognitoStore = useStore(CognitoStore);

    const [reportGenerated, setReportGenerated] = useState<null|boolean>(null);
    useEffect(() => {
        if (!authStore.user || !authStore.isPjxUser || !cognitoStore.clientInitialized || !identityId || !hasIntacctIdOrIdList) {
            return;
        }

        const s3 = new S3({
            credentials: authStore.awsCredentials
        });

        s3.headObject({
            Bucket: S3Store.bucket,
            Key: `${getEnv()}/${identityId}/df/pulse-report.json`
        }).promise().then(() => {
            setReportGenerated(true);
        }, () => {
            setReportGenerated(false);
        });
    }, [
        authStore.awsCredentials,
        authStore.isPjxUser,
        authStore.user,
        cognitoStore.clientInitialized,
        identityId,
        hasIntacctIdOrIdList
    ]);

    const toolTipWrapper = (button: JSX.Element) => {
        if (reportGenerated === false) {
            return <Tooltip title="Pulse Report Not Generated">
                <div>
                    { button }
                </div>
            </Tooltip>
        }

        return button;
    };

    return <Fragment>
        { hasIntacctIdOrIdList && identityId &&
            toolTipWrapper(<Button
                onClick={() => history.push(`/pulse/${username}`)}
                variant="contained"
                color="primary"
                disabled={!reportGenerated}
            >
                Pulse Report
            </Button>)
        }
    </Fragment>
});

export default ClientBrowser;