import { computed } from 'mobx';
import { Lambda } from 'aws-sdk';
import AwsServiceStore from 'stores/abstractStores/awsServiceStore';
import { authStore } from 'stores/authStore';

class LambdaStore extends AwsServiceStore {
    protected readonly serviceName = 'Lambda';

    private static region = 'us-east-1';
    private static apiVersion = '2015-03-31';

    @computed
    protected get client(): Lambda|null {
        if (!authStore.awsCredentials) {
            return null;
        }

        return new Lambda({
            region: LambdaStore.region,
            apiVersion: LambdaStore.apiVersion,
            credentials: authStore.awsCredentials
        });
    };

    public invoke(params: Lambda.InvocationRequest) {
        return this.callAwsSdkMethod<[Lambda.InvocationRequest], Lambda.InvocationResponse>(this.client?.invoke, params);
    };
};

export default LambdaStore;