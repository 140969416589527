import React, { useEffect, useState, useContext, Suspense, lazy } from 'react';
import { Route, Switch, Redirect, match, useLocation } from 'react-router-dom';
import LoadingPage from 'components/loadingPage/LoadingPage';
import PageNotFound from 'components/pageNotFound/PageNotFound';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import CampaignStore from 'stores/campaignStore';
import AppControlStore, { AppControlStoreContext } from 'stores/appControlStore';
const Reporting = lazy(() => import('components/reporting/Reporting'));

interface MatchParams {
    campaignId: string;
    token: string
};

interface Props {
    match: match<MatchParams>;
};

const CampaignPageRouter: React.FC<Props> = ({ match }: Props): JSX.Element => {
    const [campaignStore, setCampaignStore] = useState<CampaignStore|null|undefined>();
    const appControlStore = useContext(AppControlStoreContext);
    const { pathname } = useLocation();

    useEffect(() => {
        setCampaignStore(undefined);

        const loadingPromises:[Promise<CampaignStore>,Promise<undefined>?] = [CampaignStore.fetchCampaign(match.params.campaignId, match.params.token)];

        if (appControlStore.loadingFirstPage) {
            loadingPromises.push(new Promise((resolve) => {setTimeout(() => {resolve()}, AppControlStore.LOGO_SPINNER_ANIMATION_LOADING_TIME)}) as Promise<undefined>);
        }

        Promise.all(loadingPromises).then((promisedData: (CampaignStore | undefined)[]) => {
            const campaignStore = (promisedData as [CampaignStore, undefined?])[0]
            setCampaignStore(campaignStore);
        }, () => {
            setCampaignStore(null);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.campaignId, match.params.token]);

    return (
        <ErrorBoundary>
            <Suspense fallback={<LoadingPage/>}>
                <Switch>
                    <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                    <Route
                        path={[`${match.path}/reporting/`,`${match.path}/`]}
                        exact={true}
                        render={({ match }) => <Reporting campaignStore={campaignStore} match={match}/>}
                    />
                    <Route component={PageNotFound}/>
                </Switch>
            </Suspense>
        </ErrorBoundary>
    );
};

export default CampaignPageRouter;