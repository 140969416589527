import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Bar } from 'react-chartjs-2';
import palette from 'google-palette';
import PulseReportStore from 'stores/pulseReportStore';
import ChartWrapper from 'components/chartWrapper/ChartWrapper';
import { formatLabelsWithNumberSeparatorWithPrefix } from 'utils/chartjs';
import { formatNumbersWithSeparator } from 'utils/numbers';

interface Props {
    pulseReportStore: PulseReportStore;
};

const RevenueByYearChart: React.FC<Props> = observer(({ pulseReportStore }: Props): JSX.Element => {
    const data = useMemo(() => {
        const color = (palette('mpn65', 65) || []).map((color) => `#${color}`).pop();
        let years = pulseReportStore.presentedYears;

        const data = {
            datasets: [{
                data: [] as Array<number>,
                backgroundColor: color,
                maxBarThickness: 125
            }],
            labels: years
        };

        years.forEach((year) => {
            const revenueByFormatByMonth = pulseReportStore.revenueByFormatByMonthByYear.get(year);
            let yearsRevenue: number = 0;

            if (revenueByFormatByMonth) {
                revenueByFormatByMonth.forEach((revenueByFormat) => {
                    yearsRevenue += Object.values(revenueByFormat).reduce((sum, revenue) => sum + revenue, 0);
                });
            }

            data.datasets[0].data.push(yearsRevenue);
        });

        return data;
    }, [
        pulseReportStore.presentedYears,
        pulseReportStore.revenueByFormatByMonthByYear
    ]);

    return (
        <ChartWrapper
            title="Spend By Year"
            componentClassName="revenue-by-year-chart-component"
        >
            { data === null ?
                <div>No Data Found</div>
            :
                <Bar
                    data={data}
                    options={{
                        legend: {
                            display: false
                        },
                        tooltips: {
                            position: 'nearest',
                            intersect: false,
                            mode: 'index',
                            callbacks: {
                                label: (tooltipItem, data) => {
                                    const formattedLabel = formatLabelsWithNumberSeparatorWithPrefix('$', tooltipItem, data, true);
                                    return formattedLabel;
                                }
                            }
                        },
                        responsive:true,
                        maintainAspectRatio: false,
                        scales: {
                            yAxes: [{
                                ticks: {
                                    callback: function(label) {
                                        return `$${formatNumbersWithSeparator(label)}`;
                                    },
                                    beginAtZero: true
                                },
                                gridLines: {
                                    drawBorder: false
                                },
                            }],
                        }
                    }}
                />
            }
        </ChartWrapper>
    );
});

export default RevenueByYearChart;