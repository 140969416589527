import { observable, computed, action, runInAction } from 'mobx';
import { AsyncParser } from 'json2csv';
import BaseStore from 'stores/abstractStores/baseStore';
import { clientStore, Props as ClientProps } from 'stores/clientStore';
import MarketSummaryStore, { Props as MarketSummaryProps, SegmentMeasurmentsProps, PropsActual, PropsInProgress } from 'stores/marketSummaryStore';
import { LineItemStore, LineItemDateAttributes, PlannedLineItemStore, ActualLineItemStore, UnitLineItemStore, PackageLineItemStore, PackageProps, UnitProps, PlannedUnitLineItemStore, ActualUnitLineItemStore, PlannedPackageLineItemStore, ActualPackageLineItemStore, LineItemType } from 'stores/lineItemStore';
import CampaignImageStore, { Props as CampaignImageProps } from './campaignImageStore';
import { fetchCampaign, fetchGeopathData, TargetSegmentIds, GeopathSegmentsData, Flight } from 'utils/adstrucApi';
import { colorToHex } from 'utils/colors';
import { getToday, formatDate } from 'utils/dates';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

export const ALL_MARKETS_STRING = 'All Markets';

type AlternateDateRangeTypes = 'actual'|'inProgress';
type DateRangeType = 'planned'|AlternateDateRangeTypes;
export type AlternateDateRangeNames = 'Actual'|'In Progress';

interface ExportDataGeopathMeasurements {
    frequency: number|null;
    impressions: number|null;
    netReach: number|null;
    reachPercentage: number|null;
    trp: number|null;
};

type ExportData = {
    week: number;
    startDate: Date;
    endDate: Date;
    base: ExportDataGeopathMeasurements;
    primary?: ExportDataGeopathMeasurements;
    secondary?: ExportDataGeopathMeasurements;
};

interface Props {
    id: string;
    token: string;
    name: string;
    units: Array<UnitProps>;
    packages: Array<PackageProps>;
    baseSegmentName: string;
    baseSegmentId: string;
    primarySegmentName?: string;
    primarySegmentId?: string;
    secondarySegmentName?: string;
    secondarySegmentId?: string;
    enableExport: boolean;
    client: ClientProps;
    markets: {
        [marketId: string]: string
    };
    campaignPhotos?: Array<string>;
    images?: Array<CampaignImageProps>;
};

class CampaignStore extends BaseStore {
    @observable
    public id: string;

    @observable
    public token: string;

    @observable
    public name: string;

    @observable
    public units: Array<UnitLineItemStore>;

    @observable
    public packages: Array<PackageLineItemStore>;

    @observable
    public campaignPhotos?: Array<string>;

    @observable
    public images: Array<CampaignImageStore>;

    @observable
    public baseSegmentName: string;

    @observable
    public baseSegmentId: string;

    @observable
    public primarySegmentName?: string;

    @observable
    public primarySegmentId?: string;

    @observable
    public secondarySegmentName?: string;

    @observable
    public secondarySegmentId?: string;

    @observable
    public enableExport: boolean;

    @observable
    public markets: {
        [marketId: string]: string
    };

    @observable
    public marketSummary?: MarketSummaryStore;

    @observable
    public selectedMarketId: string;

    @observable
    public selectedStartDate: Date|null;

    @observable
    public selectedEndDate: Date|null;

    @observable
    private geopathDataPendingCounter: number = 0;

    private static readonly CAMPAIGN_DATE_TIME_FORMAT = new Intl.DateTimeFormat('en', { year: '2-digit', month: '2-digit', day: '2-digit' });

    constructor({
        id,
        token,
        name,
        client,
        units,
        packages,
        baseSegmentName,
        baseSegmentId,
        primarySegmentName,
        primarySegmentId,
        secondarySegmentName,
        secondarySegmentId,
        enableExport,
        markets,
        campaignPhotos,
        images
    }: Props) {
        super();
        this.id = id;
        this.token = token;
        this.name = name;
        this.units = units.map((lineItemProps) => new UnitLineItemStore(lineItemProps));
        this.packages = packages.map((lineItemProps) => new PackageLineItemStore(lineItemProps));

        this.campaignPhotos = campaignPhotos;
        this.images = (images || []).map((image) => {
            return new CampaignImageStore(image);
        }).sort((i1, i2) => {
            return i2.position - i1.position;
        });
        this.baseSegmentName = baseSegmentName;
        this.baseSegmentId = baseSegmentId;
        this.primarySegmentName = primarySegmentName;
        this.primarySegmentId = primarySegmentId;
        this.secondarySegmentName = secondarySegmentName;
        this.secondarySegmentId = secondarySegmentId;
        this.enableExport = enableExport;
        this.markets = markets;

        this.selectedMarketId = ALL_MARKETS_STRING;
        this.selectedStartDate = this.minStartDate;
        this.selectedEndDate = this.maxEndDate;

        clientStore.loadClient(client);
        this.fetchGeopathData();
    };

    public static fetchCampaign(id: string, token: string): Promise<CampaignStore> {
        return fetchCampaign(id, token).then((data?) => {
            return runInAction('setFetchedCampaignData', () => {
                return new this({
                    id: id,
                    token: token,
                    name: data.settings.campaignName,
                    units: data.lineItems,
                    packages: data.packageLineItems,
                    markets: data.markets,
                    campaignPhotos: data.campaignPhotos,
                    images: data.images,
                    baseSegmentId: data.settings.baseSegmentId,
                    baseSegmentName: data.settings.baseSegmentName,
                    primarySegmentId: data.settings.primarySegmentId,
                    primarySegmentName: data.settings.primarySegmentName,
                    secondarySegmentId: data.settings.secondarySegmentId,
                    secondarySegmentName: data.settings.secondarySegmentName,
                    enableExport: data.settings.enableExport,
                    client: {
                        name: data.settings.clientName,
                        primaryColor: colorToHex(data.settings.primaryColor),
                        secondaryColor: colorToHex(data.settings.secondaryColor)
                    }
                });
            });
        });
    };

    public fetchGeopathDataForExport(): Promise<Array<ExportData>> {
        const dates: Array<{
            week: number,
            startDate: Date,
            endDate: Date
        }> = [];

        const today = getToday();

        this.getSegments().forEach((segment) => {
            if (this.minStartDate === null || this.maxEndDate === null) {
                return Promise.reject('Campaign Dates not Found');
            }

            let weekStartDate = this.minStartDate;
            if (weekStartDate.getDay() !== 1) {
                const mondayStartedWeekStartDate = new Date(weekStartDate);
                if (weekStartDate.getDay() === 0) {
                    mondayStartedWeekStartDate.setDate(weekStartDate.getDate() - 6);
                } else {
                    mondayStartedWeekStartDate.setDate(weekStartDate.getDate() - (weekStartDate.getDay() - 1))
                }
                weekStartDate = mondayStartedWeekStartDate;
            }
            let weekCounter = 1;
            while (weekStartDate <= this.maxEndDate) {
                let weekEndDate = new Date(weekStartDate);
                weekEndDate.setDate(weekStartDate.getDate() + 6);

                if (today < (this.maxEndDate < weekEndDate ? this.maxEndDate : weekEndDate)) {
                    break;
                }

                dates.push({
                    week: weekCounter,
                    startDate: weekStartDate,
                    endDate: weekEndDate
                });
                weekCounter++;
                weekStartDate = new Date(weekEndDate);
                weekStartDate.setDate(weekEndDate.getDate() + 1);
            }
        });

        const geopathRequests = dates.map<Promise<ExportData>>((date) => {
            const marketIds = Object.keys(this.markets);

            const targetSegmentIds: TargetSegmentIds = {
                base: this.baseSegmentId
            };

            if (this.primarySegmentId) {
                targetSegmentIds.primary = this.primarySegmentId;
            }

            if (this.secondarySegmentId) {
                targetSegmentIds.secondary = this.secondarySegmentId;
            }

            let flights: Array<Flight>|undefined;
            let customPlannedImps: number;
            switch (this.alternateDateRangeType) {
                case 'actual':
                    flights = this.getLineItemsInGivenDateRange('unit', 'actual', date.startDate, date.endDate, false).reduce((flights: Array<Flight>, unit: ActualUnitLineItemStore): Array<Flight> => {
                        const startDate = (date.startDate !== null && unit.actualStartDate! < date.startDate) ? date.startDate : unit.actualStartDate!;
                        const endDate = (date.endDate !== null && unit.actualEndDate! > date.endDate) ? date.endDate : unit.actualEndDate!;

                        if (startDate <= endDate && unit.geopathId) {
                            flights.push({
                                spotId: unit.geopathId,
                                startDate: startDate,
                                endDate: endDate
                            });
                        }

                        return flights;
                    }, [] as Array<Flight>);

                    customPlannedImps = this.getLineItemsInGivenDateRange('unit', 'actual', date.startDate, date.endDate, false).reduce((customPlannedImps: number, unit: ActualUnitLineItemStore) => {
                        const startDate = (date.startDate !== null && unit.actualStartDate! < date.startDate) ? date.startDate : unit.actualStartDate!;
                        const endDate = (date.endDate !== null && unit.actualEndDate! > date.endDate) ? date.endDate : unit.actualEndDate!;

                        const imp = this.getDailyNonGeopathImpression({
                            start: startDate,
                            end: endDate,
                            lineItem: unit
                        });

                        return customPlannedImps + imp;
                    }, 0);

                    break;
                case 'inProgress':
                    flights = this.filteredInProgressUnits.reduce((flights: Array<Flight>, unit: UnitLineItemStore): Array<Flight> => {
                        const startDate = (date.startDate !== null && unit.startDate < date.startDate) ? date.startDate : unit.startDate;
                        let endDate = (date.endDate !== null && unit.endDate > date.endDate) ? date.endDate : unit.endDate;
                        endDate = today < endDate ? today : endDate;

                        if (startDate <= endDate && unit.geopathId) {
                            flights.push({
                                spotId: unit.geopathId,
                                startDate: startDate,
                                endDate: endDate
                            });
                        }

                        return flights;
                    }, [] as Array<Flight>);

                    customPlannedImps = this.filteredInProgressLineItems.reduce((customPlannedImps: number, unit: LineItemStore) => {
                        let startDate = (date.startDate !== null && unit.startDate < date.startDate) ? date.startDate : unit.startDate;
                        let endDate = (date.endDate !== null && unit.endDate > date.endDate) ? date.endDate : unit.endDate;
                        endDate = today < endDate ? today : endDate;

                        const imp = this.getDailyNonGeopathImpression({
                            start: startDate,
                            end: endDate,
                            lineItem: unit
                        });

                        return customPlannedImps + imp;
                    }, 0);

                    break;
                default:
                    flights = [];
                    break;
            }

            return fetchGeopathData(this.id, this.token, marketIds, targetSegmentIds, flights).then((data?) => {
                const segmentMeasurmentsProps = (Object.keys(data.planned) as Array<keyof GeopathSegmentsData>).reduce((segmentMeasurmentsProps, segment) => {
                    const segmentData = data.planned[segment];

                    if (segmentData) {
                        segmentMeasurmentsProps = segmentMeasurmentsProps || ({} as Partial<SegmentMeasurmentsProps>);
                        segmentMeasurmentsProps[segment] = {
                            frequency: segmentData.frequencyAverage,
                            reachPercentage: segmentData.reachPercentage,
                            impressions: segmentData.targetImpressions,
                            trp: segmentData.trp
                        };
                    }

                    return segmentMeasurmentsProps;
                }, {} as Partial<SegmentMeasurmentsProps>) as SegmentMeasurmentsProps;

                const exportData: ExportData = {
                    week: date.week,
                    startDate: date.startDate,
                    endDate: date.endDate,
                    base: {
                        frequency: segmentMeasurmentsProps.base.frequency,
                        impressions: segmentMeasurmentsProps.base.impressions + customPlannedImps,
                        netReach: segmentMeasurmentsProps.base.impressions / segmentMeasurmentsProps.base.frequency,
                        reachPercentage: segmentMeasurmentsProps.base.reachPercentage,
                        trp: segmentMeasurmentsProps.base.trp
                    }
                };

                if (segmentMeasurmentsProps.primary) {
                    exportData.primary = {
                        frequency: segmentMeasurmentsProps.primary.frequency,
                        impressions: segmentMeasurmentsProps.primary.impressions,
                        netReach: segmentMeasurmentsProps.primary.impressions / segmentMeasurmentsProps.primary.frequency,
                        reachPercentage: segmentMeasurmentsProps.primary.reachPercentage,
                        trp: segmentMeasurmentsProps.primary.trp
                    }
                }

                if (segmentMeasurmentsProps.secondary) {
                    exportData.secondary = {
                        frequency: segmentMeasurmentsProps.secondary.frequency,
                        impressions: segmentMeasurmentsProps.secondary.impressions,
                        netReach: segmentMeasurmentsProps.secondary.impressions / segmentMeasurmentsProps.secondary.frequency,
                        reachPercentage: segmentMeasurmentsProps.secondary.reachPercentage,
                        trp: segmentMeasurmentsProps.secondary.trp
                    }
                }

                return exportData;
            });
        });

        return Promise.all(geopathRequests);
    };

    private fetchGeopathData() {
        this.geopathDataPendingCounter++;
        const frozenGeopathDataPendingCounter = this.geopathDataPendingCounter;

        const marketIds = this.selectedMarketId !== ALL_MARKETS_STRING ? [this.selectedMarketId] : Object.keys(this.markets);

        const targetSegmentIds: TargetSegmentIds = {
            base: this.baseSegmentId
        };

        if (this.primarySegmentId) {
            targetSegmentIds.primary = this.primarySegmentId;
        }

        if (this.secondarySegmentId) {
            targetSegmentIds.secondary = this.secondarySegmentId;
        }

        const flights = this.filteredPlannedUnits.reduce((flights: Array<Flight>, lineItem: PlannedUnitLineItemStore): Array<Flight> => {
            const startDate = (this.selectedStartDate !== null && lineItem.plannedStartDate < this.selectedStartDate) ? this.selectedStartDate : lineItem.plannedStartDate;
            const endDate = (this.selectedEndDate !== null && lineItem.plannedEndDate > this.selectedEndDate) ? this.selectedEndDate : lineItem.plannedEndDate;

            if (startDate <= endDate && lineItem.geopathId) {
                flights.push({
                    spotId: lineItem.geopathId,
                    startDate: startDate,
                    endDate: endDate
                });
            }

            return flights;
        }, [] as Array<Flight>);

        const customPlannedImps = this.filteredPlannedLineItems.reduce((customPlannedImps: number, lineItem: PlannedLineItemStore) => {
            const startDate = (this.selectedStartDate !== null && lineItem.plannedStartDate < this.selectedStartDate) ? this.selectedStartDate : lineItem.plannedStartDate;
            const endDate = (this.selectedEndDate !== null && lineItem.plannedEndDate > this.selectedEndDate) ? this.selectedEndDate : lineItem.plannedEndDate;

            const imp = this.getDailyNonGeopathImpression({
                start: startDate,
                end: endDate,
                lineItem: lineItem
            });

            return customPlannedImps + imp;
        }, 0);

        const alternateDateRangeType = this.alternateDateRangeType;
        let alternateDateRange: Array<Flight>|undefined;
        let alternateCustomBaseImps: number;

        switch(this.alternateDateRangeType) {
            case 'actual':
                alternateDateRange = this.filteredActualUnits.reduce((flights: Array<Flight>, lineItem: ActualUnitLineItemStore): Array<Flight> => {
                    const startDate = (this.selectedStartDate !== null && lineItem.actualStartDate! < this.selectedStartDate) ? this.selectedStartDate : lineItem.actualStartDate!;
                    const endDate = (this.selectedEndDate !== null && lineItem.actualEndDate! > this.selectedEndDate) ? this.selectedEndDate : lineItem.actualEndDate!;

                    if (startDate <= endDate && lineItem.geopathId) {
                        flights.push({
                            spotId: lineItem.geopathId,
                            startDate: startDate,
                            endDate: endDate
                        });
                    }

                    return flights;
                }, [] as Array<Flight>);

                alternateCustomBaseImps = this.filteredActualLineItems.reduce((alternateCustomBaseImps: number, lineItem: ActualLineItemStore) => {
                    const startDate = (this.selectedStartDate !== null && lineItem.actualStartDate! < this.selectedStartDate) ? this.selectedStartDate : lineItem.actualStartDate!;
                    const endDate = (this.selectedEndDate !== null && lineItem.actualEndDate! > this.selectedEndDate) ? this.selectedEndDate : lineItem.actualEndDate!;

                    const imp = this.getDailyNonGeopathImpression({
                        start: startDate,
                        end: endDate,
                        lineItem: lineItem
                    });

                    return alternateCustomBaseImps + imp;
                }, 0);

                break;
            case 'inProgress':
                const today = getToday();
                alternateDateRange = this.filteredInProgressUnits.reduce((flights: Array<Flight>, lineItem: UnitLineItemStore): Array<Flight> => {
                    const startDate = (this.selectedStartDate !== null && lineItem.startDate < this.selectedStartDate) ? this.selectedStartDate : lineItem.startDate;
                    let endDate = (this.selectedEndDate !== null && lineItem.endDate > this.selectedEndDate) ? this.selectedEndDate : lineItem.endDate;
                    endDate = today < endDate ? today : endDate;

                    if (startDate <= endDate && lineItem.geopathId) {
                        flights.push({
                            spotId: lineItem.geopathId,
                            startDate: startDate,
                            endDate: endDate
                        });
                    }

                    return flights;
                }, [] as Array<Flight>);

                alternateCustomBaseImps = this.filteredInProgressLineItems.reduce((alternateCustomBaseImps: number, lineItem: LineItemStore) => {
                    const startDate = (this.selectedStartDate !== null && lineItem.startDate < this.selectedStartDate) ? this.selectedStartDate : lineItem.startDate;
                    let endDate = (this.selectedEndDate !== null && lineItem.endDate > this.selectedEndDate) ? this.selectedEndDate : lineItem.endDate;
                    endDate = today < endDate ? today : endDate;

                    const imp = this.getDailyNonGeopathImpression({
                        start: startDate,
                        end: endDate,
                        lineItem: lineItem
                    });

                    return alternateCustomBaseImps + imp;
                }, 0);

                break;
        }

        this.marketSummary = undefined;

        return fetchGeopathData(this.id, this.token, marketIds, targetSegmentIds, flights, alternateDateRange).then((data?) => {
            if (this.geopathDataPendingCounter !== frozenGeopathDataPendingCounter) {
                return;
            }

            runInAction('setFetchedGeopathData', () => {
                this.geopathDataPendingCounter = 0;

                const marketSummaryProps = (Object.keys(data.planned) as Array<keyof GeopathSegmentsData>).reduce((props, segment) => {
                    const segmentData = data.planned[segment];

                    if (segmentData) {
                        const plannedProps = props.planned || ({} as Partial<SegmentMeasurmentsProps>);
                        plannedProps[segment] = {
                            frequency: segmentData.frequencyAverage,
                            reachPercentage: segmentData.reachPercentage,
                            impressions: segment === 'base' ? (segmentData.targetImpressions + customPlannedImps) : segmentData.targetImpressions,
                            trp: segmentData.trp
                        };

                        props.planned = plannedProps as SegmentMeasurmentsProps;

                        if (data.alternateDateRange && alternateDateRangeType) {
                            const alternateDateRangeSegmentData = data.alternateDateRange[segment];
                            if (alternateDateRangeSegmentData) {
                                const alternateDateRangeProps = (props as Partial<PropsActual & PropsInProgress>)[alternateDateRangeType] || ({} as Partial<SegmentMeasurmentsProps>);
                                alternateDateRangeProps[segment] = {
                                    frequency: alternateDateRangeSegmentData.frequencyAverage,
                                    reachPercentage: alternateDateRangeSegmentData.reachPercentage,
                                    impressions: segment === 'base' ? (alternateDateRangeSegmentData.targetImpressions + alternateCustomBaseImps) : alternateDateRangeSegmentData.targetImpressions,
                                    trp: alternateDateRangeSegmentData.trp
                                };

                                (props as Partial<PropsActual & PropsInProgress>)[alternateDateRangeType] = alternateDateRangeProps as SegmentMeasurmentsProps;
                            }
                        }
                    }

                    return props;
                }, {} as Partial<MarketSummaryProps>) as MarketSummaryProps;

                this.marketSummary = new MarketSummaryStore(marketSummaryProps);
            });
        }, (error) => {
            if (this.geopathDataPendingCounter !== frozenGeopathDataPendingCounter) {
                return;
            }

            this.geopathDataPendingCounter = 0;
            throw error;
        });
    };

    @computed
    public get geopathDataPending(): boolean {
        return !!this.geopathDataPendingCounter;
    };

    @computed
    public get lineItems(): Array<LineItemStore> {
        return [...this.units, ...this.packages];
    };

    @computed
    public get filteredPlannedUnits(): Array<PlannedUnitLineItemStore> {
        return this.getLineItemsInSelectedDateRange('unit', 'planned');
    };

    @computed
    public get filteredActualUnits(): Array<ActualUnitLineItemStore> {
        return this.getLineItemsInSelectedDateRange('unit', 'actual');
    };

    @computed
    public get filteredInProgressUnits(): Array<UnitLineItemStore> {
        return this.getLineItemsInSelectedDateRange('unit', 'inProgress');
    };

    @computed
    public get filteredPlannedPackages(): Array<PlannedPackageLineItemStore> {
        return this.getLineItemsInSelectedDateRange('package', 'planned');
    };

    @computed
    public get filteredActualPackages(): Array<ActualPackageLineItemStore> {
        return this.getLineItemsInSelectedDateRange('package', 'actual');
    };

    @computed
    public get filteredInProgressPackages(): Array<PackageLineItemStore> {
        return this.getLineItemsInSelectedDateRange('package', 'inProgress');
    };

    @computed
    public get filteredPlannedLineItems(): Array<PlannedLineItemStore> {
        return [
            ...this.filteredPlannedUnits,
            ...this.filteredPlannedPackages
        ];
    };

    @computed
    public get filteredActualLineItems(): Array<ActualLineItemStore> {
        return [
            ...this.filteredActualUnits,
            ...this.filteredActualPackages
        ];
    };

    @computed
    public get filteredInProgressLineItems(): Array<LineItemStore> {
        return [
            ...this.filteredInProgressUnits,
            ...this.filteredInProgressPackages
        ];
    };

    @computed
    public get filteredLineItems(): Array<LineItemStore> {
        let alternateLineItems: Array<LineItemStore>;
        switch(this.alternateDateRangeType) {
            case 'actual':
                alternateLineItems = this.filteredActualLineItems;
                break;
            case 'inProgress':
                alternateLineItems = this.filteredInProgressLineItems;
                break;
            default:
                return this.filteredPlannedLineItems;
        }

        return [...this.filteredPlannedLineItems, ...alternateLineItems.filter((lineItem: LineItemStore, i) => {
            return (this.filteredPlannedLineItems as Array<LineItemStore>).indexOf(lineItem) < 0;
        })];
    };

    @computed
    public get filteredUnauditedLineItems(): Array<LineItemStore> {
        return this.filteredLineItems.filter((lineItem) => {
            return (
                lineItem instanceof UnitLineItemStore &&
                !lineItem.geopathId &&
                !lineItem.baseWeeklyNonGeopathImpressions
            ) || (
                lineItem instanceof PackageLineItemStore &&
                !lineItem.baseWeeklyNonGeopathImpressions
            );
        });
    };

    @computed
    public get filteredNonGeopathAuditedLineItems(): Array<LineItemStore> {
        return this.filteredLineItems.filter((lineItem) => {
            return (lineItem instanceof UnitLineItemStore && !lineItem.geopathId) || lineItem instanceof PackageLineItemStore;
        });
    };

    @computed
    public get filteredUnauditedUnits(): Array<UnitLineItemStore> {
        return this.filteredUnauditedLineItems
            .filter(lineItem => lineItem instanceof UnitLineItemStore) as Array<UnitLineItemStore>;
    }

    @computed
    public get filteredUnauditedPackages(): Array<PackageLineItemStore> {
        return this.filteredUnauditedLineItems
            .filter(lineItem => lineItem instanceof PackageLineItemStore) as Array<PackageLineItemStore>;
    }

    @computed
    public get filteredNonGeopathAuditedUnits(): Array<UnitLineItemStore> {
        return this.filteredNonGeopathAuditedLineItems
            .filter(lineItem => lineItem instanceof UnitLineItemStore) as Array<UnitLineItemStore>;
    }

    @computed
    public get filteredNonGeopathAuditedPackages(): Array<PackageLineItemStore> {
        return this.filteredNonGeopathAuditedLineItems
            .filter(lineItem => lineItem instanceof PackageLineItemStore) as Array<PackageLineItemStore>;
    }

    @computed
    public get unauditedLineItemDisclaimer(): string {
        const unitCount = this.filteredNonGeopathAuditedUnits.length;
        const pkgCount = this.filteredNonGeopathAuditedPackages.length;

        return this.constructLineItemDisclaimerTxt(unitCount, pkgCount);
    };

    @computed
    public get unauditedLineItemDisclaimerImpressions(): string {
        const nonAuditedUnitCount = this.filteredUnauditedUnits.length;
        const nonAuditedPkgCount = this.filteredUnauditedPackages.length;

        return this.constructLineItemDisclaimerTxt(nonAuditedUnitCount, nonAuditedPkgCount);
    };

    @computed
    public get hasActualDates(): boolean {
        return LineItemStore.doAllLineItemsHaveActualDates(this.filteredPlannedLineItems);
    };

    @computed
    public get isTodayBeforeSelectedDates(): boolean {
        if (!this.selectedEndDate) {
            return false;
        }
        const today = getToday();
        return today < this.selectedEndDate;
    };

    private constructLineItemDisclaimerTxt(unitCount: number, pkgCount: number): string {
        if (pkgCount <= 0 && unitCount <= 0) {
            return '';
        }

        const unitTxt = `${unitCount} unit${unitCount > 1 ? 's' : ''}`;
        const pkgTxt = `${pkgCount} package${pkgCount > 1 ? 's' : ''}`;

        return `Excluding ${unitCount ? unitTxt : ''} ${unitCount && pkgCount ? 'and ' : ''}${pkgCount ? pkgTxt : ''}`;
    };

    private getDailyNonGeopathImpression({ start, end, lineItem }: { start: Date, end: Date, lineItem: LineItemStore }): number {
        let val = 0;
        if (start <= end && !lineItem.isGeopathAudited && lineItem.baseWeeklyNonGeopathImpressions) {
            const dailyImps = lineItem.baseWeeklyNonGeopathImpressions / 7;
            const numOfDays = differenceInCalendarDays(end, start) + 1;

            val = dailyImps * numOfDays;
        }

        return val;
    }

    private getLineItemsInSelectedDateRange<T extends DateRangeType, L extends LineItemType>(lineItemType: L, dateRangeType: T):
        T extends 'planned' ? (L extends 'unit' ? Array<PlannedUnitLineItemStore> : (L extends 'package' ? Array<PlannedPackageLineItemStore> : Array<PlannedLineItemStore>)) :
        T extends 'actual' ? (L extends 'unit' ? Array<ActualUnitLineItemStore> : (L extends 'package' ? Array<ActualPackageLineItemStore> : Array<ActualLineItemStore>)) :
        (L extends 'unit' ? Array<UnitLineItemStore> : (L extends 'package' ? Array<PackageLineItemStore> : Array<LineItemStore>));
    private getLineItemsInSelectedDateRange(lineItemType: LineItemType, dateRangeType: DateRangeType): Array<PlannedLineItemStore> | Array<ActualLineItemStore> | Array<LineItemStore> {
        return this.getLineItemsInGivenDateRange(lineItemType, dateRangeType, this.selectedEndDate, this.selectedEndDate);
    };


    private getLineItemsInGivenDateRange<T extends DateRangeType, L extends LineItemType>(lineItemType: L, dateRangeType: T, dateRangeStart: Date|null, dateRangeEnd: Date|null, selectedMarketsOnly?: boolean):
        T extends 'planned' ? (L extends 'unit' ? Array<PlannedUnitLineItemStore> : (L extends 'package' ? Array<PlannedPackageLineItemStore> : Array<PlannedLineItemStore>)) :
        T extends 'actual' ? (L extends 'unit' ? Array<ActualUnitLineItemStore> : (L extends 'package' ? Array<ActualPackageLineItemStore> : Array<ActualLineItemStore>)) :
        (L extends 'unit' ? Array<UnitLineItemStore> : (L extends 'package' ? Array<PackageLineItemStore> : Array<LineItemStore>));
    private getLineItemsInGivenDateRange(lineItemType: LineItemType, dateRangeType: DateRangeType, dateRangeStart: Date|null, dateRangeEnd: Date|null, selectedMarketsOnly: boolean = true): Array<PlannedLineItemStore> | Array<ActualLineItemStore> | Array<LineItemStore> {
        let lineItems: Array<LineItemStore>|Array<UnitLineItemStore>|Array<PackageLineItemStore>;
        if (selectedMarketsOnly) {
            switch (lineItemType) {
                case 'unit':
                    lineItems = this.inMarketUnits;
                    break;
                case 'package':
                    lineItems = this.inMarketPackages;
                    break;
                default:
                    lineItems = this.inMarketLineItems;
            }
        } else {
            switch (lineItemType) {
                case 'unit':
                    lineItems = this.units;
                    break;
                case 'package':
                    lineItems = this.packages;
                    break;
                default:
                    lineItems = this.lineItems;
            }
        }
        return (lineItems).filter((lineItem: LineItemStore): boolean => {
            let startDate;
            let endDate;
            switch(dateRangeType) {
                case 'planned':
                    startDate = lineItem.plannedStartDate;
                    endDate = lineItem.plannedEndDate;
                    break;
                case 'actual':
                    startDate = lineItem.actualStartDate;
                    endDate = lineItem.actualEndDate;
                    break;
                case 'inProgress':
                    startDate = lineItem.startDate;
                    endDate = lineItem.endDate;
                    let today = getToday();
                    if (today < startDate) {
                        return false;
                    } else if (today < endDate) {
                        endDate = today;
                    }
                    break;
                default:
                    throw new Error('Invalid Date Range Type');
            }

            if (!startDate || !endDate) {
                return false;
            }

            return (
                (this.selectedEndDate === null || startDate <= this.selectedEndDate) &&
                (this.selectedStartDate === null || endDate >= this.selectedStartDate)
            );
        }) as Array<PlannedLineItemStore>|Array<ActualLineItemStore>;
    };

    @computed
    public get selectedMarketName(): string {
        if (Object.keys(this.markets).length === 1) {
            return Object.values(this.markets)[0];
        }
        return this.markets[this.selectedMarketId] ? this.markets[this.selectedMarketId] : ALL_MARKETS_STRING;
    };

    @action
    public changeSelectedMarket(marketId: string): void {
        this.selectedMarketId = marketId;
        this.selectedStartDate = this.minStartDate;
        this.selectedEndDate = this.maxEndDate;
        this.fetchGeopathData();
    };

    @computed
    public get marketSelectorOptions(): Array<{
        value: string,
        text: string
    }> {
        const marketsObj = this.lineItems.reduce((markets: {[key: string]: number}, lineItem) => {
            const marketId = lineItem.marketId;
            if (!this.markets[marketId]) {
                return markets;
            }

            if (typeof markets[marketId] === 'undefined') {
                markets[marketId] = 0;
            }

            markets[marketId]++;
            return markets;
        }, {});

        const marketIds = Object.keys(marketsObj).sort((a, b) => {
            return marketsObj[b] - marketsObj[a];
        });

        const marketOptions = marketIds.map((marketId) => {
            return {
                value: marketId,
                text: this.markets[marketId]
            };
        });

        if (marketOptions.length > 1) {
            marketOptions.unshift({
                value: ALL_MARKETS_STRING,
                text: ALL_MARKETS_STRING
            });
        }

        return marketOptions;
    };

    @computed
    public get actualStartDate(): Date|null {
        return this.getMinMaxDate('actualStartDate', true);
    };

    @computed
    public get actualEndDate(): Date|null {
        return this.getMinMaxDate('actualEndDate', false);
    };

    @computed
    public get plannedStartDate(): Date|null {
        return this.getMinMaxDate('plannedStartDate', true);
    };

    @computed
    public get plannedEndDate(): Date|null {
        return this.getMinMaxDate('plannedEndDate', false);
    };

    @computed
    public get minStartDate(): Date|null {
        const today = getToday();
        if (this.plannedStartDate && today < this.plannedStartDate && (!this.actualStartDate || today < this.actualStartDate)) {
            return this.plannedStartDate
        }

        if (this.actualStartDate && this.plannedStartDate) {
            return this.actualStartDate < this.plannedStartDate ? this.actualStartDate : this.plannedStartDate;
        }

        return this.plannedStartDate || this.actualStartDate;
    };

    @computed
    public get maxEndDate(): Date|null {
        const today = getToday();
        if (this.plannedStartDate && today < this.plannedStartDate && (!this.actualStartDate || today < this.actualStartDate)) {
            return this.plannedEndDate;
        }

        if (this.actualEndDate && this.plannedEndDate) {
            return this.actualEndDate > this.plannedEndDate ? this.actualEndDate : this.plannedEndDate;
        }

        return this.plannedEndDate || this.actualEndDate;
    };

    @action
    public changeSelectedStartDate(date: Date): void {
        this.selectedStartDate = date;
        if (this.selectedEndDate === null || date > this.selectedEndDate) {
            this.selectedEndDate = date;
        }
        this.fetchGeopathData();
    };

    @action
    public changeSelectedEndDate(date: Date): void {
        this.selectedEndDate = date;
        if (this.selectedStartDate === null || date < this.selectedStartDate) {
            this.selectedStartDate = date;
        }
        this.fetchGeopathData();
    };

    @computed
    private get inMarketLineItems(): Array<LineItemStore> {
        return [...this.inMarketUnits, ...this.inMarketPackages];
    };

    @computed
    private get inMarketUnits(): Array<LineItemStore> {
        return this.units.filter((units) => {
            return (this.selectedMarketId === ALL_MARKETS_STRING || this.selectedMarketId === units.marketId);
        });
    };

    @computed
    private get inMarketPackages(): Array<LineItemStore> {
        return this.packages.filter((packages) => {
            return (this.selectedMarketId === ALL_MARKETS_STRING || this.selectedMarketId === packages.marketId);
        });
    };

    @computed
    public get fullCampaignSelected(): boolean {
        return (
            this.selectedMarketId === ALL_MARKETS_STRING &&
            (this.selectedStartDate === null || (this.minStartDate !== null && this.selectedStartDate.getTime() === this.minStartDate.getTime())) &&
            (this.selectedEndDate === null || (this.maxEndDate !== null && this.selectedEndDate.getTime() === this.maxEndDate.getTime()))
        );
    };

    private getMinMaxDate(dateName: LineItemDateAttributes, useEarlierDate: boolean): Date|null {
        if (!this.inMarketLineItems.length) {
            return null;
        }

        return this.inMarketLineItems.reduce((date: Date|null, lineItem): Date|null => {
            const lineItemDate = lineItem[dateName];

            if (lineItemDate === undefined) {
                return date;
            }

            if (date === null) {
                return lineItemDate
            }

            if (useEarlierDate) {
                if (lineItemDate < date) {
                    date = lineItemDate;
                }
            } else {
                if (lineItemDate > date) {
                    date = lineItemDate;
                }
            }

            return date;
        }, null);
    };

    @computed
    public get plannedFilteredMediaValue(): number {
        return this.getFilteredMediaValue('planned', false)
    };

    @computed
    public get plannedFilteredNegotiatedMediaValue(): number {
        return this.getFilteredMediaValue('planned', true);
    };

    @computed
    public get alternateDatesFilteredMediaValue(): number {
        return this.getAlternateDatessFilteredMediaValue(false)
    };

    @computed
    public get alternateDatesFilteredNegotiatedMediaValue(): number {
        return this.getAlternateDatessFilteredMediaValue(true);
    };

    @computed
    public get alternateDateRangeType(): AlternateDateRangeTypes|null {
        if (this.isTodayBeforeSelectedDates) {
            return this.filteredInProgressLineItems.length ? 'inProgress' : null;
        } else {
            return this.hasActualDates ? 'actual' : 'inProgress';
        }
    };

    private getAlternateDatessFilteredMediaValue(negotiated: boolean): number {
        if (!this.alternateDateRangeType) {
            return 0;
        }

        return this.getFilteredMediaValue(this.alternateDateRangeType, negotiated);
    };

    private getFilteredMediaValue(dateRange: DateRangeType, negotiated: boolean):number {
        if (dateRange === 'actual' && !this.hasActualDates) {
            return 0;
        }
        const today = dateRange === 'inProgress' ? getToday() : null;
        let lineItems: Array<LineItemStore>;
        switch(dateRange) {
            case 'planned':
                lineItems = this.filteredPlannedLineItems;
                break;
            case 'actual':
                lineItems = this.filteredActualLineItems;
                break;
            case 'inProgress':
                lineItems = this.filteredInProgressLineItems;
                break;
            default:
                throw new Error('Invalid Date Range Type')
        }
        return lineItems.reduce((value: number, lineItem: LineItemStore): number => {
            let startDate;
            let endDate;
            switch(dateRange) {
                case 'actual':
                    startDate = (lineItem as ActualLineItemStore).actualStartDate;
                    endDate = (lineItem as ActualLineItemStore).actualEndDate;
                    break;
                case 'inProgress':
                    startDate = lineItem.startDate;
                    endDate = today! < lineItem.endDate ? today! : lineItem.endDate;
                    break;
                default:
                    startDate = (lineItem as PlannedLineItemStore).plannedStartDate;
                    endDate = (lineItem as PlannedLineItemStore).plannedEndDate;
            }
            if (this.selectedStartDate && startDate < this.selectedStartDate) {
                startDate = this.selectedStartDate;
            };
            if (this.selectedEndDate && endDate > this.selectedEndDate) {
                endDate = this.selectedEndDate;
            };
            if (endDate < startDate) {
                return value;
            }

            return value + lineItem.getMediaValue(negotiated, startDate, endDate);
        }, 0);
    };

    public async generateCsv(exportData: Array<ExportData>): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const fields = [
                'Week',
                'Start Date',
                'End Date',
                'Base Frequency',
                'Base Impressions',
                'Base Net Reach',
                'Base Market Reach %',
                'Base TRP',
                'Primary Frequency',
                'Primary Impressions',
                'Primary Net Reach',
                'Primary Market Reach %',
                'Primary TRP',
                'Secondary Frequency',
                'Secondary Impressions',
                'Secondary Net Reach',
                'Secondary Market Reach %',
                'Secondary TRP'
            ] as const;

            const formatedData = exportData.map((exportLine): {
                [k in typeof fields[number]]: string|number|null
            } => {
                return {
                    'Week': exportLine.week,
                    'Start Date': formatDate(exportLine.startDate, 'MM/DD/YYYY'),
                    'End Date': formatDate(exportLine.endDate, 'MM/DD/YYYY'),
                    'Base Frequency': exportLine.base.frequency,
                    'Base Impressions': exportLine.base.impressions,
                    'Base Net Reach': exportLine.base.netReach,
                    'Base Market Reach %': exportLine.base.reachPercentage,
                    'Base TRP': exportLine.base.trp,
                    'Primary Frequency': exportLine.primary ? exportLine.primary.frequency : null,
                    'Primary Impressions': exportLine.primary ? exportLine.primary.impressions : null,
                    'Primary Net Reach': exportLine.primary ? exportLine.primary.netReach : null,
                    'Primary Market Reach %': exportLine.primary ? exportLine.primary.reachPercentage : null,
                    'Primary TRP': exportLine.primary ? exportLine.primary.trp : null,
                    'Secondary Frequency': exportLine.secondary ? exportLine.secondary.frequency : null,
                    'Secondary Impressions': exportLine.secondary ? exportLine.secondary.impressions : null,
                    'Secondary Net Reach': exportLine.secondary ? exportLine.secondary.netReach : null,
                    'Secondary Market Reach %': exportLine.secondary ? exportLine.secondary.reachPercentage : null,
                    'Secondary TRP': exportLine.secondary ? exportLine.secondary.trp : null
                };
            });
            const opts = { fields: fields.slice() };
            const transformOpts = { highWaterMark: 8192 };

            const asyncParser = new AsyncParser(opts, transformOpts);

            let csv = '';
            asyncParser.processor.on('data', (chunk) => {
                (csv += chunk.toString())
            }).on('end', () => {
                resolve(csv);
            }).on('error', (err) => {
                reject(err);
            });

            asyncParser.input.push(JSON.stringify(formatedData));
            asyncParser.input.push(null);
        });
    }

    private getSegments(): Array<{id: string, name: string}> {
        const segments = [{
            id: this.baseSegmentId,
            name: this.baseSegmentName
        }];

        if (this.primarySegmentId && this.primarySegmentName) {
            segments.push({
                id: this.primarySegmentId,
                name: this.primarySegmentName
            })
        }

        if (this.secondarySegmentId && this.secondarySegmentName) {
            segments.push({
                id: this.secondarySegmentId,
                name: this.secondarySegmentName
            })
        }

        return segments;
    }
};

export default CampaignStore;