import React from 'react';
import { observer } from 'mobx-react-lite';
import PulseReportStore from 'stores/pulseReportStore';
import RevenueStackedBarChart from 'components/pulseReportCharts/revenueStackedBarChart/RevenueStackedBarChart';

interface Props {
    pulseReportStore: PulseReportStore;
};

const MarketRevenueStackedBarChart: React.FC<Props> = observer(({pulseReportStore}: Props): JSX.Element => {
    return <RevenueStackedBarChart
        revenueByCategoryByMonthByYear={pulseReportStore.revenueByMarketByMonthByYear}
        selectedCategories={pulseReportStore.presentedMarkets}
        colorByCategory={pulseReportStore.colorByMarket}
        years={pulseReportStore.years}
        title="Spend By Market"
    />
});

export default MarketRevenueStackedBarChart;