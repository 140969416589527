import { observable, computed } from 'mobx';
import BaseStore from 'stores/abstractStores/baseStore';
import GeopathMeasurementsStore, { Props as GeopathMeasurementsStoreProps } from 'stores/geopathMeasurementsStore';
import { AlternateDateRangeNames } from 'stores/campaignStore';

interface PropsPlanned {
    planned: SegmentMeasurmentsProps;
};

export interface PropsActual extends PropsPlanned {
    actual: SegmentMeasurmentsProps;
};

export interface PropsInProgress extends PropsPlanned {
    inProgress: SegmentMeasurmentsProps;
};

export type Props = PropsPlanned|PropsActual|PropsInProgress;

export interface SegmentMeasurmentsProps {
    base: GeopathMeasurementsStoreProps;
    primary?: GeopathMeasurementsStoreProps;
    secondary?: GeopathMeasurementsStoreProps;
};

interface SegmentMeasurments {
    base: GeopathMeasurementsStore;
    primary?: GeopathMeasurementsStore;
    secondary?: GeopathMeasurementsStore;
};

class MarketSummaryStore extends BaseStore {
    @observable
    public planned: SegmentMeasurments;

    @observable
    public actual?: SegmentMeasurments;

    @observable
    public inProgress?: SegmentMeasurments;

    constructor(props: PropsPlanned);
    constructor(props: PropsActual);
    constructor(props: PropsInProgress);
    constructor(props: PropsPlanned|PropsActual|PropsInProgress) {
        super();
        const planned = props.planned;
        const actual = (props as PropsActual).actual;
        const inProgress = (props as PropsInProgress).inProgress;

        this.planned = {
            base: new GeopathMeasurementsStore(planned.base)
        };

        if (planned.primary) {
            this.planned.primary = new GeopathMeasurementsStore(planned.primary);
        };

        if (planned.secondary) {
            this.planned.secondary = new GeopathMeasurementsStore(planned.secondary);
        };

        if (actual) {
            this.actual = {
                base: new GeopathMeasurementsStore(actual.base)
            };

            if (actual.primary) {
                this.actual.primary = new GeopathMeasurementsStore(actual.primary);
            };

            if (actual.secondary) {
                this.actual.secondary = new GeopathMeasurementsStore(actual.secondary);
            };
        };

        if (inProgress) {
            this.inProgress = {
                base: new GeopathMeasurementsStore(inProgress.base)
            };

            if (inProgress.primary) {
                this.inProgress.primary = new GeopathMeasurementsStore(inProgress.primary);
            };

            if (inProgress.secondary) {
                this.inProgress.secondary = new GeopathMeasurementsStore(inProgress.secondary);
            };
        };
    };

    @computed
    public get alternateDateRangeMeasurments(): SegmentMeasurments|undefined {
        return this.actual || this.inProgress;
    };

    @computed
    public get alternateDateRangeName(): AlternateDateRangeNames|undefined {
        if (this.actual) {
            return 'Actual';
        } else if (this.inProgress) {
            return 'In Progress';
        }
        return undefined;
    };
};

export default MarketSummaryStore;