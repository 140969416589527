import React, { useMemo, CSSProperties } from 'react';
import { Select, MenuItem } from '@mui/material';
import { isTabletOrMobileDeviceMediaQuery } from 'utils/reactMediaQueries';
import './Selector.scss';

interface OptionObject<T extends string|number> {
    value: T;
    text: string|number;
};

interface Props<T extends string|number> {
    value: T;
    onChange: (value: T) => unknown;
    className?: string;
    options: Array<T>|Array<OptionObject<T>>;
    style?: CSSProperties;
};

const Selector = <T extends string|number>({ value, options, onChange, className = '', style }: Props<T>): JSX.Element => {
    const isTabletOrMobileDevice = isTabletOrMobileDeviceMediaQuery();
    const SelectOptionTag = isTabletOrMobileDevice ? 'option' : MenuItem;

    const optionsArr = useMemo((): Array<OptionObject<T>> => {
        return (options as Array<T|OptionObject<T>>).map((option) => {
            if (typeof option === 'object') {
                return option;
            }
            return {
                value: option,
                text: option
            };
        });
    }, [options]);

    return (
        <Select
            className={`selector-component ${className}`}
            native={isTabletOrMobileDevice}
            value={value}
            style={style}
            onChange={(e) => onChange(e.target.value as T)}
            variant="standard"
        >
            { optionsArr.map((option: OptionObject<T>):JSX.Element => {
                return (
                    <SelectOptionTag
                        key={option.text}
                        value={option.value}
                    >
                        { option.text }
                    </SelectOptionTag>
                );
            }) }
        </Select>
    );
};

export default Selector;