import React from 'react';
import MessagePage from 'components/messagePage/MessagePage';

interface Props {
    children: JSX.Element;
};

class ErrorBoundary extends React.Component {
    public state: { hasError: boolean };

    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    };

    static getDerivedStateFromError() {
        return { hasError: true };
    };

    render() {
        if (this.state.hasError) {
            return <MessagePage text="Something went wrong"/>;
        }

        return this.props.children;
    };
};

export default ErrorBoundary;