import React, { Fragment, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Portal, ListItem, ListItemText, ListItemIcon, Button } from '@mui/material';
import { RiFolderAddLine } from 'react-icons/ri';
import S3CreateFolderPrompt from '../../s3Prompt/s3CreateFolderPrompt/S3CreateFolderPrompt';
import S3FileBrowserStore from 'stores/s3FileBrowserStore';
import { useIsComponentMounted } from 'utils/customHooks';
import './S3CreateFolder.scss';
interface Props {
    s3FileBrowserStore: S3FileBrowserStore;
    folderPath: string;
    buttonStyle: 'button'|'menuItem';
    folderAlias?: string;
    onSuccess?: () => void;
};

const S3CreateFolder: React.FC<Props> = observer(({s3FileBrowserStore, folderPath, onSuccess, buttonStyle, folderAlias}: Props): JSX.Element => {
    const [showingPrompt, setShowingPrompt] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const isComponentMounted = useIsComponentMounted();

    folderAlias = folderAlias || 'Folder';

    const createFolder = (newFolderName: string): void => {
        if (!newFolderName) {
            return;
        }

        setWaiting(true);

        s3FileBrowserStore.createFolder(`${folderPath}${newFolderName}/`).finally(() => {
            s3FileBrowserStore.fetchTree(true);
            if (!isComponentMounted.current) {
                return;
            }
            setWaiting(false);
        }).then(() => {
            s3FileBrowserStore.snackbarMessage = `${folderAlias} Created`;
            if (isComponentMounted.current) {
                setShowingPrompt(false);
            }
            onSuccess && onSuccess();
        }, () => {
            s3FileBrowserStore.snackbarMessage = `Error Creating ${folderAlias}`;
        });
    };

    return <Fragment>
        { buttonStyle === 'button' ?
            <Button
                color="primary"
                onClick={() => setShowingPrompt(true)}
            >
                <u>Add {folderAlias}</u>
            </Button>
        :
            <ListItem
                button
                onClick={() => setShowingPrompt(true)}
                className="s3-create-folder"
            >
                <ListItemIcon className="s3-create-folder-icon">
                    <RiFolderAddLine/>
                </ListItemIcon>
                <ListItemText>
                    Create {folderAlias}
                </ListItemText>
            </ListItem>
        }
        <Portal>
            <S3CreateFolderPrompt
                open={showingPrompt}
                onClose={() => setShowingPrompt(false)}
                onConfirm={createFolder}
                waitingForResponse={waiting}
                folderAlias={folderAlias}
            />
        </Portal>
    </Fragment>
});

export default S3CreateFolder;