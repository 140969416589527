import React, { useRef, useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { CircularProgress, Tooltip } from '@mui/material';
import useResizeObserver from 'use-resize-observer/polyfilled';
//@ts-ignore
export { default as ChartWrapperStyles} from './ChartWrapper.scss';

interface Props {
    title?: string;
    subTitle?: string;
    componentClassName: string;
    children: JSX.Element;
    onWidthChange?: (width: number|undefined) => void;
    onHeightChange?: (height: number|undefined) => void;
    loaded?: boolean;
}

const CONTENT_ABSOLUTE_MARGIN = 15;

const TitleContainer = ({ title, subTitle }: { title: string, subTitle?: string }) => {
    const [isOverflowed, setIsOverflow] = useState(false);
    const { ref: textElementRef, width: textElementWidth } = useResizeObserver<HTMLDivElement>();

    useLayoutEffect(() => {
        if (textElementRef && textElementRef.current && textElementWidth) {
            setIsOverflow(textElementRef.current.scrollWidth > textElementWidth);
        }
    }, [title, subTitle, textElementWidth, textElementRef]);

    return (
        <Tooltip title={(subTitle || title)} disableHoverListener={!isOverflowed} placement="top" enterTouchDelay={0}>
            <div 
                className="chart-title" 
                ref={textElementRef}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {title}
                {
                    subTitle &&
                    <span className="chart-sub-title">{subTitle}</span>
                }
            </div>
        </Tooltip>
    );
};

export const ChartWrapper: React.FC<Props> = observer(({ title, subTitle, componentClassName, onWidthChange, onHeightChange, children, loaded = true}: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const { width, height } = useResizeObserver({
        ref: ref
    });

    useLayoutEffect(() => {
        if (onWidthChange) {
            let newWidth = width;
            if (typeof newWidth === 'number') {
                newWidth = newWidth - (CONTENT_ABSOLUTE_MARGIN * 2);
                if (newWidth < 0) {
                    newWidth = 0;
                }
            }
            onWidthChange(newWidth);
        }
    }, [width, onWidthChange]);

    useLayoutEffect(() => {
        if (onHeightChange) {
            let newHeight = height;
            if (typeof newHeight === 'number') {
                newHeight = newHeight - (CONTENT_ABSOLUTE_MARGIN * 2);
                if (newHeight < 0) {
                    newHeight = 0;
                }
            }
            onHeightChange(newHeight);
        }
    }, [height, onHeightChange]);

    return (
        <div className={`chart-wrapper-component ${componentClassName}`} ref={ref}>
            { !loaded ?
                <CircularProgress className="loader" color="secondary"/>
                :
                <div
                    className="loaded-content"
                    style={{
                        position: 'absolute',
                        top: CONTENT_ABSOLUTE_MARGIN,
                        bottom: CONTENT_ABSOLUTE_MARGIN,
                        left: CONTENT_ABSOLUTE_MARGIN,
                        right: CONTENT_ABSOLUTE_MARGIN
                    }}
                >
                    {
                        title && 
                        <TitleContainer 
                            title={title} 
                            subTitle={subTitle} />
                    }
                    <div className="chart-container">
                        { children }
                    </div>
                </div>
            }
        </div>
    );
});

export default ChartWrapper;