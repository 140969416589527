import React, { useEffect, useState, Fragment } from 'react';
import { TextField } from '@mui/material';
import S3Prompt from '../S3Prompt';
import { isValidFileOrFolderName } from 'utils/strings';

interface Props {
    open: boolean;
    waitingForResponse: boolean;
    currentS3Key: string;
    onClose: () => unknown;
    onConfirm: (newS3Key: string) => unknown;
};

const S3MovePrompt: React.FC<Props> = ({open, waitingForResponse, onClose, onConfirm, currentS3Key}: Props): JSX.Element => {
    const [s3Key, setS3Key] = useState('');
    const [location, setLocation] = useState('');
    const [name, setName] = useState('');
    const [locationError, setLocationError] = useState(false);
    const [nameError, setNameError] = useState(false);

    const handleConfirm = () => {
        const hasNameError = !isValidFileOrFolderName(name);
        const hasLocationError = location.indexOf('//') !== -1;
        if (hasNameError || hasLocationError) {
            if (hasNameError) {
                setNameError(true);
            }
            if (hasLocationError) {
                setLocationError(true);
            }

            return;
        }
        let key = location;

        if (!key.endsWith('/')) {
            key += '/';
        }

        if (key.startsWith('/')) {
            key = key.slice(1);
        }

        key += name;

        onConfirm(key);
    };

    useEffect(() => {
        setS3Key(currentS3Key);
        const keyPartsMatch = s3Key.match(/^(.*)\/([^/]*)\/?$/);
        if (!keyPartsMatch) {
            setLocation('');
            setName(s3Key);
        } else {
            setLocation(keyPartsMatch[1]);
            setName(keyPartsMatch[2]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        open
    ]);

    return (
        <S3Prompt
            open={open}
            onClose={() => {
                setS3Key('');
                onClose();
            }}
            onConfirm={() => handleConfirm()}
            waitingForResponse={waitingForResponse}
            maxWidth="sm"
            title="Location & Name"
            confirmButtonText="Change"
        >
            <Fragment>
                <div>
                    <TextField
                        autoFocus
                        margin="dense"
                        label={`Location${' (ex: Media/For Review)'}`}
                        variant="standard"
                        fullWidth
                        value={location}
                        error={locationError}
                        onKeyPress={(e) => e.key === 'Enter' && handleConfirm()}
                        onChange={(e) => { setLocationError(false); setLocation(e.target.value) }}
                    />
                </div>
                <div>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        variant="standard"
                        fullWidth
                        value={name}
                        error={nameError}
                        onKeyPress={(e) => e.key === 'Enter' && handleConfirm()}
                        onChange={(e) => { setNameError(false); setName(e.target.value)} }
                    />
                </div>
            </Fragment>
        </S3Prompt>
    );
};

export default S3MovePrompt;