import TagManager from 'react-gtm-module';
import { getEnv } from 'utils/env';

if (getEnv() === 'prod') {
    const tagManagerArgs = {
        gtmId: 'GTM-NH9RPCR'
    };
    
    TagManager.initialize(tagManagerArgs);
}
