import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import CampaignPageRouter from 'components/campaignPageRouter/CampaignPageRouter';
import PageNotFound from 'components/pageNotFound/PageNotFound';
import Header from 'components/header/Header';
import { RoutesConfig } from 'App';

export const ROUTES_CONFIG: RoutesConfig = [
    {
        path: '/campaign/:campaignId/:token',
        component: CampaignPageRouter,
        exact: true
    }
];

const UnauthedApp: React.FC = (): JSX.Element => {
    return (
        <Fragment>
            <div className="App-header">
                <Header authed={false}/>
            </div>
            <div className="App-content">
                <Switch>
                    { ROUTES_CONFIG.map(({path, component, exact}, i) => {
                        return <Route
                        key={i}
                        path={path}
                        component={component}
                        exact={!!exact}
                        />
                    })}
                    <Route path={undefined} component={PageNotFound}/>
                </Switch>
            </div>
        </Fragment>
    );
};

export default UnauthedApp;