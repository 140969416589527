import React, { Fragment, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from "react-router-dom";
import { Portal, Fab, Tooltip, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import S3MovePrompt from '../../s3Prompt/s3MovePrompt/S3MovePrompt';
import S3FileBrowserStore from 'stores/s3FileBrowserStore';
import { useIsComponentMounted } from 'utils/customHooks';
import './S3Move.scss';

interface Props {
    s3Key: string;
    isFile: boolean;
    s3FileBrowserStore: S3FileBrowserStore;
    buttonStyle: 'fab'|'menuItem';
    navigateOnMove?: boolean;
};

const S3Move: React.FC<Props> = observer(({s3Key, s3FileBrowserStore, isFile, buttonStyle, navigateOnMove}: Props): JSX.Element => {
    const [showingPrompt, setShowingPrompt] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const isComponentMounted = useIsComponentMounted();
    const history = useHistory();

    const moveItem = (formattedNewS3Key: string): void => {
        let movePromise: Promise<void>;
        let newS3Key = s3FileBrowserStore.getS3KeyFromRelativePath(formattedNewS3Key);

        if (isFile) {
            const linkRegex = /^.*(\.[^/.]*)$/i;
            const s3KeyMatch = s3Key.match(linkRegex);
            const newS3KeyMatch = newS3Key.match(linkRegex);

            if ( s3KeyMatch &&
                ((
                    newS3KeyMatch && (
                    !s3KeyMatch ||
                    (s3KeyMatch && s3KeyMatch[1].toLowerCase() !== newS3KeyMatch[1].toLowerCase()))
                ) || (
                    !newS3KeyMatch
                ))
            ) {
                newS3Key += s3KeyMatch[1];
            }
        } else {
            if (!newS3Key.endsWith('/')) {
                newS3Key += '/';
            }
        }

        setWaiting(true);

        if (isFile) {
            movePromise = s3FileBrowserStore.moveFile(s3Key, newS3Key);
        } else {
            movePromise = s3FileBrowserStore.moveFolder(s3Key, newS3Key);
        }

        movePromise.finally(() => {
            if (!isComponentMounted.current) {
                return;
            }
            setWaiting(false);
        }).then(() => {
            if (navigateOnMove) {
                let route = `${s3FileBrowserStore.fileBrowserAppRoute}`;
                if (!route.endsWith('/')) {
                    route += '/';
                }
                route += s3FileBrowserStore.getRelativePathFromS3Key(newS3Key, true);
                history.push(route);
                return;
            }

            s3FileBrowserStore.fetchTree(true);
            s3FileBrowserStore.snackbarMessage = 'Edit Complete';

            if (!isComponentMounted.current) {
                return;
            }
            setShowingPrompt(false);
        }, () => {
            s3FileBrowserStore.fetchTree(true);
            s3FileBrowserStore.snackbarMessage = `Error Moving ${isFile ? 'File' : 'Folder'}`;
        });
    };

    const formattedS3Key = useMemo(() => {
        let formattedS3Key = s3FileBrowserStore.getRelativePathFromS3Key(s3Key);
        if (isFile) {
            const match = formattedS3Key.match(/^(.*)\.(webloc|url)$/i);
            if (match) {
                formattedS3Key = match[1];
            }
        } else if (formattedS3Key.endsWith('/')) {
            formattedS3Key = formattedS3Key.slice(0, -1);
        }

        return formattedS3Key;
    }, [
        s3Key,
        isFile,
        s3FileBrowserStore
    ]);

    return <Fragment>
        { buttonStyle === 'fab' ?
            <Tooltip title={`Move or Rename ${isFile ? 'File' : 'Folder'}`}>
                <Fab
                    className="s3-move-fab"
                    aria-label="move"
                    size="small"
                    onClick={(e) => {e.stopPropagation(); setShowingPrompt(true)}}
                >
                    <EditIcon/>
                </Fab>
            </Tooltip>
        :
            <ListItem
                button
                onClick={() => setShowingPrompt(true)}
                className="s3-move-list-item"
            >
                <ListItemIcon className="s3-move-list-item-icon">
                    <EditIcon/>
                </ListItemIcon>
                <ListItemText className="s3-move-list-item-text">
                    Move or Rename {isFile ? 'File' : 'Folder'}
                </ListItemText>
            </ListItem>
        }
        <Portal>
            <S3MovePrompt
                open={showingPrompt}
                currentS3Key={formattedS3Key}
                waitingForResponse={waiting}
                onClose={() => setShowingPrompt(false)}
                onConfirm={moveItem}
            />
        </Portal>
    </Fragment>
});

export default S3Move;