import { formatNumbersWithSeparator } from 'utils/numbers';
import { ChartTooltipItem, ChartData } from 'chart.js'

const LABEL_WRAP_MAX_LINE_LENGTH = 10;
const LABEL_WRAP_MAX_LINES = 3;

// export interface ChartData {
//     datasets: Array<{
//         data: Array<T>;
//         label: string;
//     }>;
//     labels: Array<string>
// };

interface FormatLabelsWithNumberSeparatorWithPrefix {
    (prefix: string, tooltipItem: ChartTooltipItem, data: ChartData, roundToInt?: boolean): string;
    <T>(prefix: string, tooltipItem: ChartTooltipItem, data: ChartData, roundToInt?: boolean): T;
};

interface FormatLabelsWithNumberSeparator {
    (tooltipItem: ChartTooltipItem, data: ChartData, roundToInt?: boolean): string;
    <T extends string|number>(tooltipItem: ChartTooltipItem, data: ChartData, roundToInt?: boolean): T;
};

export const formatLabelsWithNumberSeparatorWithPrefix: FormatLabelsWithNumberSeparatorWithPrefix = <T extends string|number>(prefix: string, tooltipItem: ChartTooltipItem, data: ChartData, roundToInt: boolean = false): T|string => {
    if (typeof tooltipItem.datasetIndex === 'undefined' || typeof tooltipItem.index  === 'undefined' || typeof data.datasets === 'undefined') {
        return '';
    }

    const dataset = data.datasets[tooltipItem.datasetIndex];
    if (typeof dataset.data  === 'undefined') {
        return '';
    }

    var value = dataset.data[tooltipItem.index];
    if (typeof value !== 'number' && typeof value !== 'string') {
        return '';
    }

    let label: string;
    if (typeof dataset.label !== 'undefined') {
        label = dataset.label
    } else if (typeof data.labels !== 'undefined') {
        label = data.labels[tooltipItem.index] as string;
    } else {
        return ''
    }

    return `${label}: ${prefix}${formatNumbersWithSeparator(value, roundToInt)}`;
};

export const formatLabelsWithNumberSeparator: FormatLabelsWithNumberSeparator = <T>(tooltipItem: ChartTooltipItem, data: ChartData, roundToInt: boolean = false): T|string => {
    return formatLabelsWithNumberSeparatorWithPrefix('', tooltipItem, data, roundToInt);
};

interface LabelToWrappedLabel {
    (label: string): Array<string>;
    <T>(label: T): T;
};

export const labelToWrappedLabel: LabelToWrappedLabel = <T>(label: T): T|Array<string> => {
    return labelToTruncatedWrappedLabel(label, false)
};

interface LabelToTruncatedWrappedLabel {
    (label: string, maxLines?: number | false): Array<string>;
    <T>(label: T, maxLines?: number | false): T;
};

export const labelToTruncatedWrappedLabel: LabelToTruncatedWrappedLabel = <T>(label: T, maxLines: number | false = LABEL_WRAP_MAX_LINES): T|Array<string> => {
    if (typeof label !== 'string') {
        return label;
    }

    let words = label.split(/\s+/);

    if (!words.length) {
        return [];
    }

    words = words.reduce((wordsArr, word)=> {
        if (word.toLowerCase() !== 'yrs') {
            wordsArr.push(word)
        } else {
            wordsArr[wordsArr.length - 1] = `${wordsArr[wordsArr.length - 1]} ${word}`
        }
        return wordsArr;
    }, [] as Array<string>);

    const labelsArr = [words.shift() as string];

    while (words.length) {
        const latestLine = labelsArr[labelsArr.length - 1];
        const nextWord = words.shift() as string;

        if ((latestLine.length + nextWord.length) >= LABEL_WRAP_MAX_LINE_LENGTH) {
            labelsArr.push(nextWord);
        } else {
            labelsArr[labelsArr.length - 1] = latestLine + ' ' + nextWord;
        }
    }

    if (maxLines === false || labelsArr.length <= maxLines) {
        return labelsArr;
    }

    const truncatedLabel = labelsArr.slice(0, maxLines);
    truncatedLabel[maxLines - 1] = `${truncatedLabel[maxLines - 1].substring(0, LABEL_WRAP_MAX_LINE_LENGTH - 3)}...`;
    return truncatedLabel;
};

interface PrefixValue {
    <A extends any[]>(prefix: string, callback: (...args: A) => number): (...args: A) => string;
    <T, A extends any[]>(prefix: string, callback: (...args: A) => T): (...args: A) => T;
};


export const prefixCallbackValue:PrefixValue = <T, A extends any[]>(prefix: string, callback: (...args: A) => T): (...args: A) => T|string => {
    return function(this: any, ...args: A): T|string {
        const value = callback.apply(this, args);
        if (typeof value === 'number' || typeof value === 'string') {
            return `${prefix}${value}`;
        }
        return value;
    }
};