import React from 'react';

import { MobileDatePicker as MUIDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from '@mui/material';
import './DatePicker.scss';

interface Props {
    selectedDate: Date;
    onDateChange: (date: Date|null) => unknown;
    format?: string;
    className?: string;
    minDate?: Date;
    maxDate?: Date;
};

const DatePicker: React.FC<Props> = ({
    selectedDate,
    onDateChange,
    minDate,
    maxDate,
    className = '',
    format = 'MM/dd/yy'
}: Props): JSX.Element => {
    return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MUIDatePicker
        renderInput={(props) => <TextField
          className={`date-picker-component ${className}`}
          {...props}
          variant="standard"
        />}
        value={selectedDate}
        inputFormat={format}
        onChange={onDateChange as (date: unknown) => unknown}
        minDate={minDate}
        maxDate={maxDate}
        showToolbar={false}
    />
    </LocalizationProvider>
  );
};

export default DatePicker;