import React, { useState, Fragment } from 'react';
import S3Prompt from '../S3Prompt';
import { TextField } from '@mui/material';
import { isValidHttpUrl, isValidFileOrFolderName } from 'utils/strings';
import './S3CreateLinksPrompt.scss';

interface Props {
    open: boolean;
    waitingForResponse: boolean;
    onClose: () => unknown;
    onConfirm: (links: Array<Link>) => unknown;
};

export interface Link {
    name: string;
    url: string;
};

export interface LinkData extends Link {
    nameError: boolean;
    urlError: boolean;
};

const S3CreateLinksPrompt: React.FC<Props> = ({open, waitingForResponse, onClose, onConfirm}: Props): JSX.Element => {
    const [links, setLinks] = useState<Array<LinkData>>([]);
    const setLinkAttribute = (attribute: keyof Link, value: string, index?: number) => {
        if (index === undefined || index < 0 || index >= links.length) {
            if (!value) {
                return;
            }
            setLinks([...links, {
                name: attribute === 'name' ? value : '',
                url: attribute === 'url' ? value : '',
                nameError: false,
                urlError: false
            }]);
            return;
        }

        const link = {...links[index]};
        if (!value && (Object.keys(link) as Array<keyof Link>).every((key) => key === attribute || !link[key])) {
            setLinks([
                ...links.slice(0, index),
                ...links.slice(index + 1)
            ]);
            return;
        }

        link[attribute] = value;

        if (attribute === 'name') {
            link.nameError = false;
        } else if (attribute === 'url') {
            link.urlError = false;
        }

        setLinks([
            ...links.slice(0, index),
            link,
            ...links.slice(index + 1)
        ]);
    };

    const confirmLinks = (): void => {
        const formattedLinks: Array<Link> = [];
        const linkDataWithErrors: Array<LinkData> = [];

        if (!links.reduce((error, link): boolean => {
            let nameError = false;
            let urlError = false;

            const formattedLink: Link = {
                name: link.name.trim(),
                url: link.url.trim()
            };

            if (!formattedLink.url.startsWith("http://") && !formattedLink.url.startsWith("https://")) {
                formattedLink.url = `http://${formattedLink.url}`;
            }

            formattedLinks.push(formattedLink);

            if (!isValidFileOrFolderName(formattedLink.name)) {
                nameError = true;
            }

            if (!isValidHttpUrl(formattedLink.url)) {
                urlError = true;
            }

            if (nameError || urlError) {
                linkDataWithErrors.push({
                    name: link.name,
                    url: link.url,
                    nameError: nameError,
                    urlError: urlError
                });
            } else {
                linkDataWithErrors.push(link);
            }

            return error || nameError || urlError;
        }, false)) {
            onConfirm(formattedLinks);
            return;
        } else {
            setLinks(linkDataWithErrors);
        }
    };

    return (
        <S3Prompt
            className="s3-create-link-prompt"
            open={open}
            onClose={() => {
                setLinks([]);
                onClose();
            }}
            onConfirm={confirmLinks}
            waitingForResponse={waitingForResponse}
            maxWidth="sm"
            title="Create New Links"
            confirmButtonText="Create"
        >
            <Fragment>
                <Fragment>
                    { [...links, { name: '', url: '', nameError: false, urlError: false }].map(({name, url, nameError, urlError}, i) => <NewLink
                        key={i}
                        name={name}
                        url={url}
                        nameError={nameError}
                        urlError={urlError}
                        autoFocusName={i === 0}
                        onNameChange={(name: string) => setLinkAttribute('name', name, i)}
                        onUrlChange={(url: string) => setLinkAttribute('url', url, i)}
                        onEnter={confirmLinks}
                    />) }
                </Fragment>
            </Fragment>
        </S3Prompt>
    );
};

interface NewLinkProps {
    name: string;
    url: string;
    nameError: boolean;
    urlError: boolean;
    onNameChange: (name: string) => void;
    onUrlChange: (url: string) => void;
    onEnter: () => void;
    autoFocusName?: boolean;
};

const NewLink: React.FC<NewLinkProps> = ({name, url, nameError, urlError, autoFocusName, onNameChange, onUrlChange, onEnter}) => {
    return <div className="s3-create-link-prompt-attrs-container">
        <div className="s3-create-link-prompt-name-container s3-create-link-prompt-attr-container">
            <TextField
                value={name}
                label="Name"
                error={nameError}
                onChange={(e) => onNameChange(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && onEnter()}
                className="s3-create-link-prompt-name s3-create-link-prompt-attr"
                autoFocus={Boolean(autoFocusName)}
                variant="standard"
                fullWidth
            />
        </div>
        <div className="s3-create-link-prompt-url-container s3-create-link-prompt-attr-container">
            <TextField
                label="URL"
                value={url}
                error={urlError}
                onChange={(e) => onUrlChange(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && onEnter()}
                className="s3-create-link-prompt-url s3-create-link-prompt-attr"
                variant="standard"
                fullWidth
            />
        </div>
    </div>
};

export default S3CreateLinksPrompt;