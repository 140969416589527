import React, { Fragment, useState, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Fab, Tooltip, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import addDays from 'date-fns/addDays';
import formatISO from 'date-fns/formatISO';

import LambdaStore from 'stores/lambdaStore';
import S3FileBrowserStore from 'stores/s3FileBrowserStore';
import S3Store from 'stores/s3Store';
import { ClientStoreContext } from 'stores/clientStore';

import { useStore } from 'utils/customHooks';
import { copyTextToClipboard } from 'utils/strings';
import { getEnv } from 'utils/env';

import './S3PreSignObject.scss';

interface Props {
    s3Key: string;
    s3FileBrowserStore: S3FileBrowserStore;
    isFile: boolean;
    buttonStyle: 'fab' | 'menuItem' | 'icon';
};

interface ShortenerPayload {
    longURL: string;
    endpoint: string;
    domain?: string;
    tags?: string; // maxLength: 45
    expires_at?: string;
    alias?: string; // maxLength: 30
};

const S3PreSignObject: React.FC<Props> = observer(({ s3Key, s3FileBrowserStore, isFile, buttonStyle }: Props): JSX.Element | null => {
    const isLinkFile = (s3Key: string) => {
        const fileExt = s3Key.split('.').pop() || '';
        return ['url', 'webloc'].includes(fileExt);
    }

    let initTooltipText = 'Copy sharable link (expires in 7 days)';
    if (isLinkFile(s3Key)) {
        initTooltipText = 'Copy sharable link (may require authentication to view)';
    }

    const [tooltipText, setTooltipText] = useState(initTooltipText);
    const lambdaStore = useStore(LambdaStore);
    const clientStore = useContext(ClientStoreContext);

    const shortenUrl = (url: string) => {
        const sevenDaysFromNow = formatISO(addDays(new Date(), 7));
        const oneDayFromNow = formatISO(addDays(new Date(), 1));

        // what should get priority when being tagged? Is there anything additional we want to tag here?
        const tagsArr = [
            clientStore.name,
            s3Key.split('/').pop(), 
            `client-portal-${getEnv()}`,
        ]

        let tags = '';

        tagsArr.forEach((tag) => {
            if (!tag) return;

            const apiTagLimit = 45;

            if ((tags.length + tag.length) <= apiTagLimit) {
                tags = tags ? tags.concat(`,${tag}`) : tags.concat(tag)
            }
        });

        const payload: ShortenerPayload = {
            longURL: url,
            endpoint: 'create',
            domain: "share.pjxmedia.com",
            tags: tags,
            expires_at: getEnv() === 'prod' ? sevenDaysFromNow : oneDayFromNow 
        };

        return lambdaStore.invoke({
            FunctionName: `url_shortener_${getEnv()}`,
            InvocationType: 'RequestResponse',
            Payload: JSON.stringify(payload)
        });
    };

    const getUrl = async (s3Key: string) => {
        if (isLinkFile(s3Key)) {
            return s3FileBrowserStore.getLinkFromObject(s3Key);
        } else {
            return lambdaStore.invoke({
                FunctionName: `presign_object_with_iam_user_${getEnv()}`,
                InvocationType: 'RequestResponse',
                Payload: JSON.stringify({
                    bucket: S3Store.bucket,
                    key: s3Key,
                    expires: 60 * 60 * 24 * 7  // 7 days
                })
            }).then((response) => {
                const { StatusCode, Payload } = response;
                if (StatusCode === 200 && typeof Payload == 'string') {
                    const payload = JSON.parse(Payload);

                    if (payload.errorType) {
                        throw new Error(payload.errorMessage);
                    } else {
                        return payload.preSignedUrl;
                    }
                } else {
                    throw new Error("Error trying to presign object with iam user");
                }
            })
        }
    }

    const copySignedObjectUrl = async () => {
        return getUrl(s3Key)
            .then((url) => shortenUrl(url))
            .then((resp) => {
                if (typeof resp.Payload == 'string') {
                    const payload = JSON.parse(resp.Payload);

                    if (payload.errorType) {
                        throw new Error(payload.errorMessage);
                    } else {
                        const { tiny_url } = payload;
                        copyTextToClipboard(tiny_url);
                    }
                } else {
                    throw new Error('Unknown Payload type');
                }
            }).then(() => {
                setTooltipText('Copied!');
                setTimeout(() => setTooltipText(initTooltipText), 3000);
            }).catch((err) => {
                setTooltipText('Error copying!');
                setTimeout(() => setTooltipText(initTooltipText), 3000);

                let message = typeof err.response !== "undefined" ? err.response.data.message : err.message;
                console.warn("error", message);
            });
    };

    if (!isFile) return null;

    return (
        <Fragment>
            {
                buttonStyle === 'fab' ?
                    <Tooltip title={tooltipText}>
                        <Fab
                            className="pre-signed-link-fab"
                            aria-label="copy"
                            size="small"
                            onClick={copySignedObjectUrl}
                        >
                            <ContentCopyIcon />
                        </Fab>
                    </Tooltip>
                : buttonStyle === 'menuItem' ?
                    <ListItem
                        button
                        onClick={copySignedObjectUrl}
                        className="pre-signed-link-list-item"
                    >
                        <ListItemIcon className="pre-signed-link-list-item-icon">
                            <ContentCopyIcon />
                        </ListItemIcon>
                        <ListItemText className="pre-signed-link-list-item-text">
                            Copy Presigned URL
                        </ListItemText>
                    </ListItem>
                :
                    <ContentCopyIcon
                        onClick={copySignedObjectUrl}
                        style={{
                            height: 16,
                            cursor: 'pointer',
                        }}
                    />
            }
        </Fragment>
    );
});

export default S3PreSignObject;