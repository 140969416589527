import { observable } from 'mobx';
import BaseStore from 'stores/abstractStores/baseStore';

export interface Props {
    impressions: number;
    frequency: number;
    reachPercentage: number;
    trp: number;
};

class GeopathMeasurementsStore extends BaseStore {
    @observable
    public impressions: number;

    @observable
    public frequency: number;

    @observable
    public reachPercentage: number;

    @observable
    public trp: number;

    constructor({
        impressions,
        frequency,
        reachPercentage,
        trp
    }: Props) {
        super();
        this.impressions = impressions;
        this.frequency = frequency;
        this.reachPercentage = reachPercentage;
        this.trp = trp;
    };
};

export default GeopathMeasurementsStore;