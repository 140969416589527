import { observable, computed } from 'mobx';
import md5 from "crypto-js/md5";
import BaseStore from 'stores/abstractStores/baseStore';

export type LineItemDateAttributes = 'actualEndDate'| 'actualStartDate'| 'plannedEndDate'| 'plannedStartDate';

interface Props {
    actualEndDate?: string;
    actualStartDate?: string;
    plannedEndDate?: string;
    plannedStartDate?: string;
    description: string;
    isDigital: boolean;
    marketId: string;
    mediaFormat: string;
    mediaRateCard: number;
    negotiatedMediaRate: number;
    baseWeeklyNonGeopathImpressions?: number;
};

export interface PackageProps extends Props {
    numberOfUnits: number;
};

export interface UnitProps extends Props {
    unitNumber: string;
    geopathId?: string;
};

export abstract class LineItemStore extends BaseStore {
    @observable
    public actualEndDate?: Date;

    @observable
    public actualStartDate?: Date;

    @observable
    public description: string;

    @observable
    public isDigital: boolean;

    @observable
    public marketId: string;

    @observable
    public mediaFormat: string;

    @observable
    public mediaRateCard: number;

    @observable
    public negotiatedMediaRate: number;

    @observable
    public plannedEndDate?: Date;

    @observable
    public plannedStartDate?: Date;

    @observable
    public baseWeeklyNonGeopathImpressions?: number;

    public readonly cycleLength = 4 * 7;

    constructor({
        actualEndDate,
        actualStartDate,
        description,
        isDigital,
        marketId,
        mediaFormat,
        mediaRateCard,
        negotiatedMediaRate,
        plannedEndDate,
        plannedStartDate,
        baseWeeklyNonGeopathImpressions,
    }: Props) {
        super();
        this.actualEndDate = LineItemStore.toDate(actualEndDate);
        this.actualStartDate = LineItemStore.toDate(actualStartDate);
        this.description = description;
        this.isDigital = isDigital;
        this.marketId = marketId;
        this.mediaFormat = mediaFormat;
        this.mediaRateCard = mediaRateCard;
        this.negotiatedMediaRate = negotiatedMediaRate;
        this.plannedEndDate = LineItemStore.toDate(plannedEndDate);
        this.plannedStartDate = LineItemStore.toDate(plannedStartDate);
        this.baseWeeklyNonGeopathImpressions = baseWeeklyNonGeopathImpressions;
    };

    private static toDate<T extends string|Date|undefined>(date: T): T extends undefined ? undefined : Date;
    private static toDate(date: string|Date|undefined): Date|undefined {
        if (typeof date === 'undefined') {
            return date;
        }
        if (date instanceof Date) {
            return date;
        }
        const dateParts = date.split('-').map((n) => parseInt(n)) as [number, number, number];
        dateParts[1] = dateParts[1] - 1;
        return new Date(...dateParts);
    };

    public static doAllLineItemsHaveActualDates(lineItems: Array<LineItemStore>): boolean {
        return lineItems.every((lineItem: LineItemStore): any => lineItem.actualStartDate && lineItem.actualEndDate);
    };

    @computed
    public get startDate(): Date {
        return (this.actualStartDate || this.plannedStartDate) as Date;
    };

    @computed
    public get endDate(): Date {
        return (this.actualEndDate || this.plannedEndDate) as Date;
    };

    public getMediaValue(negotiated: boolean, startDate: Date, endDate: Date): number {
        const plannedLengthInTime = endDate.getTime() - startDate.getTime();
        const plannedLength = Math.round(plannedLengthInTime / (1000 * 3600 * 24) + 1);
        const mediaRateCard = negotiated ? this.negotiatedMediaRate : this.mediaRateCard;

        return mediaRateCard * plannedLength / this.cycleLength;
    };

    public abstract get isGeopathAudited(): Boolean;
    public abstract get identifierStr(): string;
};

export class PackageLineItemStore extends LineItemStore {
    @observable
    public numberOfUnits: number;

    constructor({
        numberOfUnits,
        ...baseLineItemProps
    }: PackageProps) {
        super(baseLineItemProps);
        this.numberOfUnits = numberOfUnits;
    };

    public get isGeopathAudited(): false {
        return false;
    };

    public get identifierStr(): string {
        const keys = [
            this.mediaFormat,
            this.isDigital.toString(),
            this.numberOfUnits.toString(),
            this.description
        ];

        return md5(keys.join(':')).toString();
    };
};

export class UnitLineItemStore extends LineItemStore {
    @observable
    public geopathId?: string;

    @observable
    public unitNumber: string;

    constructor({
        unitNumber,
        geopathId,
        ...baseLineItemProps
    }: UnitProps) {
        super(baseLineItemProps);
        this.geopathId = geopathId;
        this.unitNumber = unitNumber;
    };

    @computed
    public get isGeopathAudited(): Boolean {
        return Boolean(this.geopathId);
    };

    public get identifierStr(): string {
        const keys = [
            this.unitNumber,
            this.mediaFormat,
            this.isDigital.toString(),
            this.description
        ];

        return md5(keys.join(':')).toString();
    };
};

interface Planned {
    plannedEndDate: Date;
    plannedStartDate: Date;
};

interface Actual {
    actualEndDate: Date;
    actualStartDate: Date;
};

export type PlannedLineItemStore = LineItemStore & Planned;
export type ActualLineItemStore = LineItemStore & Actual;

export type PlannedUnitLineItemStore = UnitLineItemStore & Planned;
export type ActualUnitLineItemStore = UnitLineItemStore & Actual;

export type PlannedPackageLineItemStore = PackageLineItemStore & Planned;
export type ActualPackageLineItemStore = PackageLineItemStore & Actual;

export type LineItemType = 'unit'|'package'|'all';