export const isValidHttpUrl = (url: string): boolean => {
    if (!url) {
        return false;
    }
    const res = url.match(/(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
    return (res !== null)
};

export const isValidFileOrFolderName = (name: string): boolean => {
    return (Boolean(name) && !name.startsWith('.') && name.indexOf('/') === -1);
};

export const concatRouteParts = (...routeParts: Array<string>): string => {
    return routeParts.reduce((route: string, routePart) => {
        if (routePart === undefined || routePart === '') {
            return route;
        }

        if (routePart.endsWith('/')) {
            routePart = routePart.slice(0, -1);
        }

        if (routePart.startsWith('/')) {
            routePart = routePart.slice(1);
        }

        return `${route}${(route && routePart) ? '/' : ''}${routePart}`;
    }, '');
};

export const getNameWithoutLinkExtention = (name: string): string => {
    if (getIsLinkExtention(name)) {
        name = name.slice(0, name.lastIndexOf('.'));
    }

    return name;
};

export const getNameWithoutExtention = (name: string): string => {
    const extentionDotIndex = name.lastIndexOf('.')
    if (extentionDotIndex !== -1) {
        name = name.slice(0, name.lastIndexOf('.'));
    }

    return name;
};

export const getIsLinkExtention = (name: string): boolean => {
    const lowerCaseName = name.toLowerCase();
    return lowerCaseName.endsWith('.url') || lowerCaseName.endsWith('.webloc')
};

export const copyTextToClipboard = (text: string) => {
    if (navigator.clipboard && window.isSecureContext) {
        return navigator.clipboard.writeText(text);
    } else { 
        let textArea = document.createElement("textarea");
        textArea.value = text;

        // moving textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        return new Promise((resolve, reject) => {
            try {
                document.execCommand('copy') ? resolve(true) : reject();
                textArea.remove();
            } catch (err) {
                console.error('Unable to copy to clipboard: ', err);
                window.prompt("Copy to clipboard: Ctrl+C, Enter", text);
            }
        });
    }
}