import { createContext } from 'react';
import { observable, computed } from 'mobx';
import { Theme, adaptV4Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import BaseStore from 'stores/abstractStores/baseStore';
import { authStore } from 'stores/authStore';
import { isLight } from 'utils/colors';

const DEFAULT_CLIENT_PRIMARY_COLOR = '#325694';
const DEFAULT_CLIENT_SECONDARY_COLOR = '#44A3F2';

export interface Props {
    name: string;
    primaryColor?: string;
    secondaryColor?: string;
    logo?: string|null;
};

class ClientStore extends BaseStore {
    @observable
    public name!: string;

    @observable
    public primaryColor!: string;

    @observable
    public secondaryColor!: string;

    @observable
    public clientPending!: boolean;

    @observable
    private logoOverride?: string|null;

    constructor(clientArguments: Props) {
        super();
        this.loadClient(clientArguments);
    };

    public loadClient({name, primaryColor, secondaryColor, logo}: Props): void {
        this.name = name;
        this.primaryColor = primaryColor || DEFAULT_CLIENT_PRIMARY_COLOR;
        this.secondaryColor = secondaryColor || DEFAULT_CLIENT_SECONDARY_COLOR;
        this.logoOverride = logo;
    };

    @computed
    public get theme(): Theme {
        return createTheme(adaptV4Theme({
            palette: {
                primary: {
                    main: this.primaryColor,
                    contrastText: isLight(this.primaryColor) ? '#000000' : '#FFFFFF'
                },
                secondary: {
                    main: this.secondaryColor,
                    contrastText: isLight(this.primaryColor) ? '#000000' : '#FFFFFF'
                }
            },
            typography: {
                button: {
                    textTransform: 'none'
                },
                fontFamily: [
                    '"Open Sans"', '"Avenir Book"',
                ].join(','),
            }
        }));
    };

    @computed
    public get logo(): string|null {
        if (typeof this.logoOverride !== 'undefined') {
            return this.logoOverride;
        }

        if (authStore.user && authStore.user.attributes && typeof authStore.user.attributes['custom:logo'] === 'string') {
            return authStore.user.attributes['custom:logo'];
        }

        return null;
    };
};


export default ClientStore;
export const clientStore = new ClientStore({
    name: ''
});
export const ClientStoreContext = createContext(clientStore);