import { UnitProps, PackageProps } from 'stores/lineItemStore';
import { CardSizeType } from 'components/cardGrid/CardGrid';
import { formatDate } from 'utils/dates';
import { getEnv } from 'utils/env';

const getBaseRoute = (): string => {
    switch(getEnv()) {
        case 'prod':
            return 'https://www.adstruc.com';
        case 'qa':
            return 'https://docker-staging.adstruc.com';
        case 'demo':
                return 'https://demo.adstruc.com';
        case 'dev':
            return '';
        default:
            throw new Error('Invalid Env');
    }
};

const getEnvBasedUrl = (route: string): string => {
    return `${getBaseRoute()}${route}`;
};

const getRequiredDataResponseHandlers = <T>(errorMessage: string): Array<((response: Response) => Promise<T>)> => {
    return [(response: Response): Promise<T> => {
        return new Promise((resolve, reject) => {
            response.json().then((data: T|{
                code: number,
                message: string
            }) => {
                if (!data || (typeof data === 'object' && 'code' in data && (data.code < 200 || data.code > 299))) {
                    reject(errorMessage);
                }
                resolve(data as T);
            }, (e) => {
                reject(errorMessage);
            });
        })
    }, () => {
        return Promise.reject(errorMessage);
    }];
};

export interface CampaignApiData {
    lineItems: Array<UnitProps>;
    packageLineItems: Array<PackageProps>;
    settings: {
        campaignName: string;
        clientName: string;
        primaryColor?: string;
        secondaryColor?: string;
        baseSegmentName: string;
        baseSegmentId: string;
        primarySegmentName?: string;
        primarySegmentId?: string;
        secondarySegmentName?: string;
        secondarySegmentId?: string;
        enableExport: boolean;
    };
    markets: {
        [marketId: string]: string;
    };
    campaignPhotos?: Array<string>;
    images?: Array<{
        url: string;
        size?: CardSizeType;
        position?: number;
    }>;
};

export const fetchCampaign = (campaignId: string, token: string): Promise<CampaignApiData> => {
    const url = getEnvBasedUrl(`/s/tools/campaign-reports/${campaignId}/${token}`);

    return fetch(url).then(...getRequiredDataResponseHandlers<CampaignApiData>('Campaign not found'));
};

export interface Flight {
    spotId: string;
    startDate: Date;
    endDate: Date;
};

interface GeopathApiSummary {
    reachPercentage: number | null;
    frequencyAverage: number | null;
    targetImpressions: number | null;
    trp: number | null;
};

interface GeopathSummaryData extends GeopathApiSummary {
    reachPercentage: number;
    frequencyAverage: number;
    targetImpressions: number;
    trp: number;
};

interface GeopathApiData {
    spotIdsWithNoMeasures?: Array<string>;
    summary: GeopathApiSummary | null
};

export interface TargetSegmentIds {
    base: string;
    primary?: string;
    secondary?: string;
};

export interface GeopathSegmentsData {
    base: GeopathSummaryData;
    primary?: GeopathSummaryData;
    secondary?: GeopathSummaryData;
};

export interface GeopathData {
    planned: GeopathSegmentsData;
    alternateDateRange?: GeopathSegmentsData
};

export const fetchGeopathData = (
    campaignId: string,
    token: string,
    marketIds: Array<string>,
    targetSegmentIds: TargetSegmentIds,
    flights: Array<Flight>,
    alternateFlights?: Array<Flight>
): Promise<GeopathData> => {
    const targetSegmentKeys: Array<keyof TargetSegmentIds> = ['base', 'primary', 'secondary'];
    const flightArrays = [flights];

    if (alternateFlights) {
        flightArrays.push(alternateFlights);
    }

    const fetchPromises = flightArrays.map((flightsArray) => {
        return targetSegmentKeys.map((targetSegmentKey) => {
            const targetSegmentId = targetSegmentIds[targetSegmentKey];
            if (!targetSegmentId) {
                return Promise.resolve(null);
            }

            if (!flightsArray.length) {
                const zeroMeasurementResponse: GeopathApiData = {
                    spotIdsWithNoMeasures: [],
                    summary: {
                        reachPercentage: 0,
                        frequencyAverage: 0,
                        targetImpressions: 0,
                        trp: 0
                    }
                };
                return Promise.resolve(zeroMeasurementResponse);
            }

            return fetchGeopathSegmentData(
                campaignId,
                token,
                marketIds,
                targetSegmentId,
                flightsArray
            );
        });
    }).flat();

    return Promise.all(fetchPromises).then((segmentsData: Array<GeopathApiData|null>) => {
        const groupedSegmentsData = {
            planned: segmentsData.slice(0, targetSegmentKeys.length),
            alternateDateRange: segmentsData.slice(targetSegmentKeys.length)
        }

        return (Object.keys(groupedSegmentsData) as Array<keyof GeopathData>).reduce((transformedData, dateRangeKey) => {
            const segmentsData = groupedSegmentsData[dateRangeKey];
            if (segmentsData.length) {
                transformedData[dateRangeKey] = segmentsData.reduce((transformedData, segmentData, index) => {
                    const targetSegmentKey = targetSegmentKeys[index];

                    if (segmentData) {
                        if (
                            segmentData.summary === null || 
                            segmentData.summary.frequencyAverage === null ||
                            segmentData.summary.reachPercentage === null ||
                            segmentData.summary.targetImpressions === null ||
                            segmentData.summary.trp === null
                        ) {
                            throw new Error('Summary data does not exist.');
                        }

                        transformedData[targetSegmentKey] = segmentData.summary as GeopathSummaryData;
                    }

                    return transformedData;
                }, {} as Partial<GeopathSegmentsData>) as GeopathSegmentsData;
            }

            return transformedData;
        }, {} as Partial<GeopathData>) as GeopathData;
    });
};

const FAILED_GEOPATH_DATA_FETCH_MESSAGE = 'Failed to Load Geopath Data';

const fetchGeopathSegmentData = (
    campaignId: string,
    token: string,
    marketIds: Array<string>,
    targetSegmentId: string,
    flights: Array<Flight>
) => {
    const url = getEnvBasedUrl(`/s/tools/campaign-reports/geopath-inventory-summary-search/${campaignId}/${token}`);

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Same-Site': 'None',
        },
        body: JSON.stringify({
            marketIds: marketIds,
            targetSegmentId: targetSegmentId,
            flights: flights.map(({startDate, endDate, ...otherArgs}) => {
                return {
                    startDate: formatDate(startDate),
                    endDate: formatDate(endDate),
                    ...otherArgs
                }
            })
        })
    // }).then(...getRequiredDataResponseHandlers<GeopathApiData>(FAILED_GEOPATH_DATA_FETCH_MESSAGE)).then((data: GeopathApiData): GeopathApiData => {
    //     if (data.spotIdsWithNoMeasures && data.spotIdsWithNoMeasures.length) {
    //         throw FAILED_GEOPATH_DATA_FETCH_MESSAGE;
    //     }

    //     return data;
    // });
    }).then(...getRequiredDataResponseHandlers<GeopathApiData>(FAILED_GEOPATH_DATA_FETCH_MESSAGE));
};