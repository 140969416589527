import React, { Fragment, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Switch } from 'react-router-dom';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState } from '@aws-amplify/ui-components';
import FileShare, { ClientWritableFileShare } from 'components/fileShare/FileShare';
import HomePageRedirect from 'components/homePageRedirect/HomePageRedirect';
import Header from 'components/header/Header';
import ClientBrowser from 'components/clientBrowser/ClientBrowser';
import PulseReport from 'components/pulseReport/PulseReport';
import UserSetup from 'components/userSetup/UserSetup';
import CreateUser from 'components/createUser/CreateUser';
import { AuthStoreContext, User } from 'stores/authStore';
import { RoutesConfig } from 'App';
import './AuthedApp.scss';

export const ROUTES_CONFIG: RoutesConfig = [
    {
        path: '/',
        component: HomePageRedirect,
        exact: true
    },
    {
        path: '/home',
        component: HomePageRedirect,
    },
    {
        path: '/client-upload/:userToken',
        component: ClientWritableFileShare
    },
    {
        path: '/file-share/:userToken',
        component: FileShare
    },
    {
        path: '/clients',
        component: ClientBrowser
    },
    {
        path: '/pulse/:userToken',
        component: PulseReport
    },
    {
        path: '/user-setup',
        component: UserSetup
    },
    {
        path: '/create-user',
        component: CreateUser
    }
];

const AuthedApp: React.FC = observer((): JSX.Element => {
    const authStore = useContext(AuthStoreContext);
    return (
        <Fragment>
            <div className="App-header">
                <Header authed={!!authStore.user}/>
            </div>
            <div className="App-content">
                { authStore.authState === AuthState.SignedIn && authStore.user ?
                    <AuthedRoutes/>
                :
                    <div className="authenticator-container">
                        <AmplifyAuthenticator
                            handleAuthStateChange={(nextAuthState, authData) => authStore.handleAuthStateChange(nextAuthState, authData as User|undefined)}
                        >
                            <AmplifySignIn
                                slot="sign-in"
                                hideSignUp={true}
                                formFields={[
                                    {type: 'username'},
                                    {type: 'password', hint: null}
                                ]}
                            />
                        </AmplifyAuthenticator>
                    </div>
                }
            </div>
        </Fragment>
    );
});

const AuthedRoutes: React.FC = (): JSX.Element => {
    return <Switch>
        { ROUTES_CONFIG.map(({path, component, exact}, i) => {
            return <Route
            key={i}
            path={path}
            component={component}
            exact={!!exact}
            />
        })}
    </Switch>
};

export default AuthedApp;