import React from 'react';
import { DialogContentText } from '@mui/material';
import S3Prompt from '../S3Prompt';

interface Props {
    open: boolean;
    itemName: string;
    waitingForResponse: boolean;
    onClose: () => unknown;
    onConfirm: () => unknown;
};

const S3DeletePrompt: React.FC<Props> = ({open, itemName, waitingForResponse, onClose, onConfirm}: Props): JSX.Element => {
    return (
        <S3Prompt
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
            waitingForResponse={waitingForResponse}
            onKeyPress={(e) => e.key === 'Enter' && onConfirm()}
            maxWidth="xs"
            title="Delete Confirmation"
        >
            <DialogContentText>
                Are you sure you want to delete {itemName}
            </DialogContentText>
        </S3Prompt>
    );
};

export default S3DeletePrompt;