import React, { Fragment, useState } from 'react';
import { Drawer, Tooltip, List } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import S3FileBrowserStore from 'stores/s3FileBrowserStore';
import S3Move from '../s3Move/S3Move';
import S3Delete from '../s3Delete/S3Delete';
import S3Upload from '../s3Upload/S3Upload';
import S3CreateLinks from '../s3CreateLinks/S3CreateLinks';
import S3CreateFolder from '../s3CreateFolder/S3CreateFolder';
import './S3FolderActions.scss';

interface Props {
    s3FileBrowserStore: S3FileBrowserStore;
    folderPath: string;
    className?: string;
};

const S3FolderActions: React.FC<Props> = ({s3FileBrowserStore, folderPath, className}: Props): JSX.Element => {
    const [showMenu, setShowMenu] = useState(false);
    const closeMenu = () => setShowMenu(false);
    const s3Key = s3FileBrowserStore.getS3KeyFromRelativePath(folderPath);

    return <Fragment>
        <Tooltip title="Edit Files and Folders">
            <EditIcon
                className={`s3-folder-actions-button ${className || ''}`}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowMenu(!showMenu)
                }}
            />
        </Tooltip>
        <Drawer
            anchor="right"
            open={showMenu} onClose={() => setShowMenu(false)}
            className="s3-folder-action-menu"
            onClick={(e) => e.stopPropagation()}
        >
            <div className="s3-folder-action-menu-contents">
                <List
                    className="s3-folder-action-list"
                >
                    <S3Upload
                        type="File"
                        folderPath={folderPath}
                        s3FileBrowserStore={s3FileBrowserStore}
                        onSuccess={closeMenu}
                    />
                    <S3Upload
                        type="Folder"
                        folderPath={folderPath}
                        s3FileBrowserStore={s3FileBrowserStore}
                        onSuccess={closeMenu}
                    />
                    <S3CreateFolder
                        folderPath={folderPath}
                        s3FileBrowserStore={s3FileBrowserStore}
                        onSuccess={closeMenu}
                        buttonStyle="menuItem"
                    />
                    <S3CreateLinks
                        folderPath={folderPath}
                        s3FileBrowserStore={s3FileBrowserStore}
                        onSuccess={closeMenu}
                    />
                    <Fragment>
                        <S3Move
                            s3Key={s3Key}
                            s3FileBrowserStore={s3FileBrowserStore}
                            isFile={false}
                            navigateOnMove={true}
                            buttonStyle="menuItem"
                        />
                        <S3Delete
                            s3Key={s3Key}
                            s3FileBrowserStore={s3FileBrowserStore}
                            isFile={false}
                            navigateUpOnDelete={true}
                            buttonStyle="menuItem"
                        />
                    </Fragment>
                </List>
            </div>
        </Drawer>
    </Fragment>
};

export default S3FolderActions;