import BaseStore from 'stores/abstractStores/baseStore';

abstract class AutorunStore extends BaseStore {
    private disposers: Array<Function> = [];

    protected addAutorun(disposer: Function): void {
        this.disposers.push(disposer)
    };

    public clean(): void {
        this.disposers.forEach((disposer) => {disposer()});
        super.clean();
    };
};

export default AutorunStore;