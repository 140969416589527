import React, { Fragment, useState, useContext, useLayoutEffect, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Add as AddIcon } from '@mui/icons-material';
import useResizeObserver from 'use-resize-observer/polyfilled';
import SignOut from 'components/signOut/SignOut';
import './Header.scss';
import { ClientStoreContext } from 'stores/clientStore';
import { useWindowSize } from 'utils/reactMediaQueries';
import { getS3ImageUrl } from 'utils/label';

interface Props {
    authed: boolean;
}

const LOGO_HEIGHT = 50;
const LOGO_MARGIN_BOTTOM = 2;
const LOGO_MARGIN_RIGHT = 5;

const Header: React.FC<Props> = observer(({ authed }: Props): JSX.Element => {
    const histroy = useHistory();
    const clientStore = useContext(ClientStoreContext);

    const [showClientLogo, setShowClientLogo] = useState(true);
    const [clientLogoLoaded, setClientLogoLoaded] = useState(false);
    const [floatHeaderRightLeft, setFloatHeaderRightLeft] = useState(false);

    const headerRef = useRef<HTMLDivElement>(null);
    const headerLeftRef = useRef<HTMLDivElement>(null);
    const headerRightRef = useRef<HTMLDivElement>(null);
    const logosRef = useRef<HTMLDivElement>(null);
    const clientLogoRef = useRef<HTMLImageElement>(null);

    const { width: windowWidth } = useWindowSize();
    const { width: headerWidth } = useResizeObserver({
        ref: headerRef
    });
    const { width: headerLeftWidth } = useResizeObserver({
        ref: headerLeftRef
    });
    const { width: headerRightWidth } = useResizeObserver({
        ref: headerRightRef
    });
    const { height: logosHeight } = useResizeObserver({
        ref: logosRef
    });

    useEffect(() => {
        if (clientLogoRef.current && clientLogoRef.current.complete) {
            setClientLogoLoaded(true);
        } else {
            setClientLogoLoaded(false);
        }
    }, [clientStore.logo]);

    useEffect(() => {
        setShowClientLogo(true);
    }, [
        windowWidth,
        clientStore.logo
    ]);

    useLayoutEffect(() => {
        const width = headerWidth || 0;
        const leftWidth = headerLeftWidth || 0;
        const rightWidth = headerRightWidth || 0;
        setFloatHeaderRightLeft((leftWidth + rightWidth) > width);
    }, [
        headerWidth,
        headerLeftWidth,
        headerRightWidth
    ]);

    useLayoutEffect(() => {
        if (logosHeight && logosHeight > (LOGO_HEIGHT + LOGO_MARGIN_BOTTOM)) {
            setShowClientLogo(false);
        }
    }, [logosHeight]);

    return (
        <div
            className="header-component clearfix"
            ref={headerRef}
        >
            <div
                className="header-left"
                ref={headerLeftRef}
                style={{
                    float: 'left'
                }}
            >
                <div className="header-left-content">
                    <div
                        className="app-header-logos"
                        ref={logosRef}
                    >
                        <a href={'/home'}>
                            <img
                                className="app-header-logo"
                                style={{
                                    height: LOGO_HEIGHT,
                                    marginBottom: LOGO_MARGIN_BOTTOM,
                                    marginRight: LOGO_MARGIN_RIGHT
                                }}
                                src={getS3ImageUrl('logo.svg')}
                                alt='Logo'
                            />
                            { clientStore.logo &&
                                <div
                                    style={{
                                        display: 'inline-block'
                                    }}
                                >
                                    <AddIcon
                                        className="app-header-logo-separator"
                                        style={{
                                            display: showClientLogo && clientLogoLoaded ? 'inline-block' : 'none',
                                            marginBottom: LOGO_MARGIN_BOTTOM,
                                            marginRight: LOGO_MARGIN_RIGHT
                                        }}
                                    />
                                    <img
                                        className="app-header-logo"
                                        ref={clientLogoRef}
                                        src={clientStore.logo}
                                        alt="client-logo"
                                        onLoad={() => setClientLogoLoaded(true)}
                                        style={{
                                            height: LOGO_HEIGHT,
                                            marginBottom: LOGO_MARGIN_BOTTOM,
                                            display: showClientLogo ? 'inline-block' : 'none',
                                            marginRight: LOGO_MARGIN_RIGHT
                                        }}
                                    />
                                </div>
                            }
                        </a>
                    </div>
                </div>
            </div>
            <div
                className="header-right"
                ref={headerRightRef}
                style={{
                    float: floatHeaderRightLeft ? 'left' : 'right'
                }}
            >
                <div className="header-right-content">
                    <div style={{marginRight: 15, marginTop: 14}}>
                        { authed &&
                            <Fragment>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {histroy.push('/home')}}
                                >
                                    home
                                </div>
                                <div style={{
                                    display: 'inline-block',
                                    marginLeft: 5,
                                    marginRight: 5,
                                }}>
                                    |
                                </div>
                                <SignOut/>
                            </Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Header;